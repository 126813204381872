<template>
    <Header></Header>
    <main>
        <router-view :key="$route.fullPath" />
    </main>
    <Footer></Footer>
    <AuthWindow />
    <notifications classes="notification" position="top center" />
</template>

<script>
import Header from "@/components/partials/header/Header.vue"
import Footer from "@/components/partials/footer/Footer.vue"
import AuthWindow from "./components/windows/authWindow/AuthWindow.vue"

export default {
    components: {
        Header,
        Footer,
        AuthWindow
    }
}
</script>
