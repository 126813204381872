import axios from "axios"
import store from "../store"

const AdminTransactionService = {
    async getTransactions({ userId = null, sortBy = null, isDesc = null, perPage = 30, page = 1 } = {}) {
        const response = await axios.get("/api/admin/transactions", { headers: { Authorization: `Bearer ${store.state.accessToken}` }, params: arguments[0] })
        return response.data
    },
}

export default AdminTransactionService
