<template>
    <form @submit.prevent="sendNotification" class="user-admin__form primary-linear-stroke">
        <h2 class="user-admin__form__title">Отправить сообщение</h2>
        <div class="user-admin__form__content">
            <div class="auth-window__input-wrapper">
                <primary-textarea placeholder="Сообщение . . ." @input="validateText" :isError="!!error" v-model="text" class="user-admin__form__input"></primary-textarea>
                <small class="auth-window__error-text" v-if="error">{{ error }}</small>
            </div>
            <button type="sumbit" class="user-admin__form__button btn-success">Отправить</button>
        </div>
    </form>
</template>

<script>
import AdminUserService from "@/services/AdminUserService.js"

export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            text: "",
            error: ""
        }
    },
    methods: {
        async sendNotification() {
            this.validateText()
            if (!!this.error) {
                return
            }
            await AdminUserService.sendNotification(this.user.id, this.text)
            this.$notify({ text: "Сообщение отправлено" })
            this.text = ""
        },
        validateText() {
            if (this.text.length === 0) {
                this.error = this.$store.getters.content.ERROR_REQUIRED_FIELD
            }
            else {
                this.error = ""
            }
        }
    }
}
</script>