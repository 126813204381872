<template>
    <container>
    <div class="blocked">
        <h2 class="blocked__title">{{ this.$store.getters.content.BLOCKEDBALANCE_TITLE }}</h2>
        <p class="blocked__description">{{ this.$store.getters.content.BLOCKEDBALANCE_DESCRIPTION }}</p>
    </div>
</container>
</template>

<style scoped>
.blocked {
background-color: var(--neutral-background-90);
display: flex;
justify-content: center;
gap: 35px;
flex-direction: column;
border-radius: 16px;
margin-top: 75px;
padding: 25px;
}

.blocked__title {
font-size: 24px;
font-weight: 600;
color: var(--red);
text-align: center;
}

.blocked__link {
width: fit-content;
display: block;
margin: auto;
}

.blocked__description {
font-size: 16px;
font-weight: 400;
color: var(--neutral-text);
text-align: center;
}
</style>
