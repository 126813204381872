<template>
    <container>
        <DealStatusBar v-if="status" :status="status" />
        <table class="deal-info">
            <tr class="deal-info__properties">
                <td class="deal-info__properties__text">
                    {{ this.$store.getters.content.CREATE_TITLE }}
                </td>
                <td class="deal-info__properties__text">
                    {{ deal.title }}
                </td>
            </tr>
            <tr class="deal-info__properties">
                <td class="deal-info__properties__text">
                    {{ this.$store.getters.content.CREATE_ROLE_1 }}
                </td>
                <td class="deal-info__properties__text">
                    {{ deal.customer.userName }}
                </td>
            </tr>
            <tr class="deal-info__properties">
                <td class="deal-info__properties__text">
                    {{ this.$store.getters.content.CREATE_ROLE_2 }}
                </td>
                <td class="deal-info__properties__text">
                    {{ deal.performer.userName }}
                </td>
            </tr>
            <tr class="deal-info__properties">
                <td class="deal-info__properties__text">
                    {{ this.$store.getters.content.CREATE_CALCULATOR_1 }}
                </td>
                <td class="deal-info__properties__text">
                    <CurrentCurrency :value="deal.amount" />
                </td>
            </tr>
            <tr class="deal-info__properties">
                <td class="deal-info__properties__text">
                    {{ this.$store.getters.content.CREATE_CALCULATOR_2 }}
                </td>
                <td class="deal-info__properties__text">
                    <CurrentCurrency :value="Math.ceil((deal.amount) * 0.05)" />
                </td>
            </tr>
            <tr class="deal-info__properties">
                <td class="deal-info__properties__text">
                    {{ this.$store.getters.content.CREATE_CALCULATOR_3 }}
                </td>
                <td class="deal-info__properties__text">
                    <CurrentCurrency :value="deal.amount + Math.ceil((deal.amount) * 0.05)" />
                </td>
            </tr>
            <tr class="deal-info__properties">
                <td class="deal-info__properties__text">
                    {{ this.$store.getters.content.CREATE_DESCRIPTION }}
                </td>
                <td class="deal-info__properties__text">
                    {{ deal.description }}
                </td>
            </tr>
        </table>
        <div class="deal-buttons">
            <primary-button v-if="isCanConfirm" @click="$emit('confirm')">{{ this.$store.getters.content.DEAL_BUTTON_CONFIRM }}</primary-button>
            <primary-button v-if="isCanPay" @click="$emit('pay')">{{ this.$store.getters.content.DEAL_BUTTON_PAY }}</primary-button>
            <linear-stroke-button class="deal-buttons__arbitrage-button" v-if="isCanArbitrage"
                @click="$emit('arbitrage')">{{ this.$store.getters.content.DEAL_BUTTON_ARBITRAGE }}</linear-stroke-button>
            <primary-button v-if="isCanUnrbitrage" @click="$emit('unarbitrage')">{{ this.$store.getters.content.DEAL_BUTTON_UNARBITRAGE }}</primary-button>
            <primary-button v-if="isCanClose" @click="$emit('close')">{{ this.$store.getters.content.DEAL_BUTTON_CLOSE }}</primary-button>
            <primary-button @click="isShowAdminArbitrage = true" v-if="isCanSolveArbitrage">Решить арбитраж</primary-button>
        </div>
        <window-wrapper :isShow="isShowAdminArbitrage" @hideWindow="isShowAdminArbitrage = false">
            <div class="deal-arbitrage primary-linear-stroke">
                <h2 class="deal-arbitrage__title">Решение арбитража</h2>
                <div class="deal-arbitrage__buttons">
                    <primary-button class="admin-close" @click="$emit('adminClose')">Закрыть сделку</primary-button>
                    <primary-button class="admin-cancel" @click="$emit('adminCancel')">Отменить сделку</primary-button>
                    <primary-button class="admin-unarbitrage" @click="$emit('adminUnarbitrage')">Отменить
                        арбитраж</primary-button>
                </div>
            </div>
        </window-wrapper>
        <DealChat @newSystemMessage="$emit('newSystemMessage')" :deal="deal" />
    </container>
</template>

<style scoped>
.deal-info {
    padding: 24px;
    border-radius: 16px;
    background: var(--neutral-background-90);
    width: 1016px;
    margin: auto;
    margin-top: 46px;
    display: block;
}

@media (max-width: 1200px) {
    .deal-info {
        width: 100%;
    }
}

.deal-info__properties {
    display: block;
    border-bottom: 1px solid transparent;
    border-image: var(--primary-linear-stroke) 1;
}

.deal-info__properties:last-child {
    border: none;
}

.deal-info__properties__text:nth-child(1) {
    width: 181px;
}

.deal-info__properties__text {
    font-size: 16px;
    font-weight: 400;
    color: var(--neutral-text);
    padding: 16px 0;
    vertical-align: top;
    white-space: pre-wrap;
    word-break: break-word;
}

@media (max-width: 800px) {
    .deal-info__properties {
        display: flex;
        flex-direction: column;
    }

    .deal-info__properties__text {
        color: var(--neutral-white);
        padding: 0;
    }

    .deal-info__properties__text:nth-child(1) {
        width: unset;
        opacity: 0.5;
        font-size: 14px;
        padding-top: 16px;
    }

    .deal-info__properties__text:nth-child(2) {
        padding: 4px 0 16px 0;
    }
}

.deal-buttons {
    width: 1016px;
    margin: auto;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    margin-top: 24px;
}

@media (max-width: 1200px) {
    .deal-buttons {
        width: 100%;
    }
}

.deal-buttons__arbitrage-button {
    padding: 14px 32px;
    width: unset;
    height: unset;
    font-size: 18px;
    font-weight: 400;
}

@media (max-width: 750px) {
    .deal-buttons {
        flex-direction: column;
    }

    .deal-buttons__item>button {
        width: 100%;
    }
}

.deal-arbitrage {
    padding: 25px;
    background-color: var(--neutral-background-90);
    width: fit-content;
    border-radius: 16px;
    margin: auto;
}

.deal-arbitrage__title {
    font-size: 18px;
    font-weight: 600;
    color: var(--neutral-white);
}

.deal-arbitrage__buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

.admin-cancel {
    background: var(--red) !important;
}

.admin-unarbitrage {
    background: var(--gray) !important;
}
</style>

<script>
import StatusProvider from "@/services/StatusProvider"
import DealStatusBar from "./DealStatusBar.vue"
import DealChat from "./DealChat.vue"
import CurrentCurrency from '../../currency/CurrentCurrency.vue'

export default {
    emits: ["confirm", "pay", "arbitrage", "unarbitrage", "close", "adminClose", "adminCancel", "adminUnarbitrage"],
    props: {
        deal: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isShowAdminArbitrage: false
        }
    },
    computed: {
        isCanConfirm() {
            if (this.deal.status != 1) {
                return false
            }
            let notCreatorId;
            switch (this.deal.creatorRole) {
                case 1:
                    notCreatorId = this.deal.performer.id
                    break
                case 2:
                    notCreatorId = this.deal.customer.id
                    break
            }
            if (this.$store.state.currentUser.id != notCreatorId) {
                return false
            }
            return true
        },
        isCanPay() {
            if (this.deal.status != 2) {
                return false
            }
            if (this.deal.customer.id != this.$store.state.currentUser.id) {
                return false
            }
            return true
        },
        isCanArbitrage() {
            if (this.deal.status != 3) {
                return false
            }
            if (this.deal.customer.id != this.$store.state.currentUser.id && this.deal.performer.id != this.$store.state.currentUser.id) {
                return false
            }
            return true
        },
        isCanUnrbitrage() {
            if (this.deal.status != 4) {
                return false
            }
            if (this.deal.arbitrageCreator.id != this.$store.state.currentUser.id) {
                return false
            }
            return true
        },
        isCanClose() {
            if (this.deal.status != 3) {
                return false
            }
            if (this.deal.customer.id != this.$store.state.currentUser.id) {
                return false
            }
            return true
        },
        isCanSolveArbitrage() {
            if (this.deal.status != 4) {
                return false
            }
            if (!this.$store.state.currentUser.roles.includes("Admin")) {
                return false
            }
            return true
        },
        status() {
            return StatusProvider.getStatusById(this.deal.status);
        }
    },
    components: { DealStatusBar, DealChat, CurrentCurrency }
}
</script>
