import axios from "axios"

const LanguageProvider = {
    async getLanguage() {
        const response = await axios.get("https://ipapi.co/country")
        switch (response.data) {
            case "RU":
            case "KZ":
            case "BY":
                return "RU"
            case "UA":
            case "PL":
                return "UA"
            default:
                return "EN"
        }
    },
    getContent(appName, appDomain) {
        return {
            "RU": {
                "HEADER_REVIEWS": "Отзывы",
                "HEADER_FORWHOM": "Для кого",
                "HEADER_SERTIFICATES": "Сертификаты",
                "HEADER_HOWITWORK": "Как это работает",
                "HEADER_NOTIFICATIONS": "Уведомления",
                "HEADER_DEALS": "Мои сделки",
                "HEADER_BALANCE": "Мой счёт",
                "HEADER_ADMIN": "Админ панель",
                "HEADER_SETTINGS": "Настройки",
                "HEADER_LOGOUT": "Выйти",
                "HEADER_LOGIN": "Войти",
                "HEADER_SIGNUP": "Создать аккаунт",
                "HEADER_CURRENT_LANGUAGE": "Язык",
                "FOOTER_COPY": "Гарант сервис",
                "PROMO_TITLE": "Безопасность ваших сделок – наш приоритет",
                "PROMO_DESCRIPTION": "Мы предлагаем надежный и безопасный Гарант-сервис для онлайн-сделок. Наши специалисты обеспечивают защиту ваших интересов и помогают избежать финансовых рисков при ведении сделок в Интернете.",
                "PROMO_BUTTON": "Создать сделку",
                "PROMO_BUTTON_AUTH": "Зарегистрироваться сейчас!",
                "SAFE_TITLE": "Что такое <span>безопасная</span> сделка?",
                "SAFE_DESCRIPTION_1": "Это сервис, который обеспечивает защиту как покупателя, так и продавца при совершении онлайн-сделок",
                "SAFE_DESCRIPTION_2": `При использовании этого сервиса, покупатель осуществляет 100% предоплату, которая хранится на счете гаранта ${appDomain.toLowerCase()} до тех пор, пока продавец не выполнит все свои обязательства и покупатель не получит свой товар или услугу. Кроме того, сервис гарантирует сохранность средств покупателя: если продавец нарушает условия сделки, гарант-сервис ${appDomain.toLowerCase()} возвращает средства или предлагает покупателю скидку.`,
                "SAFE_BUTTON": "Cовершить безопасную сделку",
                "STEPS_TITLE": "Что можно сделать через наш сервис?",
                "STEPS_DESCRIPTION": "Мы предоставляем безопасную сделку и гарантируем сохранность ваших средств до полного выполнения обязательств продавца и получения товара или услуги покупателем.",
                "STEPS_1_TITLE": "Cоздайте сделку",
                "STEPS_1_DESCRIPTION": "Заполните все поля, логин продавца, условия сделки, цену, сроки.",
                "STEPS_2_TITLE": "Дождитесь продавца",
                "STEPS_2_DESCRIPTION": "Продавец должен перейти в пункт Мои Сделки, после чего перейти на страницу самой сделки и принять.",
                "STEPS_3_TITLE": "Проведите сделку",
                "STEPS_3_DESCRIPTION": "Подробный процесс проведения сделки описан ниже.",
                "STEPS_4_TITLE": "Подтвердите оплату",
                "STEPS_4_DESCRIPTION": "Если товар соответствует заявленным характеристикам, после получения подтвердите платеж.",
                "SCHEME_TITLE": "Схема работы гарант сервиса",
                "SCHEME_1_TITLE": "Регистрация",
                "SCHEME_1_DESCRIPTION": "Услуги нашего сервиса безопасных сделок предоставляются только после того, как оба участника регистрируются на сайте.",
                "SCHEME_2_TITLE": "Обсуждение условий сделки",
                "SCHEME_2_DESCRIPTION": "Участники обсуждают характеристики услуг и сумму оплаты, выбирают платежную систему. Также обсуждают дальнейшие действия в случае претензии по услуге.",
                "SCHEME_3_TITLE": "Открытие сделки",
                "SCHEME_3_DESCRIPTION": "Заказчик открывает сделку, исполнитель её принимает. Если на данном этапе что-то идёт не так (исполнитель пропал), то сделка отменяется, оплата возвращается заказчику. Для общения и передачи информации используется внутренний чат.",
                "SCHEME_4_TITLE": "Исполнение обязательств",
                "SCHEME_4_DESCRIPTION": "Заказчик вносит деньги на баланс сделки, средства сразу же блокируются. Исполнитель видит это и передаёт услугу, после чего заказчик переводит деньги с баланса на баланс исполнителя. Исполнитель может отказаться от сделки и вернуть деньги заказчику. Суть в том, что участники могут переводить деньги с баланса только на баланс друг друга, но не на свои. В случае спора участники могут обратиться к гаранту сделок (арбитраж).",
                "SCHEME_5_TITLE": "Закрытие сделки",
                "SCHEME_5_DESCRIPTION": "Если на балансе сделки нет денег, то заказчик её закрывает. Сделка через гаранта завершена.",
                "ADVENTAGES_TITLE": `Почему ${appDomain.toLowerCase()} можно доверять?`,
                "ADVENTAGES_1": "Надежность",
                "ADVENTAGES_1_1": "Персональный аттестат Webmoney выдан в 2009 году.",
                "ADVENTAGES_1_2": "Больше двух лет на рынке цифровых и материальных товаров в сфере безопасной сделки.",
                "ADVENTAGES_1_3": "Система вычисления вероятности того, что партнер по сделке - мошенник.",
                "ADVENTAGES_1_4": "Актуальный черный список c автоматической проверкой всех пользователей.",
                "ADVENTAGES_1_5": "Финансовые транзакции проходят через счёт в государственном банке.",
                "ADVENTAGES_2": "Конфиденциальность",
                "ADVENTAGES_2_1": "Сделка проходит на закрытой странице, доступ к которой есть только у участников сделки и модератора сервиса.",
                "ADVENTAGES_2_2": "Запрет публикации данных для идентификации продаваемого товара/услуги.",
                "ADVENTAGES_2_3": "Простое урегулирование спорных ситуаций между продавцом и покупателем.",
                "ADVENTAGES_2_4": "Возможность проводить защищенные платежи между физическими лицами.",
                "ADVENTAGES_3": "Удобство",
                "ADVENTAGES_3_1": "Автоматизированное проведение сделки без участия реального поссредника.",
                "ADVENTAGES_3_2": "Круглосуточная работа сервиса - сервис доступен к использованию в любое время суток.",
                "ADVENTAGES_3_3": "Защита сделок купли-продажи 24/7 без похода к юристу и в банк.",
                "HEAD_TITLE": `${appDomain} в цифрах`,
                "HEAD_1": "Комиссия сервиса",
                "HEAD_2": "Выплачено",
                "HEAD_3": "Проведено сделок",
                "HEAD_BUTTON_AUTH": "Создать аккаунт",
                "SUPPORT_TITLE": "У вас остались вопросы?",
                "SUPPORT_DESCRIPTION": "Наша команда готова помочь вам ответить на любые вопросы, связанные с нашим сервисом. Просто обратитесь к нам в чате поддержки, и мы с радостью поможем вам решить любые возникшие проблемы.",
                "SUPPORT_BUTTON": "Открыть чат с поддержкой",
                "HOWITWORK_TITLE": `Как работает ${appDomain.toLowerCase()}`,
                "HOWITWORK_DESCRIPTION": `${appDomain.toLowerCase()} - сервис по защите торговых сделок в интернете, который гарантирует выполнение обязательств и снижает риски мошенничества и подлога.`,
                "HOWITWORK_STEP_TITLE": "Шаг",
                "HOWITWORK_STEP_1_DESCRIPTION": "Заказчик и исполнитель договорились о сделке",
                "HOWITWORK_STEP_2_DESCRIPTION": "Заказчик либо исполнитель создает сделку",
                "HOWITWORK_STEP_3_DESCRIPTION": "Заказчик переводит средства на баланс сделки, сумма замораживается",
                "HOWITWORK_STEP_4_DESCRIPTION": "Осуществляется выполнение услуги исполнителем",
                "HOWITWORK_STEP_5_DESCRIPTION": "Заказчик принимает и проверяет услугу на соответствие",
                "HOWITWORK_STEP_6_DESCRIPTION": "Заказчик подтверждает платёж, деньги переходят на баланс исполнителю.",
                "HOWITWORK_STAGE_TITLE": "Этапы сделки",
                "HOWITWORK_STAGE_DESCRIPTION": "Все защищенные сделки имеют определенные статусы в зависимости от этапа. Статусы управляются пользователями или сервисом, согласно заданных условий.",
                "HOWITWORK_STAGE_1_TITLE": "Статус «Ожидает подтверждения»",
                "HOWITWORK_STAGE_1_DESCRIPTION": "После того, как Покупатель или Продавец создали сделку, статус сделки становится «Ожидает подтверждение». В этом статусе Продавец или Покупатель должны подтвердить сделку на странице Мои сделки. В условиях сделки можно указать подробные условия, обязательные к исполнению одной или обеими сторонами – условия доставки, этапы оплаты, качество товаров/услуг, срок проверки или гарантийные обязательства.",
                "HOWITWORK_STAGE_2_TITLE": "Статус «Ожидается оплата»",
                "HOWITWORK_STAGE_2_DESCRIPTION": "После подтверждения сделки обеими сторонами, сделка переходит в статус «Ожидается оплата». Для совершения дальнейших действий Покупателю необходимо произвести оплату по сделке.",
                "HOWITWORK_STAGE_3_TITLE": "Статус «Оплачена»",
                "HOWITWORK_STAGE_3_DESCRIPTION": "На этой стадии Продавец должен передать товар или передать услугу оговоренным способом. После того, как товар будет передан, для проверки на соответствие покупателем.",
                "HOWITWORK_STAGE_4_TITLE": "Статус «Арбитраж»",
                "HOWITWORK_STAGE_4_DESCRIPTION": `Если стороны не пришли к соглашению (не соответствует товар заявленым характеристикам), то они могут прибегнуть к помощи опытных арбитражных консультантов ${appDomain.toLowerCase()}, которые изучат суть спора и предложат сторонам возможные компромиссные решения.`,
                "HOWITWORK_STAGE_5_TITLE": "Статус «Завершенная сделка»",
                "HOWITWORK_STAGE_5_DESCRIPTION": "На этой стадии Продавец должен передать товар или передать услугу оговоренным способом. После того, как товар будет передан, для проверки на соответствие покупателем.",
                "HOWITWORK_WARNING_TITLE": "Период защиты сделки",
                "HOWITWORK_WARNING_DESCRIPTION": "ВНИМАНИЕ! Период защиты сделки распространяется только в случае купли-продажи материальных товаров. Составляет 21 день с момента отправки товара, если в условиях сделки не указан иной срок. Если в течение периода защиты сделки Покупатель не осуществил действия «Принять Сделку» или «Открыть Претензию», сделка считается завершенной, деньги переводятся Продавцу.",
                "ADDBALANCE_TITLE": "Пополнение счета Monero (XMR)",
                "ADDBALANCE_DESCRIPTION_1": "Отправка средств происходит в РУЧНОМ режиме. Для осуществления отправки - Вам необходимо сделать следующие действия описанные ниже, после чего обратится в чат поддержки:",
                "ADDBALANCE_DESCRIPTION_2": "ВАЖНО: курс по заявке будет зафиксирован на 1 час! Если в течение этого времени не получено ни одно подтверждение от сети Monero, тогда курс зафиксируется в момент получения 1-го подтверждения, обмен выполнен после поступления средств на наш кошелек Monero.",
                "ADDBALANCE_STEP_1": "Шаг 1. Обратитесь в чат поддержки, укажите свой банк и сумму пополнения. Оператор подберет для Вас обменник.",
                "ADDBALANCE_STEP_2": "Шаг 2. Создайте заявку на сервисе обменника. Реквизиты кошелька Monero (XMR) сервиса:",
                "ADDBALANCE_STEP_3": "Шаг 3. Следуйте инструкциям обменника. Заполняете поля в заявке, указываете соответствующие данные в необходимые поля.",
                "ADDBALANCE_STEP_4": "Шаг 4. Предоставьте скриншот обработанной заявки в чат поддержки.",
                "ADDBALANCE_WARNING_TITLE": "ВНИМАНИЕ!",
                "ADDBALANCE_WARNING_DESCRIPTION": 'Оплата заявки на обменнике происходит в РУЧНОМ режиме. Вам требуется оплатить её В ПРИЛОЖЕНИИ БАНКА ПЕРЕВОДОМ по реквизитам, которые предоставит обменник, после перевода не забудьте нажать кнопку "Я оплатил" на обменнике.',
                "BACK": "Назад",
                "BLOCKEDBALANCE_TITLE": "Ваш счёт заблокирован",
                "BLOCKEDBALANCE_DESCRIPTION": "Вы не имеете права для просмотра этой страницы до его разблокировки.",
                "BLOCKED_TITLE": "Ваш аккаунт заблокирован",
                "BLOCKED_DESCRIPTION": "Ваш аккаунт был заблокирован администратором в связи с нарушением правил сайта.",
                "CREATE_TITLE": "Название сделки",
                "CREATE_TITLE_PLACEHOLDER": "Название . . .",
                "CREATE_ROLE": "Ваша роль",
                "CREATE_ROLE_1": "Заказчик",
                "CREATE_ROLE_2": "Исполнитель",
                "CREATE_LOGIN_PLACEHOLDER": "Введите логин . . .",
                "CREATE_DESCRIPTION": "Описание сделки",
                "CREATE_DESCRIPTION_PLACEHOLDER": "Описание . . .",
                "CREATE_SUM": "Сумма сделки",
                "CREATE_SUM_PLACEHOLDER": "Например, 2000",
                "CREATE_DURATION": "Срок (дней)",
                "CREATE_DURATION_PLACEHOLDER": "Например, 15",
                "CREATE_BUTTON": "Создать сделку",
                "CREATE_CALCULATOR_TITLE": "Расчет суммы сделки с учетом комиссии",
                "CREATE_CALCULATOR_1": "Сумма сделки",
                "CREATE_CALCULATOR_2": "Комиссия сервиса",
                "CREATE_CALCULATOR_3": "Итоговая сумма сделки",
                "ERROR_LOGIN_NOT_FOUND": "Пользователь с таким логином не найден",
                "ERROR_REQUIRED_FIELD": "Поле обязательно для заполнения",
                "ERROR_INCORRECT_NUMBER": "Введите корректное число",
                "ERROR_MIN_AMOUNT": "Минимальная сумма сделки: ",
                "ERROR_MIN_DURATION": "Минимальный срок: ",
                "ERROR_NOT_ENOUGH_BALANCE": "Недостаточно средств на счету",
                "ERROR_BLOCKEDBALANCE": "Ваш счёт заблокирован",
                "ERROR_LOW_AMOUNT": "Слишком маленькая сумма",
                "ERROR_INCORRECT_EMAIL": "Некорректный email",
                "ERROR_SHORT_PASSWORD": "Пароль слишком короткий",
                "ERROR_PASSWORD_REPEAT": "Пароли не совпадают",
                "ERROR_LOGIN": "Неправильный логин или пароль",
                "CHAT_TITLE": "Чат сделки",
                "CHAT_PLACEHOLDER": "Введите сообщение . . .",
                "LAST_ONLINE_CONTACT": "Собеседник ",
                "LAST_ONLINE_WAS": "был в сети ",
                "LAST_ONLINE_AGO": " назад",
                "LAST_ONLINE_ONLINE": "в сети",
                "STATUS": "Статус",
                "DEAL_STATUS": "Статус сделки",
                "DEAL_BUTTON_GO": "Перейти",
                "DEAL_BUTTON_CONFIRM": "Подтвердить участие",
                "DEAL_BUTTON_PAY": "Оплатить",
                "DEAL_BUTTON_ARBITRAGE": "Открыть арбитраж",
                "DEAL_BUTTON_UNARBITRAGE": "Отменить арбитраж",
                "DEAL_BUTTON_CLOSE": "Подтвердить",
                "BALANCE_BUTTON": "Пополнить счёт",
                "BALANCE_DESCRIPTION_1": `${appDomain.toLowerCase()} не является банком, платежной системой или иной финансовой организацией и не ведет расчетные счета пользователей.<br>Кабинет ${appDomain.toLowerCase()} обеспечивает лишь удобство расчетов между Клиентами.`,
                "BALANCE_DESCRIPTION_2": "Перевод осуществляется в течении 24ч.",
                "BALANCE_WARNING_DESCRIPTION": "на данный момент, переводы и выводы для вашей учетной записи приостановлены.",
                "BALANCE_WARNING_LINK": "Прочитать описание причины ограничений",
                "BALANCE_HISTORY_TITLE": "История",
                "BALANCE_HISTORY_TAB_1": "История пополнений",
                "BALANCE_HISTORY_TAB_2": "Выводы по реквизитам",
                "BALANCE_HISTORY_TAB_3": "Перевод пользователю",
                "OUTPUT_STATUS_1": "В обработке",
                "OUTPUT_STATUS_2": "Отменён",
                "OUTPUT_STATUS_3": "Одобрен",
                "DEPOSIT_DATE": "Дата",
                "DEPOSIT_TYPE": "Тип реквизита",
                "DEPOSIT_NUMBER_1": "Номер счета",
                "DEPOSIT_NUMBER_2": "Реквизиты",
                "DEPOSIT_AMOUNT": "Сумма",
                "DEPOSIT_STATUS": "Статус",
                "OUTPUT_BUTTON": "Создать перевод",
                "OUTPUT_NUMBER_PLACEHOLDER": "Реквизиты",
                "OUTPUT_AMOUNT_PLACEHOLDER": "Сумма",
                "TRANSACTION_SUCCESS": "Перевод отправлен",
                "TRANSACTION_LOGIN": "Логин пользователя",
                "TRANSACTION_LOGIN_PLACEHOLDER": "Логин",
                "TRANSACTION_AMOUNT": "Сумма",
                "TRANSACTION_AMOUNT_PLACEHOLDER": "Например, 450",
                "TRANSACTION_BUTTON": "Отправить",
                "NOTFOUND_TITLE": "Страница не найдена",
                "NOTFOUND_DESCRIPTION": "Запрашиваемая страница не найдена, убедитесь в правильности ссылки в адресной строке.",
                "NOTFOUND_BUTTON": "На главную",
                "NOTIFICATIONS_TITLE": `Уведомления от ${appDomain}`,
                "NOTIFICATIONS_VOID": "У вас пока нет от нас уведомлений...",
                "DEALS_TITLE": "Мои сделки",
                "DEALS_VOID": "Пока что тут пусто, потому что у вас еще ни одной сделки",
                "SETTINGS_TITLE": "Настройки аккаунта",
                "SETTINGS_LOGIN": "Имя пользователя",
                "SETTINGS_CURRENCY": "Отображаемая валюта",
                "SETTINGS_EMAIL_PLACEHOLDER": "Новая электронная почта",
                "SETTINGS_PASSWORD": "Пароль",
                "SETTINGS_PASSWORD_PLACEHOLDER": "Новый пароль",
                "SETTINGS_BUTTON": "Сохранить изменения",
                "SETTINGS_SUCCESS": "Изменения сохранены",
                "REGISTER_TITLE": "Регистрация",
                "REGISTER_LOGIN_PLACEHOLDER": "Логин",
                "REGISTER_PASSWORD_PLACEHOLDER": "Пароль",
                "REGISTER_PASSWORD_REPEAT_PLACEHOLDER": "Повторите пароль",
                "REGISTER_BUTTON": "Зарегистрироваться",
                "REGISTER_PRIVACY_1": "Я ознакомлен с",
                "REGISTER_PRIVACY_2": "Пользовательским соглашением",
                "REGISTER_HAS_ACCOUNT": "Уже есть аккаунт?",
                "LOGIN_NOT_HAS_ACCOUNT": "Нет учетной записи?",
                "REVIEWS_TITLE": "Отзывы",
                "REVIEWS_DESCRIPTION": "Мы собрали честные отзывы от наших пользователей и вот что они думают про нас",
                "REVIEWS_CARD_1": "Датская компания, управляющая веб-сайтом отзывов, основанная в 2007 году, на котором размещены многочисленные положительных отзывы о нашей деятельности.",
                "REVIEWS_CARD_2": "Самый полезный форум отзывов, где миллионы людей обмениваются полезным опытом, делятся фото и видео, ставят оценки и создают рейтинги лучших.",
                "REVIEWS_CARD_3": "Электронная система расчётов, основанная в 1998, где мы прошли аттестацию, что гарантирует вам проведение операции с конкретным человеком.",
                "REVIEWS_CARD_BUTTON": "Читать больше",
                "REVIEWS_PROMO": "Качество и гарантия безопасности - наши главные приоритеты для создания благоприятного сотрудничества. Для нас важно поддерживать имидж для продолжения стабильной работы. Интересы клиентов для нас всегда находятся в приоритете, благодаря чему мы показываем высокий гарант качества.",
                "REVIEWS_REVIEW_NAME": "Алина, дизайнер, г. Челябинск",
                "REVIEWS_REVIEW_TEXT": "Мы предлагаем надежный и безопасный Гарант-сервис для онлайн-сделок. Наша команда специалистов обеспечит защиту ваших интересов и поможет избежать финансовых рисков при проведении сделок в Интернете.",
                "REVIEWS_REVIEW_DEAL_BUTTON": "Начать сделку!",
                "REVIEWS_REVIEW_AUTH_BUTTON": "Зарегистрироваться сейчас!",
                "REVIEWS_WHYME_TITLE": "Почему мы?",
                "REVIEWS_WHYME_1_TITLE": "Надежность",
                "REVIEWS_WHYME_1_DESCRIPTION": "Персональный аттестат Webmoney выдан в 2009 году.",
                "REVIEWS_WHYME_2_TITLE": "Безопасность",
                "REVIEWS_WHYME_2_DESCRIPTION": "Финансовые транзакции проходят через счёт в государственном банке.",
                "REVIEWS_WHYME_3_TITLE": "Удобство",
                "REVIEWS_WHYME_3_DESCRIPTION": "Защита сделок купли-продажи 24/7 без похода к юристу и в банк.",
                "REVIEWS_WHYME_4_TITLE": "Автоматизация",
                "REVIEWS_WHYME_4_DESCRIPTION": "Защита сделок купли-продажи 24/7 без похода к юристу и в банк.",
                "REVIEWS_END_TEXT": "Превосходный сервис, всем рекомендую, мы продали аккаунтов более чем на 50к рублей именно через эту площадку",
                "SERTIFICATES_DESCRIPTION": `Используйте наш сервис ${appName}, не рискуя самостоятельно проверять человека на честность. Мошенник никогда и ни при каких условиях не согласиться проводить безопасную сделку.`,
                "SERTIFICATES_1": "Протокол SSL обеспечивает защищенный обмен данных между пользователями сервиса.",
                "SERTIFICATES_2": "Сертификат с расширенной проверкой (EV-сертификат) доказывает, что сайт не является одним из мошеннических или поддельных сайтов. Владелец сертификата проходит полную проверку подлинности согласно самым высоким стандартам индустрии безопасности. Проверку и выпуск сертификата осуществляет специализированный центр сертификации (GeoTrust).",
                "SERTIFICATES_3": "Операции с банковским картами и электронные платежи осуществляются через партнеров MandarinPay и Robokassa, имеющих действующие сертификаты безопасности PCI DSS версии 3.2",
                "SERTIFICATES_WARNING": `Помните! Никогда не проверяйте человека на честность самостоятельно, так как риск потерять ваш товар очень и очень велик! Используйте гарант сервис ${appName}. И помните, мошенник никогда, не при каких условиях не согласится на безопасную сделку, на гаранта.`,
                "FORWHOM_DESCRIPTION": `${appName} – сервис для приема платежей и защиты онлайн сделок услуг. Для физических и юридических лиц.`,
                "FORWHOM_CARD_1": "Продажа услуг по 100% предоплате",
                "FORWHOM_CARD_2": "Гарантия оплаты при выполнении обязательств",
                "FORWHOM_CARD_3": "Защита от подлога и мошенничества",
                "FORWHOM_STAGES_TITLE": "Этапы сделки",
                "FORWHOM_STAGES_DESCRIPTION": "Все защищенные сделки имеют определенные статусы в зависимости от этапа. Статусы управляются пользователями или сервисом, согласно заданных условий.",
                "FORWHOM_STAGES_1": "Преимущества сервиса",
                "FORWHOM_STAGES_1_1": "Контроль ведения сделки",
                "FORWHOM_STAGES_1_2": "Выгодно и надежно",
                "FORWHOM_STAGES_1_3": "Профессиональная команда по урегулированию споров",
                "FORWHOM_STAGES_1_4": "Прозрачные условия, автоматический расчет комиссии",
                "FORWHOM_STAGES_2": `Кому полезен ${appName}`,
                "FORWHOM_STAGES_2_1": `Виртуальные услуги - ${appName} защитит ваши интересы при продаже услуг, когда вы не можете заранее подтвердить качество исполнителя и не готовы рисковать, отдавая услуги без предоплаты.`,
                "FORWHOM_STAGES_2_2": `При продаже дорогой услуги ${appName} необходим, так как предоплата и проверка подлинности товара, неотъемлемые условия, которые позволяют провести сделку для обеих сторон без рисков.`,
                "FORWHOM_STAGES_2_3": `${appName} позволит заказчикам безопасно произвести предоплату онлайн, а вам получить гарантию, что заказчик заплатит после выполнения вами всех обязательств.`,
                "FORWHOM_STAGES_2_4": `Оказание услуг, в том числе удаленно - ${appName} упрощает удаленную работу, так как защищает стороны от риска неисполнения обязательств: Заказчик получает гарантию, что работа будет сделана, Исполнитель – оплату оказанных услуг. ${appName} обеспечит выполнение условий сделки.`,
                "FORWHOM_STAGES_3": "Предупрежден - значит вооружен",
                "FORWHOM_STAGES_3_1": "В последнее время набирает оборот очень хитрый способ мошенничества. Мошенник-Заказчик готов вам оплатить полную цену услуги. Вы предоставляете ему кошелек для оплаты, а вместо оплаты вам приходит счет, по которому вы должны оплатить довольно немалую комиссию. Это мошенничество! Ни одна платежная система не требует оплаты комиссии от человека, принимающего денежные средства. Если вы подтвердите оплату, вы просто переведете деньги лжезаказчику, который после их получения просто пропадет из сети.",
                "FORWHOM_STAGES_3_2": "Кардинг - если у Вас запрашивают CVV код карты и срок её действия - получив эти данные мошенник может снять с карты все деньги. Могут предоставить фейковый скриншот страницы оплаты.",
                "FORWHOM_STAGES_3_3": "Если вам пишет человек со страницы-однодневки (допустим, 20 друзей, 1 фото, нет старых записей на стене в vk.com), то это скорее всего мошенник, но в любом случае мошенник НИКОГДА не согласится на гаранта. То же самое относится и к скайпу. В скайпе можно регистрироваться хоть 100 раз в день, поэтому после совершения сделки вы можете просто не найти этого человека при появлении каких-либо проблем.",
                "FORWHOM_STAGES_3_4": "Будьте бдительны, не попадитесь на уловки мошенников",
                "FORWHOM_STAGES_4": "Перевод средств с сайта",
                "FORWHOM_STAGES_4_1": "Перевод средств с сайта производится в течении 24 часов, с 3% комиссией при переводе на Банковскую карту. Перевод на платежные системы Qiwi Wallet, Yandex Money, Webmoney, PayPal осуществляются с комиссией 1.5%",
                "FORWHOM_WARNING": `В случае выполнения обязательств перед Заказчиком денежные средства перейдут на ваш счет, при ненадлежащем исполнении условий сделки — возвращены Заказчику. Помните! Никогда не проверяйте человека на честность самостоятельно, так как риск потерять ваш товар очень и очень велик! Используйте гарант сервис ${appName}. Помните, мошенник никогда, не при каких условиях не согласится на безопасную сделку, на гарант сервис.`,
                "RULES_TITLE": "Пользовательское соглашение",
                "FROZEN": "заморожено",
                "FROZEN_TOOLTIP": "Замороженные средства будут доступны после завершения сделки",
                "RULES_TEXT": `
                    1. Термины и определения<br>
                    В настоящем Пользовательском соглашении используются следующие термины и определения:<br>
                    1.1. Сайт – совокупность программных и аппаратных средств, объединенных в пределах пространства доменного имени
                    ${appDomain.toLowerCase()}, обеспечивающих взаимодействие между Пользователями.<br>
                    1.2. Пользователь - физическое или юридическое лицо, зарегистрированное на Сайте и использующее его от своего
                    имени.<br>
                    1.3. Учетная запись (аккаунт) - уникальная запись Пользователя, которая создается на Сайте при регистрации,
                    защищена пользовательским паролем.<br>
                    1.4. Покупатель - Пользователь, осуществляющий поиск Продавцов в рамках любых ресурсов а так же Сайта, с целью
                    заказа определенного вида работ.<br>
                    1.5. Продавец - Пользователь, предоставляющий услуги выполнения различного рода работ.<br>
                    1.6. Контент - данные, информация, материалы, документы, файлы, сообщения и т.п. в электронной форме,
                    расположенные на Сайте.<br>
                    2. Описание услуг<br>
                    Биржа ${appDomain.toLowerCase()} предлагает Пользователям доступ к широкому спектру сервисов, без перерывов, за исключением
                    времени проведения необходимых ремонтных и регламентных работ. Все существующие на данный момент сервисы, а
                    также любое развитие их и/или добавление новых является предметом настоящего Соглашения. Вы понимаете и
                    соглашаетесь с тем, что все сервисы предоставляются по принципу "как есть" и что Администрация не несет
                    ответственности ни за какие задержки, сбои, неверную или несвоевременную доставку, удаление или несохранность
                    какой-либо пользовательской персональной информации.<br>
                    3. Предмет соглашения<br>
                    3.1. Пользователь, прошедший регистрацию, имеет возможность использовать Сайт и получает право на следующие виды
                    услуг:<br>
                    3.1.1. Проведение сделок.<br>
                    3.1.2. Поиск Продавцов для выполнения различного рода работ.<br>
                    4.Предоставление услуг<br>
                    4.1. Персонал Сайта обладает правом на следующие действия:<br>
                    4.1.1. Отказать Пользователю в предоставлении услуг без указания причин в следующих случаях:<br>
                    4.1.1.1. Нарушения Пользователем условий настоящего Соглашения либо действующих правил.<br>
                    4.1.1.2. Невыполнения Пользователем финансовых обязательств перед Сайтом и/или третьими лицами.<br>
                    4.1.1.3. Наличия претензий в адрес Пользователя, подкрепленных доказательствами в рамках Сайта.<br>
                    4.1.1.4. Возникновения у персонала Сайта сомнений в надлежащем использовании Сайта Пользователем.<br>
                    4.1.1.5. Попытки со стороны Пользователя причинения любого вида ущерба Сайту.<br>
                    4.1.1.6. Отказа Пользователя от выполнения каких-либо требований со стороны персонала Сайта.<br>
                    4.1.1.7. Проявления неуважения со стороны Пользователя по отношению к персоналу Сайта и/или другим Пользователям
                    Сайта.<br>
                    4.1.2. Удалить любую информацию со страниц Сайта без указания причин либо отказать в её размещении.<br>
                    4.1.3. Требовать от Пользователя предъявления документов, удостоверяющих личность, а также предоставления
                    дополнительных данных, идентифицирующих Пользователя, в следующих случаях:<br>
                    4.1.3.1. При наличии оснований предполагать, что Пользователь нарушает действующее законодательство, условия
                    настоящего Соглашения, права и интересы персонала Сайта и/или третьих лиц.<br>
                    4.1.3.2. Иных случаях необходимости идентификации Пользователя по усмотрению персонала Сайта.<br>
                    4.1.4. Приостановить работу программного и/или аппаратного обеспечения Сайта в целях обновления, а также
                    проведения профилактических работ.<br>
                    4.1.5. Не обслуживать запросы к Сайту с анонимных прокси-серверов.<br>
                    5. Права и Обязанности Пользователя Сайта<br>
                    5.1.Пользователь Сайта ${appDomain.toLowerCase()} имеет следующие права и обязанности:<br>
                    5.1.1. Регистрировать только одну Учетную Запись.<br>
                    5.1.2. Предоставить достоверные персональные данные и в дальнейшем поддерживать их в актуальном состоянии.<br>
                    5.1.3. Следовать предписаниям и строго соблюдать действующие правила, определяющие порядок использования
                    Сайта.<br>
                    5.1.4. Не использовать Сайт в противоправных целях.<br>
                    5.1.5. Не совершать противозаконных действий с использованием Сайта.<br>
                    5.1.6. Не разглашать конфиденциальную информацию, используемую для доступа к Учетной Записи.<br>
                    5.2. Пользователям Сайта ${appDomain.toLowerCase()} запрещено:<br>
                    5.2.1. Использовать в своих сообщениях нецензурных слов, брани, оскорбительных для других Пользователей.<br>
                    5.2.2. Использовать чужие работы, не имеющие отношения к Вашему личному портфолио<br>
                    5.2.3. Понижать/повышать рейтинг несанкционированными методами (при помощи отзывов, рекомендаций, рекламных
                    сообщений в блогах, проектах, личных сообщениях, комментариях).<br>
                    5.2.4. Нарушать права несовершеннолетних лиц и/или причинение им вреда в любой форме.<br>
                    5.2.5. Загружать, посылать, передавать или любым другим способом размещать контент, который Вы не имеете права
                    делать доступным по закону Российской Федерации или согласно каким-либо контрактным отношениям.<br>
                    5.2.6. Нарушения любым способом нормальной работы сайта ${appDomain.toLowerCase()};<br>
                    5.2.7. Размещения ссылок на ресурсы, содержание которых противоречит действующему законодательству России;<br>
                    5.2.8. Массовая публикация сообщений (комментариев, отзывов, личных сообщений пользователям) рекламного
                    характера (спам) и флуда;<br>
                    5.2.9. Размещение и пропаганда порнографии и детской эротики, а также реклама интимных услуг<br>
                    5.2.10 Переводы доступны пользователю, после того как он пополнит счет на сайте, именно что сумма пополнения
                    устанавливается для каждого индивидуально, в зависимости от проведенной(их) им сделок. Средства получены иным
                    путем (переводом от других пользователей, проведением сделок), учитываться не будут.<br>
                    5.2.11 Администрация сервиса, обязует себя установить сумму минимального пополнения исходя из сделок на сайте на
                    момент перевода, для доступа пользователю к переводам валюты сайта другим пользователям и доп. функциям сервиса.
                    Так же минимальня сумма пополнения нужна для верификации личности.<br>
                    5.2.12 В случае нарушения данных условий и прочих обязательств по данному Соглашению Администрация ресурса
                    ${appDomain.toLowerCase()} (или представители администрации в лице модераторов) имеют право:<br>
                    предупредить вас в письменном виде;<br>
                    заблокировать ваш аккаунт;<br>
                    обнулить ваш рейтинг.<br>
                    5.2.13 Все реальные платежи которые поступают на баланс сервиса являются пожертвованием на развитие проэкта,
                    взамен на это, Администрация сервиса пополняет счет пользователя который пожертвовал n-ю сумму, на сумму равну
                    пожертвованием пользователя.<br>
                    6. Ответственность сторон<br>
                    6.1. Персонал Сайта не несет ответственность:<br>
                    6.1.1. За действия Пользователей, нарушающие действующее законодательство.<br>
                    6.1.2. За информацию и материалы, размещаемые Пользователями в рамках Сайта.<br>
                    6.1.3. За качество товаров и услуг, предоставляемых Пользователями и самим сервисом ${appDomain.toLowerCase()}<br>
                    6.1.4. За какой-либо ущерб и/или упущенную выгоду Пользователей и/или третьих лиц в результате использования или
                    невозможности использования Сайта.<br>
                    6.1.5 За незнание правил сервиса, а так же за незнание тех. или иных пунктов которые касаются самого
                    пользователя. PS: Незнание закона не освобождает от ответственности. <br>
                    6.2. Пользователь несет
                    ответственность:<br>
                    6.2.1. За все действия, осуществленные от имени Пользователя в рамках Сайта.<br>
                    6.2.2. За качество и своевременность выполнения принятых в рамках Сайта обязательств.<br>
                    6.2.3. За безопасность конфиденциальной информации, используемой для доступа к Учетной Записи.<br>
                    6.2.4. В случае блокировок/подозрений пользователя(лей) в Frod платеже, для разблокировки счёта, проводится
                    повторная транзакция на ту же сумму (последняя сумма), что была проведена перед блокировкой с иной Банковской
                    карты. Если у пользователя нет такой возможности, Администрация обязует себя удалить платежные данные
                    пользователя, по запросу самого пользователя, для проведения транзакции с той же карты, что была совершена перед
                    блокировкой.<br>
                    6.3 В соответствии с правилами проведения платежей на сервисе, во избежания отмыва/мошенничества через сервис,
                    пользователь обязан проводить все транзакции с реквизитов которые были внесены изначально.<br>
                    7. Расторжение Соглашения<br>
                    Вы согласны с тем, что Сайт ${appDomain.toLowerCase()} оставляет за собой право прекратить действие Вашей Учетной записи и удалить
                    любой контент по любой причине, в том числе при неиспользовании доступа или при нарушении правил Соглашения.<br>
                    7.1. Персонал Сайта обладает правом в одностороннем порядке вносить изменения в настоящее Соглашение, а также
                    действующие правила.<br>
                    7.2. Использование Сайта после внесения изменений означает безусловное принятие (акцепт) Пользователем условий
                    обновленной редакции Соглашения.<br>
                    7.3. Персонал Сайта обладает правом в одностороннем порядке аннулировать Соглашение, если Учетная запись
                    Пользователя не использовалась более 12 календарных месяцев подряд. Под использованием Учетной Записи
                    подразумевается авторизация Пользователя на Сайте. 7.4. ${appDomain.toLowerCase()} может в любой момент закрыть любой из своих
                    сервисов, с или без предварительного уведомления. ${appDomain.toLowerCase()} также не несет никакой ответственности за прекращение
                    доступа к своим сервисам.<br>
                    8. Принятие условий Соглашения<br>
                    8.1. Безусловным принятием (акцептом) условий настоящего Соглашения является регистрация на Сайте.<br>
                    8.2. Принятие условий настоящего Соглашения означает безусловное принятие Пользователем условий Политики
                    конфиденциальности, а также остальных действующих правил.<br>
                    9. Освобождение от гарантий<br>
                    9.1. Вы понимаете и соглашаетесь с тем, что:<br>
                    9.1.1. Вы используете сервисы ${appDomain.toLowerCase()} на Ваш собственный риск. Службы предоставляются "как есть". ${appDomain.toLowerCase()} не
                    принимает на себя никакой ответственности, в том числе и за соответствие сервисов цели пользователя;<br>
                    9.1.2. ${appDomain.toLowerCase()} не гарантирует, что: сервисы будут соответствовать Вашим требованиям; сервисы будут
                    предоставляться непрерывно, быстро, надежно и без ошибок; результаты, которые могут быть получены с
                    использованием сервисов, будут точными и надежными; качество какого-либо продукта, услуги, информации и пр.,
                    полученного с использованием сервиса, будет соответствовать Вашим ожиданиям; все ошибки в программах будут
                    исправлены;<br>
                    9.1.3. Любые материалы, полученные Вами с использованием сервисов ${appDomain.toLowerCase()}, Вы можете использовать на свой
                    собственный страх и риск, на Вас возлагается ответственность за любой ущерб, который может быть нанесен Вашему
                    компьютеру и Вашим данным в результате загрузки этих материалов; Валюта на сайте является крипто-валютой и не
                    имеет реальной ценности. Валюту может приобрести любой пользователь, как с помощю сделок, так и купив ее за
                    реальные деньги.<br>
                    9.1.4.${appDomain.toLowerCase()} не несет ответственности за любые прямые или непрямые убытки, произошедшие из-за: использования
                    либо невозможности использования сервисов; несанкционированного доступа к Вашим коммуникациям; заявления или
                    поведение любого третьего лица в сервисах.<br>
                    10. Сервисы, сайты и контент третьих лиц<br>
                    10.1. Сервисы Сайта могут содержать ссылки на другие сервисы и сайты в сети Интернет (сервисы и сайты третьих
                    лиц), включая услуги онлайн-общения (электронная почта, Skype, ICQ), конструктор договоров и т.п. Указанные
                    третьи лица и их контент не проверяются на соответствие тем или иным требованиям (достоверности, полноты,
                    законности и т.п.). Сервис находится в бета тесте, вывoды на реальные электронные кошельки, банковские карты и
                    тп. не возможны. Биржа ${appDomain.toLowerCase()} не несет ответственность за любую информацию, материалы, размещенные на сервисах
                    и сайтах третьих лиц, к которым Пользователь получает доступ с использованием сервисов Сайта, в том числе, за
                    любые мнения или утверждения, выраженные на сервисах и сайтах третьих лиц, рекламу и т.п., а также за
                    доступность таких сервисов и сайтов или контента и последствия их использования Пользователем.<br>
                    10.2. Ссылка (в любой форме) на любой сайт, продукт, услугу, любую информацию коммерческого или некоммерческого
                    характера, размещенная на Сайте, не является одобрением или рекомендацией данных продуктов (услуг,
                    деятельности), за исключением случаев, когда на это прямо указывается Сайтом.<br>
                    11. Порядок рассмотрения споров<br>
                    11.1. Соглашение является юридически обязывающим договором между Вами и ${appDomain.toLowerCase()} и регламентирует использование
                    Вами сервисов ${appDomain.toLowerCase()}. Соответствующими договорами на Вас также могут быть наложены дополнительные
                    обязательства, связанные с использованием других сервисов, а также контента или программного обеспечения,
                    принадлежащего третьей стороне.<br>
                    11.2. Вы и ${appDomain.toLowerCase()} соглашаетесь на то, что все возможные споры по поводу Соглашения будут разрешаться по нормам
                    российского права.<br>
                    11.3. Ничто в Соглашении не может пониматься как установление между Вами и ${appDomain.toLowerCase()} агентских отношений,
                    отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то иных
                    отношений, прямо не предусмотренных Соглашением.<br>
                    11.4. Признание судом какого-либо положения Соглашения недействительным или не подлежащим принудительному
                    исполнению не влечет недействительности или неисполнимости иных положений Соглашения.<br>
                    11.5. Бездействие со стороны ${appDomain.toLowerCase()} в случае нарушения Вами либо иными пользователями положений Соглашения не
                    лишает ${appDomain.toLowerCase()} права предпринять соответствующие действия в защиту своих интересов позднее, а также не означает
                    отказа ${appDomain.toLowerCase()} от своих прав в случае совершения в последующем подобных либо сходных нарушений.<br>
                    12. Вступление соглашения в силу и действие<br>
                    12.1. Соглашение вступает в силу с момента акцепта настоящей оферты Покупателем. Безусловным принятием
                    (акцептом) условий настоящей оферты Соглашения считается осуществление Покупателем регистрации на Сайте путем
                    заполнения регистрационной формы и выражения согласия с условиями Соглашения при нажатии кнопки
                    «Зарегистрироваться», размещенной на странице Сайта с регистрационной формой. Безусловным принятием (акцептом)
                    всех условий предоставления Платных услуг также считается осуществление Покупателем платежа в счет оплаты
                    Платной услуги.<br>
                    12.2. Соглашение заключается на неопределенный срок и может быть расторгнуто по инициативе любой из Сторон в
                    любое время. Для этого Продавец размещает уведомление о расторжении Соглашения на Сайте и/или направляет
                    Покупательу на его Профиль (или регистрационные данные) соответствующее уведомление, с момента такого
                    размещения/направления такого уведомления Соглашение считается расторгнутым. Покупатель может расторгнуть
                    Соглашение путем запроса на удаление своего Профиля с Сайта, при этом далее обработка персональных данных
                    осуществляется согласно пунктам 4.8. - 4.11. данного соглашения.<br>
                    12.3. Настоящее Соглашение распространяет свое действие на Покупательов, осуществивших регистрацию до даты
                    опубликования настоящего Соглашения на Сайте.<br>
                    12.4. Пользователь обязуется самостоятельно знакомиться с условиями Соглашения. Если Пользователь не согласен с
                    условиями настоящего Соглашения, то он должен немедленно удалить свой Профиль с Сайта, в противном случае
                    продолжение использования Пользователем Сайта означает, что Пользователь согласен с условиями Соглашения.<br>
                    12.5. При определении сроков определение времени совершения Стороной или третьими лицами действия (бездействия)
                    исчисляется по Московскому времени.<br>
                    13. Прочие условия<br>
                    13.1. Стороны договорились, что при исполнении (изменении, дополнении, прекращении) Соглашения допускается
                    использование подписей представителей Сторон, а также их печатей, с помощью средств факсимильной связи,
                    механического или иного копирования, электронно-цифровой подписи либо иного аналога собственноручной подписи
                    руководителей и печатей организаций. Стороны подтверждают, что документы к Соглашению, подписанные и оформленные
                    указанным в настоящем пункте способом, имеют юридическую силу и обязательны для исполнения Сторонами.<br>
                    13.2. Стороны признают любую информацию, касающуюся заключения Соглашения, включая любые приложения и дополнения
                    к нему, коммерческой тайной и обязуются строго сохранять конфиденциальный характер такой информации, не
                    разглашая ее третьим лицам без предварительного письменного на то согласия другой Стороны, за исключением
                    случаев, когда это необходимо для целей Соглашения или для раскрытия соответствующим государственным органам в
                    случаях, определенных законом. Указанное положение не относится к общеизвестной или общедоступной информации.
                    Стороны считают конфиденциальной всю деловую информацию, передаваемую друг другу, как-то: информация об их
                    клиентах, партнерах, бизнес – планах, ценах, финансовом состоянии и т.д. Стороны не должны открывать такую
                    информацию кому бы то ни было, за исключением случаев, когда это необходимо для надлежащего выполнения их
                    обязательств по Соглашению, или если такая информация является общедоступной, или по взаимному согласованию
                    Сторон.<br>
                    13.3. Любые уведомления, запросы или иные сообщения (корреспонденция), представляемые Сторонами друг другу
                    должны быть оформлены в письменном виде и направлены получающей Стороне по почте, путем направления заказной
                    корреспонденции, по электронной почте, факсу или с курьером, как будет сочтено целесообразным. Датой получения
                    корреспонденции считается момент получения почтового отправления, в том числе заказной корреспонденции,
                    электронного подтверждения доставки при отправлении электронной почтой или по факсу, или день доставки в случае
                    отправления корреспонденции с курьером. При рассмотрении споров в cуде переписка Сторон по электронной почте,
                    факсимильные сообщения будут признаны Сторонами достаточными доказательствами, если настоящим Соглашением прямо
                    не предусмотрен иной способ направления корреспонденции.<br>
                    14. Прекращение действия учетной записи Пользователя<br>
                    14.1. Пользователь согласен с тем, что Общество оставляет за собой право прекратить действие учетной записи
                    Пользователя на ${appDomain.toLowerCase()} в любое время без предварительного уведомления Пользователя.<br>
                    14.2. Прекращение действия учетной записи может быть произведено в связи со следующими причинами: а) нарушение
                    положений настоящего Соглашения, а также дополнений к нему, являющихся их неотъемлемой частью; б) по
                    соответствующему запросу органов власти, согласно законодательству Российской Федерации; в) в случае
                    распространения Пользователем неподтвержденной негативной информации об Обществе; г) в связи с непредвиденными
                    проблемами технического характера или обстоятельствами, связанными с безопасностью и т.п.<br>
                    14.3. Общество вправе удалить учетную запись Пользователя на ${appDomain.toLowerCase()} и/или приостановить, ограничить или
                    прекратить доступ к любому из Сервисов, если Общество обнаружит в действиях Пользователя признаки нарушения
                    условий настоящего Соглашения, без объяснения причин таких действий.<br>
                    15. Исключительные права на содержание Сервисов и контент<br>
                    15.1. Пользователь обязуется не воспроизводить, не копировать, не повторять, не продавать и не перепродавать, а
                    также не использовать для каких-либо коммерческих целей и не доводить до всеобщего сведения, не распространять
                    программы ${appDomain.toLowerCase()}, целиком либо какие-либо части Сервисов ${appDomain.toLowerCase()} (включая контент, доступный Пользователю
                    посредством Сервисов), или доступ к ним, кроме тех случаев, когда Пользователь предварительно получил такое
                    разрешение от Общества.<br>
                    15.2. Все объекты, доступные при помощи Сервисов и ${appDomain.toLowerCase()}, в том числе элементы дизайна, текст, графические
                    изображения, иллюстрации, видео, программы для ЭВМ, базы данных, музыка, звуки и другие объекты (далее —
                    содержание Сервисов), а также любой контент, размещенный на Сервисах и ${appDomain.toLowerCase()}, являются объектами
                    исключительных прав Правообладателя, и других правообладателей.<br>
                    15.3. Использование контента, а также каких-либо иных элементов Сервисов и ${appDomain.toLowerCase()} возможно только в рамках
                    функционала, предлагаемого тем или иным Сервисом. Никакие элементы содержания Сервисов и ${appDomain.toLowerCase()}, а также любой
                    контент, размещенный в Сервисах и ${appDomain.toLowerCase()}, не могут быть использованы иным образом без предварительного
                    разрешения правообладателя. Под использованием подразумеваются, в том числе: воспроизведение, копирование,
                    переработка, распространение на любой основе, отображение во фрейме и т.д. Исключение составляют случаи, прямо
                    предусмотренные законодательством РФ.<br>
                    15.4. Использование Пользователем элементов содержания Сервисов, а также любого контента для личного
                    некоммерческого использования, допускается при условии сохранения всех знаков охраны авторского права, смежных
                    прав, товарных знаков, других уведомлений об авторстве, сохранения имени (или псевдонима) автора/наименования
                    правообладателя в неизменном виде, сохранении соответствующего объекта в неизменном виде. Исключение составляют
                    случаи, прямо предусмотренные законодательством РФ.<br>
                    16. Иные положения<br>
                    16.1. Настоящее Соглашение представляет собой договор между Пользователем и Обществом относительно порядка
                    использования Сервисов ${appDomain.toLowerCase()} и заменяет собой все предыдущие соглашения между Пользователем и Обществом по
                    вышеуказанным вопросам.<br>
                    16.2. Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации.
                    Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством
                    Российской Федерации. Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением,
                    разрешаются в порядке, установленном действующим законодательством Российской Федерации, по нормам российского
                    права. Везде по тексту настоящего Соглашения, если явно не указано иное, под термином «законодательство»
                    понимается как законодательство Российской Федерации.<br>
                    16.3. Ввиду безвозмездности услуг, оказываемых в рамках настоящего Соглашения, нормы о защите прав потребителей,
                    предусмотренные законодательством Российской Федерации, не могут быть применимыми к отношениям между
                    Пользователем (Продавцом и/или Покупателем) и Обществом.<br>
                    16.4. Ничто в Соглашении не может пониматься как установление между Пользователем и Обществом отношений
                    товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то иных отношений, прямо
                    не предусмотренных Соглашением.<br>
                    16.5. Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны
                    недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или
                    применимость остальных положений Соглашения.<br>
                    16.6. Бездействие со стороны Общества в случае нарушения Пользователем либо иными пользователями положений
                    Соглашений не лишает Общества права предпринять соответствующие действия в защиту своих интересов позднее, а
                    также не означает отказа Общества от своих прав в случае совершения в последующем подобных либо сходных
                    нарушений.<br>
                    16.7. Любой реальный платеж, перевод реальных средств сайту, являются добровольным, администрация может только
                    проинформировать о минимальной сумме пополнения или же дать советы как это сделать. Никто никаким образом не
                    принуждает и не заставляет кого либо переводить средства сайту, это чисто добровольное и осознанное решение
                    пользователя.
                `
            },
            "EN": {
                "HEADER_REVIEWS": "Reviews",
                "HEADER_FORWHOM": "For whom",
                "HEADER_SERTIFICATES": "Sertificates",
                "HEADER_HOWITWORK": "How it works",
                "HEADER_NOTIFICATIONS": "Notifications",
                "HEADER_DEALS": "My deals",
                "HEADER_BALANCE": "Balance",
                "HEADER_ADMIN": "Admin panel",
                "HEADER_SETTINGS": "Settings",
                "HEADER_LOGOUT": "Logout",
                "HEADER_LOGIN": "Log in",
                "HEADER_SIGNUP": "Sign up",
                "HEADER_CURRENT_LANGUAGE": "Language",
                "FOOTER_COPY": "Garant service",
                "PROMO_TITLE": "The security of your deals is our priority",
                "PROMO_DESCRIPTION": "We offer a reliable and secure Guarantor service for online deals. Our specialists ensure the protection of your interests and help you avoid financial risks when conducting deals on the Internet.",
                "PROMO_BUTTON": "Create a deal",
                "PROMO_BUTTON_AUTH": "Register now!",
                "SAFE_TITLE": "What is a <span>safe</span> deal?",
                "SAFE_DESCRIPTION_1": "It is a service that protects both the buyer and seller in online deals",
                "SAFE_DESCRIPTION_2": `When using this service, the buyer makes a 100% prepayment, which is stored in the guarantor account ${appDomain.toLowerCase()} until the seller fulfills all his obligations and the buyer receives his product or service. In addition, the service guarantees the safety of the buyer's funds: if the seller violates the terms of the deal, the ${appDomain.toLowerCase()} guarantor service returns the funds or offers the buyer a discount.`,
                "SAFE_BUTTON": "Make a safe deal",
                "STEPS_TITLE": "What can I do through our service?",
                "STEPS_DESCRIPTION": "We provide a secure deal and guarantee the safety of your funds until the seller's obligations are fully fulfilled and the buyer receives the product or service.",
                "STEPS_1_TITLE": "Create a deal",
                "STEPS_1_DESCRIPTION": "Fill in all fields, seller login, terms of the deal, price, terms.",
                "STEPS_2_TITLE": "Wait for the seller",
                "STEPS_2_DESCRIPTION": "Seller should go to My Deals, then go to the page of the deal itself and accept.",
                "STEPS_3_TITLE": "Make a deal",
                "STEPS_3_DESCRIPTION": "The detailed deal process is described below.",
                "STEPS_4_TITLE": "Confirm payment",
                "STEPS_4_DESCRIPTION": "If the item meets the stated specifications, confirm payment upon receipt.",
                "SCHEME_TITLE": "Service Guarantee Scheme",
                "SCHEME_1_TITLE": "Registration",
                "SCHEME_1_DESCRIPTION": "The services of our safe deal service are provided only after both participants register on the site.",
                "SCHEME_2_TITLE": "Discussing the terms of the deal",
                "SCHEME_2_DESCRIPTION": "Participants discuss the characteristics of the services and the amount of payment, choose a payment system. They also discuss further actions in case of a service claim.",
                "SCHEME_3_TITLE": "Opening a deal",
                "SCHEME_3_DESCRIPTION": "The customer opens the deal, the contractor accepts it. If something goes wrong at this stage (the contractor is missing), the deal is canceled and the payment is returned to the customer. Internal chat is used for communication and information transfer.",
                "SCHEME_4_TITLE": "Fulfillment of obligations",
                "SCHEME_4_DESCRIPTION": "The customer deposits money to the balance of the deal, the funds are immediately blocked. The contractor sees this and transfers the service, after which the customer transfers money from the balance to the contractor's balance. The contractor can refuse the deal and return the money to the customer. The point is that participants can only transfer money from their balance to each other's balance, but not to their own. In case of a dispute, participants can turn to the guarantor of deal (arbitration).",
                "SCHEME_5_TITLE": "Closing a deal",
                "SCHEME_5_DESCRIPTION": "If there is no money on the balance of the deal, the customer closes it. The deal through the guarantor is completed.",
                "ADVENTAGES_TITLE": `Why ${appDomain.toLowerCase()} can be trusted?`,
                "ADVENTAGES_1": "Reliability",
                "ADVENTAGES_1_1": "Webmoney personal attestation was issued in 2009.",
                "ADVENTAGES_1_2": "More than two years in the market of digital and tangible goods in the field of safe deal.",
                "ADVENTAGES_1_3": "A system for calculating the probability that a deal partner is a fraudster.",
                "ADVENTAGES_1_4": "Up-to-date blacklist c automatic verification of all users.",
                "ADVENTAGES_1_5": "Financial transactions go through a state bank account.",
                "ADVENTAGES_2": "Privacy",
                "ADVENTAGES_2_1": "The deal takes place on a private page that only the participants in the deal and the moderator of the service have access to.",
                "ADVENTAGES_2_2": "Prohibiting the publication of data to identify the product/service being sold.",
                "ADVENTAGES_2_3": "Simple settlement of disputes between the seller and the buyer.",
                "ADVENTAGES_2_4": "Ability to conduct secure payments between individuals.",
                "ADVENTAGES_3": "Convenience",
                "ADVENTAGES_3_1": "Automated deal execution without the involvement of a real intermediary.",
                "ADVENTAGES_3_2": "Round-the-clock operation of the service - the service is available for use at any time of day.",
                "ADVENTAGES_3_3": "Protection of sale and purchase deals 24/7 without going to a lawyer or bank.",
                "HEAD_TITLE": `${appDomain} in numbers`,
                "HEAD_1": "Service fee",
                "HEAD_2": "Paid out",
                "HEAD_3": "Completed deals",
                "HEAD_BUTTON_AUTH": "Create an account",
                "SUPPORT_TITLE": "Do you still have questions?",
                "SUPPORT_DESCRIPTION": "Our team is ready to help you answer any questions related to our service. Just contact us in our support chat and we will be happy to help you solve any problems you may have.",
                "SUPPORT_BUTTON": "Open a chat with support",
                "HOWITWORK_TITLE": `How the ${appDomain.toLowerCase()} works`,
                "HOWITWORK_DESCRIPTION": `${appDomain.toLowerCase()} - service to protect online trading deals, which guarantees fulfillment of obligations and reduces the risks of fraud and forgery.`,
                "HOWITWORK_STEP_TITLE": "Step",
                "HOWITWORK_STEP_1_DESCRIPTION": "The customer and the contractor agree on a deal",
                "HOWITWORK_STEP_2_DESCRIPTION": "The customer or the contractor creates the deal",
                "HOWITWORK_STEP_3_DESCRIPTION": "The customer transfers funds to the balance of the deal, the amount is frozen",
                "HOWITWORK_STEP_4_DESCRIPTION": "The fulfillment of the service by the contractor is carried out",
                "HOWITWORK_STEP_5_DESCRIPTION": "The customer accepts and checks the service for compliance",
                "HOWITWORK_STEP_6_DESCRIPTION": "The customer confirms the payment, the money is transferred to the balance of the contractor.",
                "HOWITWORK_STAGE_TITLE": "Deal stages",
                "HOWITWORK_STAGE_DESCRIPTION": "All protected deals have certain statuses depending on the stage. The statuses are managed by users or the service, according to the set conditions.",
                "HOWITWORK_STAGE_1_TITLE": "Status «Pending confirmation»",
                "HOWITWORK_STAGE_1_DESCRIPTION": "After the Buyer or Seller has created a deal, the status of the deal becomes «Pending Confirmation». In this status, the Seller or Buyer must confirm the deal on the My Deals page. In the terms and conditions of the deal you can specify detailed conditions binding on one or both parties - delivery terms, payment stages, quality of goods/services, inspection period or warranty obligations.",
                "HOWITWORK_STAGE_2_TITLE": "Status «Pending payment»",
                "HOWITWORK_STAGE_2_DESCRIPTION": "After the deal is confirmed by both parties, the deal moves to the status «Payment Pending». For further actions the Buyer needs to pay for the deal.",
                "HOWITWORK_STAGE_3_TITLE": "Status «Paid»",
                "HOWITWORK_STAGE_3_DESCRIPTION": "At this stage, the Seller must hand over the goods or transfer the service in the agreed manner. After the goods have been handed over, the goods must be inspected for conformity by the buyer.",
                "HOWITWORK_STAGE_4_TITLE": "Status «Arbitrage»",
                "HOWITWORK_STAGE_4_DESCRIPTION": `If the parties have not reached an agreement (the product does not meet the stated characteristics), they can resort to the help of experienced arbitration consultants ${appDomain.toLowerCase()}, who will study the essence of the dispute and offer the parties possible compromise solutions.`,
                "HOWITWORK_STAGE_5_TITLE": "Status «Completed deal»",
                "HOWITWORK_STAGE_5_DESCRIPTION": "At this stage, the Seller must hand over the goods or transfer the service in the agreed manner. After the goods have been handed over, the goods must be inspected for conformity by the buyer.",
                "HOWITWORK_WARNING_TITLE": "Deal protection period",
                "HOWITWORK_WARNING_DESCRIPTION": "ATTENTION: The deal protection period applies only to the purchase and sale of tangible goods. It is 21 days from the date of shipment of goods, unless a different period is specified in the terms of the transaction. If during the transaction protection period the Buyer has not performed the actions «Accept the deal» or «Open a arbitrage», the transaction is considered completed and the money is transferred to the Seller.",
                "ADDBALANCE_TITLE": "Deposit Monero (XMR) to your account",
                "ADDBALANCE_DESCRIPTION_1": "Account replenishment is done in MANUAL mode. To top up your account - you need to do the following actions described below, and then contact the support chat:",
                "ADDBALANCE_DESCRIPTION_2": "IMPORTANT: the rate on the application will be fixed for 1 hour! If no confirmation from Monero network is received during this time, then the rate will be fixed at the moment of receiving the 1st confirmation, the exchange will be executed after receiving funds on our Monero wallet.",
                "ADDBALANCE_STEP_1": "Step 1. Contact the support chat, specify your bank and the amount of replenishment. The operator will select an exchanger for you.",
                "ADDBALANCE_STEP_2": "Step 2. Create an application on the exchanger's service. Monero (XMR) wallet details of the service:",
                "ADDBALANCE_STEP_3": "Step 3. Follow the instructions of the exchanger. Fill in the fields in the application form, enter the appropriate data in the required fields.",
                "ADDBALANCE_STEP_4": "Step 4: Provide a screenshot of the processed request to the support chat.",
                "ADDBALANCE_WARNING_TITLE": "WARNING!",
                "ADDBALANCE_WARNING_DESCRIPTION": `Payment of the application on the exchanger is made in MANUAL mode. You need to pay it in the BANK APPLICATION by transfer using the details provided by the exchanger, after the transfer don't forget to click the "I paid" button on the exchanger.`,
                "BACK": "Back",
                "BLOCKEDBALANCE_TITLE": "Your balance has been blocked",
                "BLOCKEDBALANCE_DESCRIPTION": "You are not authorized to view this page until it is unlocked.",
                "BLOCKED_TITLE": "Your account has been blocked",
                "BLOCKED_DESCRIPTION": "Your account has been blocked by the administrator due to violation of site rules.",
                "CREATE_TITLE": "Name of deal",
                "CREATE_TITLE_PLACEHOLDER": "Name . . .",
                "CREATE_ROLE": "Your role",
                "CREATE_ROLE_1": "Сustomer",
                "CREATE_ROLE_2": "Сontractor",
                "CREATE_LOGIN_PLACEHOLDER": "Login . . .",
                "CREATE_DESCRIPTION": "Deal description",
                "CREATE_DESCRIPTION_PLACEHOLDER": "Description . . .",
                "CREATE_SUM": "Deal amount",
                "CREATE_SUM_PLACEHOLDER": "For example 2000",
                "CREATE_DURATION": "Deal period (in days)",
                "CREATE_DURATION_PLACEHOLDER": "For example 15",
                "CREATE_BUTTON": "Create a deal",
                "CREATE_CALCULATOR_TITLE": "Calculation of deal amount including commission",
                "CREATE_CALCULATOR_1": "Deal amount",
                "CREATE_CALCULATOR_2": "Service fee",
                "CREATE_CALCULATOR_3": "Total deal amount",
                "ERROR_LOGIN_NOT_FOUND": "User with this login was not found",
                "ERROR_REQUIRED_FIELD": "Field is required",
                "ERROR_INCORRECT_NUMBER": "Enter a valid number",
                "ERROR_MIN_AMOUNT": "Minimum deal amount: ",
                "ERROR_MIN_DURATION": "Minimum deal period: ",
                "ERROR_NOT_ENOUGH_BALANCE": "Not enough balance",
                "ERROR_BLOCKEDBALANCE": "Your balance has been blocked",
                "ERROR_LOW_AMOUNT": "The amount for withdrawal is too small",
                "ERROR_INCORRECT_EMAIL": "Invalid email",
                "ERROR_SHORT_PASSWORD": "Password is too short",
                "ERROR_PASSWORD_REPEAT": "The passwords don't match",
                "ERROR_LOGIN": "Incorrect login or password",
                "CHAT_TITLE": "Deal chat",
                "CHAT_PLACEHOLDER": "Type a message . . .",
                "LAST_ONLINE_CONTACT": "The contact ",
                "LAST_ONLINE_WAS": "was online ",
                "LAST_ONLINE_AGO": " ago",
                "LAST_ONLINE_ONLINE": "online",
                "STATUS": "Status",
                "DEAL_STATUS": "Deal status",
                "DEAL_BUTTON_GO": "Go to page",
                "DEAL_BUTTON_CONFIRM": "Confirm participation",
                "DEAL_BUTTON_PAY": "Pay",
                "DEAL_BUTTON_ARBITRAGE": "Create arbitrage",
                "DEAL_BUTTON_UNARBITRAGE": "Cancel arbitrage",
                "DEAL_BUTTON_CLOSE": "Confirm",
                "BALANCE_BUTTON": "Top up balance",
                "BALANCE_DESCRIPTION_1": `${appDomain.toLowerCase()} is not a bank, payment system or other financial organization and does not maintain users accounts.<br>Cabinet ${appDomain.toLowerCase()} provides only the convenience of settlements between clients.`,
                "BALANCE_DESCRIPTION_2": "The transfer is carried out within 24h.",
                "BALANCE_WARNING_DESCRIPTION": "at this time, transfers and withdrawals for your account have been suspended.",
                "BALANCE_WARNING_LINK": "Read a description of the reason",
                "BALANCE_HISTORY_TITLE": "History",
                "BALANCE_HISTORY_TAB_1": "Deposit history",
                "BALANCE_HISTORY_TAB_2": "Withdrawals",
                "BALANCE_HISTORY_TAB_3": "Balance transfer",
                "OUTPUT_STATUS_1": "Processing",
                "OUTPUT_STATUS_2": "Canceled",
                "OUTPUT_STATUS_3": "Сompleted",
                "DEPOSIT_DATE": "Date",
                "DEPOSIT_TYPE": "Bank",
                "DEPOSIT_NUMBER_1": "Number",
                "DEPOSIT_NUMBER_2": "Details",
                "DEPOSIT_AMOUNT": "Amount",
                "DEPOSIT_STATUS": "Status",
                "OUTPUT_BUTTON": "Request a withdrawal",
                "OUTPUT_NUMBER_PLACEHOLDER": "Account number",
                "OUTPUT_AMOUNT_PLACEHOLDER": "Amount",
                "TRANSACTION_SUCCESS": "Transfer has been sent",
                "TRANSACTION_LOGIN": "User login",
                "TRANSACTION_LOGIN_PLACEHOLDER": "Login",
                "TRANSACTION_AMOUNT": "Amount",
                "TRANSACTION_AMOUNT_PLACEHOLDER": "For example: 450",
                "TRANSACTION_BUTTON": "Send",
                "NOTFOUND_TITLE": "Page not found",
                "NOTFOUND_DESCRIPTION": "The requested page was not found, make sure the link in the address bar is correct.",
                "NOTFOUND_BUTTON": "Home",
                "NOTIFICATIONS_TITLE": `Notifications from ${appDomain}`,
                "NOTIFICATIONS_VOID": "You haven't had any notifications from us yet...",
                "DEALS_TITLE": "My deals",
                "DEALS_VOID": "It's empty so far because you haven't had a single deal yet",
                "SETTINGS_TITLE": "Account settings",
                "SETTINGS_LOGIN": "Login",
                "SETTINGS_CURRENCY": "Currency",
                "SETTINGS_EMAIL_PLACEHOLDER": "New email",
                "SETTINGS_PASSWORD": "Passowrd",
                "SETTINGS_PASSWORD_PLACEHOLDER": "New password",
                "SETTINGS_BUTTON": "Save changes",
                "SETTINGS_SUCCESS": "Сhanges have been saved",
                "REGISTER_TITLE": "Registration",
                "REGISTER_LOGIN_PLACEHOLDER": "Login",
                "REGISTER_PASSWORD_PLACEHOLDER": "Password",
                "REGISTER_PASSWORD_REPEAT_PLACEHOLDER": "Repeat password",
                "REGISTER_BUTTON": "Sign up",
                "REGISTER_PRIVACY_1": "I accept the terms of the",
                "REGISTER_PRIVACY_2": "user agreement",
                "REGISTER_HAS_ACCOUNT": "Already have an account?",
                "LOGIN_NOT_HAS_ACCOUNT": "You don't have an account?",
                "REVIEWS_TITLE": "Reviews",
                "REVIEWS_DESCRIPTION": "We've collected honest feedback from our users and here's what they think of us",
                "REVIEWS_CARD_1": "A Danish company running a testimonial website, founded in 2007, which features numerous positive reviews of our performance.",
                "REVIEWS_CARD_2": "The most useful review forum where millions of people exchange useful experiences, share photos and videos, rate and rank the best.",
                "REVIEWS_CARD_3": "Electronic settlement system founded in 1998, where we are certified, which guarantees you a transaction with a specific person.",
                "REVIEWS_CARD_BUTTON": "Read more",
                "REVIEWS_PROMO": "Quality and safety guarantee are our top priorities to create favorable cooperation. It is important for us to maintain our image in order to continue our stable work. The interests of our customers are always a priority for us, thanks to which we show a high quality guarantee.",
                "REVIEWS_REVIEW_NAME": "Alina, designer",
                "REVIEWS_REVIEW_TEXT": "We offer a reliable and secure Guarantor service for online deals. Our team of specialists will protect your interests and help you avoid financial risks in online transactions.",
                "REVIEWS_REVIEW_DEAL_BUTTON": "Create a deal",
                "REVIEWS_REVIEW_AUTH_BUTTON": "Register now!",
                "REVIEWS_WHYME_TITLE": "Why us?",
                "REVIEWS_WHYME_1_TITLE": "Reliability",
                "REVIEWS_WHYME_1_DESCRIPTION": "Webmoney personal certificate was issued in 2009.",
                "REVIEWS_WHYME_2_TITLE": "Safety",
                "REVIEWS_WHYME_2_DESCRIPTION": "Financial transactions are processed through a state bank account.",
                "REVIEWS_WHYME_3_TITLE": "Comfort",
                "REVIEWS_WHYME_3_DESCRIPTION": "Protecting sales transactions 24/7 without going to a lawyer or bank.",
                "REVIEWS_WHYME_4_TITLE": "Automatization",
                "REVIEWS_WHYME_4_DESCRIPTION": "Protecting sales transactions 24/7 without going to a lawyer or bank.",
                "REVIEWS_END_TEXT": "Excellent service, I recommend to everyone, we earned more than 1000$ just through this site exchange.",
                "SERTIFICATES_DESCRIPTION": `Use our ${appName} service without taking the risk of independently checking the person for honesty. A scammer will never, under any conditions, agree to conduct a safe deal.`,
                "SERTIFICATES_1": "SSL protocol provides secure data exchange between users of the service.",
                "SERTIFICATES_2": "An Extended Validation Certificate (EV Certificate) proves that a site is not one of the fraudulent or fake sites. The certificate holder undergoes full authentication according to the highest standards of the security industry. The certificate is verified and issued by a specialized certification center (GeoTrust).",
                "SERTIFICATES_3": "Bank card transactions and electronic payments are made through MandarinPay and Robokassa partners with valid PCI DSS version 3.2 security certificates",
                "SERTIFICATES_WARNING": `Remember! Never check a person for honesty yourself, as the risk of losing your goods is very, very high! Use the guarantee service ${appName}. And remember, a scammer will never, under any conditions will not agree to a safe deal, on the guarantor.`,
                "FORWHOM_DESCRIPTION": `${appName} - service for accepting payments and protecting online deals.`,
                "FORWHOM_CARD_1": "Sale of services by 100% prepayment",
                "FORWHOM_CARD_2": "Guarantee of payment upon fulfillment of obligations",
                "FORWHOM_CARD_3": "Fraud protection",
                "FORWHOM_STAGES_TITLE": "Stages of the deal",
                "FORWHOM_STAGES_DESCRIPTION": "All protected deals have certain statuses depending on the stage. The statuses are managed by users or the service, according to the set conditions.",
                "FORWHOM_STAGES_1": "Benefits of the service",
                "FORWHOM_STAGES_1_1": "Deal management",
                "FORWHOM_STAGES_1_2": "Profitable and reliable",
                "FORWHOM_STAGES_1_3": "Professional arbitrage team",
                "FORWHOM_STAGES_1_4": "Transparent conditions, automatic fee calculation",
                "FORWHOM_STAGES_2": `Who can use ${appName}`,
                "FORWHOM_STAGES_2_1": `Virtual Services - ${appName} will protect your interests when selling services, when you cannot confirm the quality of the performer in advance and are not willing to risk giving away services without prepayment.`,
                "FORWHOM_STAGES_2_2": `When selling an expensive service, ${appName} is necessary because prepayment and product authentication, inherent conditions that allow for a risk-free transaction for both parties.`,
                "FORWHOM_STAGES_2_3": `${appName} will allow customers to securely prepay online, and you get the assurance that the customer will pay after you fulfill all obligations.`,
                "FORWHOM_STAGES_2_4": `Providing services, including remotely - ${appName} simplifies remote work, as it protects the parties from the risk of non-performance of obligations: The client gets a guarantee that the work will be done, the contractor gets payment for services rendered. ${appName} will ensure the fulfillment of the terms of the deal.`,
                "FORWHOM_STAGES_3": "Forewarned is forearmed",
                "FORWHOM_STAGES_3_1": "Recently, a very clever method of fraud has been gaining momentum. The fraudster-customer is ready to pay you the full price of the service. You give him a wallet for payment, but instead of payment you receive an invoice, on which you have to pay quite a considerable commission. This is fraud! No payment system requires you to pay a commission to the person accepting the funds. If you confirm the payment, you will simply transfer the money to the false customer, who will simply disappear from the network after receiving it.",
                "FORWHOM_STAGES_3_2": "Carding - if you are asked for your card CVV code and its expiration date - after receiving these data, the fraudster can withdraw all the money from the card. They may provide a fake screenshot of the payment page.",
                "FORWHOM_STAGES_3_3": "If a person from a one-day page writes to you, it is most likely a scammer, but in any case a scammer will NEVER agree to a guarantor.",
                "FORWHOM_STAGES_3_4": "Be careful, do not fall for the tricks of scammers",
                "FORWHOM_STAGES_4": "Withdrawal from the site",
                "FORWHOM_STAGES_4_1": "Withdrawal from the site is made within 24 hours, with 3% commission when transferring to Bank card. Transfer to Qiwi Wallet, Yandex Money, Webmoney, PayPal payment systems is made with a 1.5% fee",
                "FORWHOM_WARNING": `In case of fulfillment of obligations to the Customer the funds will be transferred to your account, in case of improper fulfillment of the terms of the transaction - returned to the Customer. Remember! Never check a person for honesty yourself, as the risk of losing your goods is very, very high! Use the guarantee service ${appName}. Remember, a fraudster will never, under any conditions will not agree to a safe transaction, on the guarantee service.`,
                "RULES_TITLE": "User Agreement",
                "FROZEN": "frozen",
                "FROZEN_TOOLTIP": "Frozen balance will be available after the deal is completed",
                "RULES_TEXT": `
                1. Terms and Definitions<br>
                The following terms and definitions are used in this User Agreement:<br>
                1.1 The Site is a set of software and hardware combined within the domain name space
                ${appDomain.toLowerCase()}, providing interaction between Users.<br>
                1.2. User - an individual or legal entity registered on the Site and using it on its own behalf.
                name.<br>
                1.3. Account (account) - a unique record of the User, which is created on the Site during registration,
                protected by a user password. <br>
                1.4. Buyer - a User who searches for Sellers within any resources as well as the Site, with the purpose of ordering a certain type of work.
                order a certain type of work.<br>
                1.5. Seller - the User, providing services of fulfillment of various kinds of works.
                1.6. Content - data, information, materials, documents, files, messages, etc. in electronic form,
                located on the Website. <br>
                2. Description of Services<br>
                The Exchange ${appDomain.toLowerCase()} offers Users access to a wide range of services, without interruptions, with the exception of
                time of necessary repair and maintenance works. All currently existing services, as well as any development of them and/or addition of new ones.
                and any development of them and/or the addition of new ones are subject to this Agreement. You understand and
                agree that all services are provided on an "as is" basis and that the Administration is not responsible for any delays, failures, and/or new additions.
                responsibility for any delays, failures, incorrect or untimely delivery, deletion or non-preservation of any user's personal information.
                any user's personal information.<br>
                3. Subject of the agreement<br>
                3.1 The User, who has passed registration, has the opportunity to use the Site and gets the right to the following types of
                services:<br>
                3.1.1. Carrying out transactions.<br>
                3.1.2. Searching for Sellers to perform various kinds of work.<br>
                4.Provision of services<br>
                4.1 The personnel of the Site has the right to the following actions:<br>
                4.1.1. To refuse to provide services to the User without specifying the reasons in the following cases:<br>
                4.1.1.1. Violation by the User of the terms and conditions of this Agreement or the current rules.<br>
                4.1.1.2. Non-fulfillment by the User of financial obligations to the Site and/or third parties.<br>
                4.1.1.3. The presence of claims to the User, supported by evidence within the Site.<br>
                4.1.1.4. Appearance at the Site personnel of doubts in the proper use of the Site by the User.
                4.1.1.5. Attempts on the part of the User to cause any kind of damage to the Site.<br>
                4.1.1.6. Refusal of the User from fulfillment of any requirements on the part of the Site personnel.<br>
                4.1.1.7. Showing disrespect on the part of the User in relation to the Site personnel and/or other Users of the Site. 4.1.1.7.
                Site.<br>
                4.1.2. To remove any information from the pages of the Site without specifying the reasons or to refuse its placement.
                4.1.3. Require from the User to present identity documents, as well as to provide additional data identifying the User, 4.1.3.
                additional data identifying the User in the following cases:<br>
                4.1.3.1. If there are grounds to assume that the User violates the current legislation, the terms and conditions of this Agreement, the rights and interests of the User's personnel.
                of this Agreement, the rights and interests of the Website personnel and/or third parties.<br>
                4.1.3.2. Other cases of necessity of identification of the User at the discretion of the Site personnel.
                4.1.4. Suspend the operation of the software and/or hardware of the Website for the purpose of updating, as well as for the purpose of preventive maintenance.
                preventive maintenance.<br>
                4.1.5. Not to service requests to the Site from anonymous proxy servers.
                5. Rights and Obligations of the Site User<br>
                5.1.The Site User ${appDomain.toLowerCase()} has the following rights and obligations:<br>
                5.1.1.1.Register only one Account.<br>
                5.1.2. Provide accurate personal data and keep it up to date in the future.<br>
                5.1.3 Follow the regulations and strictly comply with the applicable rules governing the use of the Website.
                Site.<br>
                5.1.4. Not to use the Site for illegal purposes.
                5.1.5. Not to commit illegal actions with the use of the Site. <br>
                5.1.6. Not to disclose confidential information used for access to the Account.
                5.2 Users of the Site ${appDomain.toLowerCase()} are prohibited:<br>
                5.2.1. Use in their messages obscene words, profanity, offensive to other Users.<br>
                5.2.2 Use other people's works that are not related to your personal portfolio<br>
                5.2.3. decrease/increase your rating by unauthorized methods (by means of reviews, recommendations, advertising messages in blogs, projects, personal blogs, projects, personal posts, etc.).
                messages in blogs, projects, personal messages, comments).<br>
                5.2.4. Violate the rights of minors and/or harm them in any form.<br>
                5.2.5. Upload, send, transmit or in any other way post content that you do not have the right to
                make available under the law of the Russian Federation or under any contractual relations.<br>
                5.2.6. Disrupt in any way the normal operation of the site ${appDomain.toLowerCase()};<br>
                5.2.7. Placing links to resources, the content of which contradicts the current legislation of Russia;<br>
                5.2.8. Mass publication of messages (comments, reviews, personal messages to users) of advertising (spam) and flooding.
                (spam) and flooding;<br>
                5.2.9 Posting and propaganda of pornography and child erotica, as well as advertising of intimate services<br>
                5.2.10. Transfers are available to the user after he/she replenishes his/her account on the site, the amount of replenishment is set for each user individually.
                is set for each individual, depending on the transaction(s) he/she has made. Funds received by other
                by other means (transfer from other users, transactions), will not be taken into account.<br>
                5.2.11 The administration of the service undertakes to set the amount of the minimum replenishment based on the transactions on the site at the moment of transfer, for the user to access the site.
                at the time of transfer, for the user's access to transfers of the site currency to other users and additional functions of the service.
                Also the minimum amount of replenishment is necessary for verification of identity.<br>
                5.2.12 In case of violation of these conditions and other obligations under this Agreement the Administration of the resource
                ${appDomain.toLowerCase()} (or representatives of administration represented by moderators) have the right:<br>
                warn you in writing;<br>
                block your account;<br>
                reset your rating.<br>
                5.2.13 All real payments that come to the balance of the service are a donation for the development of the project,
                in return for this, the Administration of the service replenishes the account of the user who donated the n-th amount, the amount equal to the
                user's donation.<br>
                6. Responsibility of the parties<br>
                6.1 The personnel of the Site is not responsible:<br>
                6.1.1. For actions of the Users, violating the current legislation.<br>
                6.1.2. For the information and materials placed by the Users within the framework of the Site.<br>
                6.1.3. For the quality of goods and services provided by Users and the service ${appDomain.toLowerCase()}<br>
                6.1.4. For any damage and/or lost profits of Users and/or third parties as a result of the use or
                inability to use the Site.<br>
                6.1.5 For ignorance of the rules of the service, as well as for ignorance of those or other points that relate to the user.
                user. PS: Ignorance of the law does not release from responsibility. <br>
                6.2. The user bears
                responsibility:<br>
                6.2.1. For all actions performed on behalf of the User within the framework of the Site.<br>
                6.2.2. For the quality and timeliness of fulfillment of the obligations assumed within the framework of the Site.<br>
                6.2.3. For security of confidential information used for access to the Account.<br>
                6.2.4. In case of blocking/suspicion of the user(s) in Frod payment, in order to unblock the Account, the following is carried out
                repeated transaction for the same amount (last amount) that was made before the blocking from another Bank
                card. If the user does not have such an opportunity, the Administration is obliged to delete the payment data
                user, at the request of the user, to carry out a transaction with the same card that was made before the
                blocking.<br>
                6.3 In accordance with the rules of payments on the service, in order to avoid laundering/fraud through the service,
                the user is obliged to carry out all transactions from the details that were initially entered.<br>
                7. Termination of Agreement<br>
                You agree that the Site ${appDomain.toLowerCase()} reserves the right to terminate your Account and remove
                any content for any reason, including failure to use access or violation of the Agreement.<br>
                7.1 The Website Staff shall have the right to unilaterally amend this Agreement, as well as the
                current rules.<br>
                7.2 The use of the Site after making changes means unconditional acceptance (acceptance) by the User of the conditions of the updated edition of the Agreement.
                of the updated edition of the Agreement.<br>
                7.3 The Site personnel has the right to unilaterally cancel the Agreement, if the User's Account
                User's Account has not been used for more than 12 consecutive calendar months. The use of the Account
                means authorization of the User on the Site. 7.4 ${appDomain.toLowerCase()} may close any of its
                services, with or without prior notice. ${appDomain.toLowerCase()} also assumes no liability for any termination of
                access to its services.<br>
                8. Acceptance of the terms of the Agreement<br>
                8.1 Unconditional acceptance (acceptance) of the terms and conditions of this Agreement is registration on the Site.
                8.2 Acceptance of the terms of this Agreement means unconditional acceptance by the User of the terms of the Privacy Policy, as well as other applicable rules
                Privacy Policy, as well as other applicable rules.<br>
                9. Release of Warranties<br>
                9.1. You understand and agree that:<br>
                9.1.1 You use the ${appDomain.toLowerCase()} services at your own risk. The Services are provided "as is". ${appDomain.toLowerCase()} does not
                assumes any responsibility, including that the services are fit for the user's purpose;<br>
                9.1.2. ${appDomain.toLowerCase()} does not warrant that: the services will meet your requirements; the services will be
                provided continuously, quickly, reliably, and error-free; the results that can be obtained using the services will be accurate and error-free; the results that can be obtained using the
                using the services will be accurate and reliable; the quality of any product, service, information, etc.,
                obtained using the service will meet your expectations; all errors in the programs will be
                corrected;<br>
                9.1.3 Any materials obtained by you using the ${appDomain.toLowerCase()} services, you may use at your own
                at your own risk, and you are responsible for any damage that may be caused to your
                computer and your data as a result of downloading these materials; The currency on the site is cryptocurrency and has no
                has no real value. Currency can be acquired by any user, either through transactions or by buying it with real money.
                real money.<br>
                9.1.4.${appDomain.toLowerCase()} shall not be liable for any direct or indirect damages resulting from: the use of
                or inability to use the services; unauthorized access to your communications; statements or
                conduct of any third party on the services.<br>
                10. Third Party Services, Sites and Content<br>
                10.1 The Site Services may contain links to other services and sites on the Internet (third party services and sites), including online services, sites and content.
                third party services and sites), including online communication services (e-mail, Skype, ICQ), contract builder, etc. These
                third parties and their content are not checked for compliance with any requirements (reliability, completeness,
                legality, etc.). The service is in beta test, withdrawals to real e-wallets, bank cards, etc. are not possible.
                etc. are not possible. Exchange ${appDomain.toLowerCase()} is not responsible for any information, materials posted on the services and websites of third parties, to which ${appDomain.toLowerCase()} has access.
                and websites of third parties to which the User gains access using the Site services, including for
                any opinions or statements expressed on third party services and sites, advertising, etc., as well as for the
                availability of such services and sites or content and the consequences of their use by the User.<br>
                10.2 A link (in any form) to any site, product, service, any information of commercial or non-commercial nature, placed on the Site, as well as any other information of a commercial or non-commercial nature.
                posted on the Site does not constitute an endorsement or recommendation of these products (services,
                activities), except when it is expressly indicated by the Site.<br>
                11. Dispute Resolution<br>
                11.1 The Agreement is a legally binding contract between you and ${appDomain.toLowerCase()} and governs your use of the ${appDomain.toLowerCase()} Services.
                ${appDomain.toLowerCase()} services by You. Additional obligations may also be imposed on you by the respective contracts relating to your use of other services.
                obligations related to your use of other services and content or software,
                owned by a third party.<br>
                11.2. You and ${appDomain.toLowerCase()} agree that all possible disputes regarding the Agreement will be resolved according to the norms of Russian law.
                Russian law.<br>
                11.3 Nothing in the Agreement may be understood as establishing an agency relationship between you and ${appDomain.toLowerCase()},
                partnership relationship, joint venture relationship, personal employment relationship, or any other relationship not expressly provided for in the Agreement.
                relationship not expressly provided for in the Agreement.<br>
                11.4 A court declaring any provision of the Agreement to be invalid or unenforceable shall not invalidate or unenforceable.
                shall not entail invalidity or unenforceability of other provisions of the Agreement.<br>
                11.5 Failure of ${appDomain.toLowerCase()} to act in the event of a breach of the Agreement by you or other users does not deprive ${appDomain.toLowerCase()} of any of the provisions of the Agreement.
                deprive ${appDomain.toLowerCase()} of the right to take appropriate action to protect its interests at a later date, nor does it constitute a waiver of ${appDomain.toLowerCase()}'s right to take action to protect its interests.
                waiver of ${appDomain.toLowerCase()} of its rights in the event of subsequent similar or similar violations.<br>
                12. Entry into force and effect<br>
                12.1 The Agreement shall become effective upon acceptance of this offer by the Buyer. Unconditional acceptance
                (acceptance) of the terms and conditions of this offer of the Agreement shall be deemed to be the Buyer's registration on the Site by filling out the registration form and agreeing to the terms and conditions of this offer.
                filling in the registration form and expressing consent to the terms of the Agreement by clicking the button
                "Register" placed on the page of the Site with the registration form. Unconditional acceptance (acceptance) of
                of all terms and conditions of provision of Paid services is also considered to be the Buyer's payment for the Paid service.
                Paid service.<br>
                12.2 The Agreement is concluded for an indefinite term and may be terminated at the initiative of any of the Parties at any time.
                at any time. For this purpose, the Seller shall post a notice of termination of the Agreement on the Website and/or send a notice of termination to the Buyer on the Buyer's Profile (or to the Buyer's profile).
                the Buyer to its Profile (or registration data), from the moment of such posting/sending of such notice, the Agreement shall be terminated at any time.
                posting/sending such notice, the Agreement shall be deemed terminated. The Buyer may terminate
                Agreement by requesting the deletion of his/her Profile from the Site, in which case further processing of personal data shall be carried out in accordance with clauses 4.8.
                shall be processed in accordance with clauses 4.8. - 4.11. of this Agreement.<br>
                12.3 This Agreement shall apply to the Buyers who have registered before the date of publication of this Agreement on the Site.
                the date of publication of this Agreement on the Site.<br>
                12.4. The User undertakes to familiarize himself with the terms and conditions of the Agreement. If the User does not agree with
                terms of this Agreement, he/she must immediately delete his/her Profile from the Site, otherwise
                Continued use of the Site by the User means that the User agrees with the terms of the Agreement. <br>
                12.5 When determining the terms, the time when the Party or third parties perform an action (inaction)
                is calculated according to the Moscow time.<br>
                13. Other Terms<br>
                13.1. The Parties have agreed that when executing (amending, supplementing, terminating) the Agreement it is allowed to
                use of signatures of the Parties' representatives, as well as their seals, by means of facsimile communication,
                mechanical or other copying, electronic digital signature or any other analog of handwritten signature
                heads and seals of the organizations. The Parties confirm that the documents to the Agreement, signed and executed in the manner specified in this clause, have the following characteristics
                in the manner specified in this clause shall be legally valid and binding on the Parties.<br>
                13.2 The Parties recognize any information related to the conclusion of the Agreement, including any annexes and supplements thereto, as a commercial secret and shall be bound by the Parties.
                to it, as a commercial secret and undertake to keep such information strictly confidential and not to disclose it to any third parties without
                disclose it to third parties without prior written consent of the other Party, except for the cases when it is necessary for the purposes of the Agreement.
                cases when it is necessary for the purposes of the Agreement or for disclosure to the relevant state authorities in cases defined by law.
                in cases defined by law. The said provision shall not apply to publicly known or publicly available information.
                The Parties consider confidential all business information transmitted to each other, such as: information about their
                customers, partners, business plans, prices, financial status, etc. The parties shall not disclose such
                information to anyone, except when it is necessary for the proper fulfillment of their obligations under the Agreement, or if such information is necessary for the proper fulfillment of their obligations under the Agreement.
                obligations under the Agreement, or if such information is publicly available, or as mutually agreed by the Parties.
                Parties.<br>
                14. Termination of User's account<br>
                14.1 User agrees that the Company reserves the right to terminate User's account with ${appDomain.toLowerCase()} at any time without prior notice to User.
                User's ${appDomain.toLowerCase()} account at any time without prior notice to User.<br>
                14.2 Account termination may be effected for the following reasons: a) breach of the
                the provisions of this Agreement, as well as the additions to it, which are an integral part thereof; b) upon the
                b) at the appropriate request of the authorities, according to the legislation of the Russian Federation; c) in the case of
                c) in case of dissemination by the User of unconfirmed negative information about the Company; d) due to unforeseen technical problems or circumstances related to the Company.
                d) due to unforeseen technical problems or circumstances related to security, etc.<br>
                14.3 The Company may delete the User's account on ${appDomain.toLowerCase()} and/or suspend, restrict or
                terminate access to any of the Services if the Company detects signs of violation of the terms and conditions of this Agreement in the User's actions, without explanation.
                terms of this Agreement, without explaining the reasons for such actions.<br>
                15. Exclusive rights to the content of the Services and content<br>
                15.1 The User undertakes not to reproduce, copy, duplicate, sell or resell or
                not to use for any commercial purposes and not to make available to the public, not to redistribute
                the ${appDomain.toLowerCase()} programs, the whole or any part of the ${appDomain.toLowerCase()} Services (including the content available to User
                through the Services), or access to them, unless the User has previously obtained such authorization from the Company.
                authorization from the Company.<br>
                15.2 All objects accessible through the Services and ${appDomain.toLowerCase()}, including design elements, text, graphic
                images, illustrations, videos, computer programs, databases, music, sounds and other objects (hereinafter - the
                content of the Services), as well as any content posted on the Services and ${appDomain.toLowerCase()}, are the objects of the exclusive rights of the Rightholder.
                exclusive rights of the Licensor and other right holders.<br>
                15.3 The use of the content, as well as any other elements of the Services and ${appDomain.toLowerCase()} is possible only within the framework of the functionality offered by one or another of them.
                functionality offered by a particular Service. No content elements of the Services and ${appDomain.toLowerCase()}, as well as any
                content posted on the Services and ${appDomain.toLowerCase()} may not be used in any other way without the prior
                permission of the copyright holder. Use means, including but not limited to: reproduction, copying,
                recycling, distribution on any basis, framing, etc. Exceptions are cases directly
                provided by the legislation of the Russian Federation.<br>
                15.4 The use by the User of the elements of the content of the Services, as well as any content for personal, non-commercial use, is allowed provided that the User does not use it for any other purpose.
                non-commercial use is allowed provided that all copyright, related rights, trademarks and other signs of protection of copyright, related
                rights, trademarks, other notices of authorship, keeping the name (or pseudonym) of the author/name of the right holder unchanged.
                the name (or pseudonym) of the author/name of the right holder in the unchanged form, preservation of the corresponding object in the unchanged form. Exceptions are
                cases directly stipulated by the legislation of the Russian Federation.<br>
                16. Other provisions<br>
                16.1 This Agreement is a contract between the User and the Company regarding the procedure of
                use of the Services ${appDomain.toLowerCase()} and supersedes all previous agreements between the User and the Company on the above issues.
                above mentioned issues.<br>
                16.2 This Agreement shall be governed by and construed in accordance with the laws of the Russian Federation.
                Issues not regulated by this Agreement shall be resolved in accordance with the legislation of the Russian Federation.
                of the Russian Federation. All possible disputes arising out of the relations governed by this Agreement,
                shall be resolved in accordance with the procedure established by the current legislation of the Russian Federation, in accordance with the norms of Russian law.
                law. Throughout the text of this Agreement, unless expressly stated otherwise, the term "legislation" shall be understood as the legislation of the Russian Federation.
                shall be understood as the legislation of the Russian Federation.<br>
                16.3 Due to the gratuitous nature of the services rendered under this Agreement, the rules on consumer protection,
                provided by the legislation of the Russian Federation, cannot be applicable to the relations between the
                User (Seller and/or Buyer) and the Company.<br>
                16.4 Nothing in the Agreement may be understood as establishment of relations between the User and the Company.
                partnership relations, relations of joint activity, relations of personal employment, or any other relations not expressly provided for by the Agreement.
                not stipulated by the Agreement.<br>
                16.5 If for one or several reasons one or several provisions of this Agreement are recognized as invalid or void.
                invalid or unenforceable, the validity or enforceability of the remaining provisions of the Agreement shall not be affected.
                applicability of the remaining provisions of the Agreement.<br>
                16.6 Inaction on the part of the Company in case of violation by the User or other users of the provisions of the Agreements does not deprive the Company of the right to take appropriate actions to protect its interests later.
                Agreements does not deprive the Company of the right to take appropriate actions in defense of its interests later, nor does it mean the Company's waiver of its rights in case of subsequent similar or similar violations of the Agreements.
                also does not mean the Company's waiver of its rights in case of subsequent similar or similar violations.
                violations.<br>
                16.7 Any real payment, transfer of real funds to the site is voluntary, the administration can only
                inform about the minimum amount of replenishment or give advice on how to do it. No one in any way
                compel or force anyone to transfer funds to the site, it is purely voluntary and informed decision
                user.
                `
            },
            "UA": {
                "HEADER_REVIEWS": "Відкликання",
                "HEADER_FORWHOM": "Для кого",
                "HEADER_SERTIFICATES": "Сертифікат",
                "HEADER_HOWITWORK": "Як це працює",
                "HEADER_NOTIFICATIONS": "Повідомлення",
                "HEADER_DEALS": "Мої угоди",
                "HEADER_BALANCE": "Мій рахунок",
                "HEADER_ADMIN": "Адмін панель",
                "HEADER_SETTINGS": "Настройка",
                "HEADER_LOGOUT": "Вийти",
                "HEADER_LOGIN": "Увійти",
                "HEADER_SIGNUP": "Створити аккаунт",
                "HEADER_CURRENT_LANGUAGE": "Мова",
                "FOOTER_COPY": "Гарант сервіс",
                "PROMO_TITLE": "Безпека ваших угод-наш пріоритет",
                "PROMO_DESCRIPTION": "Ми пропонуємо надійний і безпечний Гарант-сервіс для онлайн-угод. Наші фахівці забезпечують захист ваших інтересів і допомагають уникнути фінансових ризиків при веденні угод в Інтернеті.",
                "PROMO_BUTTON": "Створити угоду",
                "PROMO_BUTTON_AUTH": "Зареєструватися зараз!",
                "SAFE_TITLE": "Що таке <span>безпечна</span> угода?",
                "SAFE_DESCRIPTION_1": "Це сервіс, який забезпечує захист як покупця, так і продавця при здійсненні онлайн-угод",
                "SAFE_DESCRIPTION_2": `При використанні цього сервісу, Покупець здійснює 100% передоплату, яка зберігається на рахунку гаранта ${appDomain.toLowerCase()} до тих пір, поки продавець не виконає всі свої зобов'язання і покупець не отримає свій товар або послугу. Крім того, сервіс гарантує збереження коштів покупця: якщо продавець порушує умови угоди, гарант-сервіс ${appDomain.toLowerCase()} повертає кошти або пропонує покупцеві знижку.`,
                "SAFE_BUTTON": "Здійснити безпечну угоду",
                "STEPS_TITLE": "Що можна зробити через наш сервіс?",
                "STEPS_DESCRIPTION": "Ми надаємо безпечну угоду і гарантуємо збереження ваших коштів до повного виконання зобов'язань продавця і отримання товару або послуги покупцем.",
                "STEPS_1_TITLE": "Створіть угоду",
                "STEPS_1_DESCRIPTION": "Заповніть всі поля, Логін продавця, умови угоди, ціну, терміни.",
                "STEPS_2_TITLE": "Дочекайтеся продавця",
                "STEPS_2_DESCRIPTION": "Продавець повинен перейти в пункт Мої угоди, після чого перейти на сторінку самої угоди і прийняти.",
                "STEPS_3_TITLE": "Проведіть угоду",
                "STEPS_3_DESCRIPTION": "Детальний процес проведення угоди описаний нижче.",
                "STEPS_4_TITLE": "Підтвердьте оплату",
                "STEPS_4_DESCRIPTION": "Якщо товар відповідає заявленим характеристикам, після отримання підтвердіть платіж.",
                "SCHEME_TITLE": "Схема роботи гарант сервісу",
                "SCHEME_1_TITLE": "Реєстрація",
                "SCHEME_1_DESCRIPTION": "Послуги нашого сервісу безпечних угод надаються тільки після того, як обидва учасники реєструються на сайті.",
                "SCHEME_2_TITLE": "Обговорення умов Угоди",
                "SCHEME_2_DESCRIPTION": "Учасники обговорюють характеристики послуг і суму оплати, вибирають платіжну систему. Також обговорюють подальші дії в разі претензії по послузі.",
                "SCHEME_3_TITLE": "Відкриття угоди",
                "SCHEME_3_DESCRIPTION": "Замовник відкриває угоду, Виконавець її приймає. Якщо на даному етапі щось йде не так (Виконавець пропав), то угода скасовується, оплата повертається замовнику. Для спілкування і передачі інформації використовується внутрішній чат.",
                "SCHEME_4_TITLE": "Виконання зобов'язань",
                "SCHEME_4_DESCRIPTION": "Замовник вносить гроші на баланс угоди, кошти відразу ж блокуються. Виконавець бачить це і передає послугу, після чого замовник переводить гроші з балансу на баланс виконавця. Виконавець може відмовитися від угоди і повернути гроші замовнику. Суть в тому, що учасники можуть переводити гроші з балансу тільки на баланс один одного, але не на свої. У разі спору учасники можуть звернутися до гаранта угод (арбітраж).",
                "SCHEME_5_TITLE": "Закриття угоди",
                "SCHEME_5_DESCRIPTION": "Якщо на балансі угоди немає грошей, то замовник її закриває. Угода через гаранта завершена.",
                "ADVENTAGES_TITLE": `Чому ${appDomain.toLowerCase()} можна довіряти?`,
                "ADVENTAGES_1": "Надійність",
                "ADVENTAGES_1_1": "Персональний атестат Webmoney виданий в 2009 році.",
                "ADVENTAGES_1_2": "Більше двох років на ринку цифрових і матеріальних товарів у сфері безпечної угоди.",
                "ADVENTAGES_1_3": "Система обчислення ймовірності того, що партнер по угоді - шахрай.",
                "ADVENTAGES_1_4": "Актуальний чорний список c автоматичною перевіркою всіх користувачів.",
                "ADVENTAGES_1_5": "Фінансові операції проходять через рахунок у Державному банку.",
                "ADVENTAGES_2": "Конфіденційність",
                "ADVENTAGES_2_1": "Угода проходить на закритій сторінці, доступ до якої є тільки в учасників угоди і модератора сервісу.",
                "ADVENTAGES_2_2": "Заборона публікації даних для ідентифікації товару/послуги, що продається.",
                "ADVENTAGES_2_3": "Просте врегулювання спірних ситуацій між продавцем і покупцем.",
                "ADVENTAGES_2_4": "Можливість проводити захищені платежі між фізичними особами.",
                "ADVENTAGES_3": "Зручність",
                "ADVENTAGES_3_1": "Автоматизоване проведення угоди без участі реального поссредника.",
                "ADVENTAGES_3_2": "Цілодобова робота сервісу-сервіс доступний до використання в будь-який час доби.",
                "ADVENTAGES_3_3": "Захист угод купівлі-продажу 24/7 без походу до юриста і в банк.",
                "HEAD_TITLE": `${appDomain} у цифрах`,
                "HEAD_1": "Комісія сервісу",
                "HEAD_2": "Виплачено",
                "HEAD_3": "Проведено угод",
                "HEAD_BUTTON_AUTH": "Створити аккаунт",
                "REVIEWS_TITLE": "Відкликання",
                "SUPPORT_TITLE": "У вас залишилися питання?",
                "SUPPORT_DESCRIPTION": "Наша команда готова допомогти Вам відповісти на будь-які питання, пов'язані з нашим сервісом. Просто зверніться до нас в чаті підтримки, і ми з радістю допоможемо вам вирішити будь-які виниклі проблеми.",
                "SUPPORT_BUTTON": "Відкрити чат з підтримкою",
                "HOWITWORK_TITLE": `Як працює ${appDomain.toLowerCase()}`,
                "HOWITWORK_DESCRIPTION": `${appDomain.toLowerCase()} - сервіс по захисту торгових угод в Інтернеті, який гарантує виконання зобов'язань і знижує ризики шахрайства і підробки.`,
                "HOWITWORK_STEP_TITLE": "Крок",
                "HOWITWORK_STEP_1_DESCRIPTION": "Замовник і виконавець домовилися про угоду",
                "HOWITWORK_STEP_2_DESCRIPTION": "Замовник або виконавець створює угоду",
                "HOWITWORK_STEP_3_DESCRIPTION": "Замовник переводить кошти на баланс угоди, сума заморожується",
                "HOWITWORK_STEP_4_DESCRIPTION": "Здійснюється виконання послуги виконавцем",
                "HOWITWORK_STEP_5_DESCRIPTION": "Замовник приймає і перевіряє послугу на відповідність",
                "HOWITWORK_STEP_6_DESCRIPTION": "Замовник підтверджує платіж, гроші переходять на баланс Виконавцю.",
                "HOWITWORK_STAGE_TITLE": "Етапи угоди",
                "HOWITWORK_STAGE_DESCRIPTION": "Всі захищені угоди мають певні статуси в залежності від етапу. Статуси управляються Користувачами або сервісом, згідно заданих умов.",
                "HOWITWORK_STAGE_1_TITLE": "Статус «Очікує підтвердження»",
                "HOWITWORK_STAGE_1_DESCRIPTION": "Після того, як покупець або продавець створили угоду, статус угоди стає «очікує підтвердження». У цьому статусі продавець або покупець повинні підтвердити угоду на сторінці Мої угоди. В умовах угоди можна вказати докладні умови, обов'язкові до виконання однією або обома сторонами – умови доставки, етапи оплати, якість товарів/послуг, термін перевірки або гарантійні зобов'язання.",
                "HOWITWORK_STAGE_2_TITLE": "Статус «Очікується оплата»",
                "HOWITWORK_STAGE_2_DESCRIPTION": "Після підтвердження угоди обома сторонами, угода переходить в статус «очікується оплата». Для здійснення подальших дій покупцеві необхідно здійснити оплату за угодою.",
                "HOWITWORK_STAGE_3_TITLE": "Статус «Оплачена»",
                "HOWITWORK_STAGE_3_DESCRIPTION": "На цій стадії продавець повинен передати товар або передати послугу обумовленим способом. Після того, як товар буде переданий, для перевірки на відповідність покупцем.",
                "HOWITWORK_STAGE_4_TITLE": "Статус «Арбітраж»",
                "HOWITWORK_STAGE_4_DESCRIPTION": `Якщо сторони не дійшли згоди (не відповідає товар заявленим характеристикам), то вони можуть вдатися до допомоги досвідчених арбітражних консультантів ${appDomain.toLowerCase()}, які вивчать суть спору і запропонують сторонам можливі компромісні рішення.`,
                "HOWITWORK_STAGE_5_TITLE": "Статус «Завершена угода»",
                "HOWITWORK_STAGE_5_DESCRIPTION": "На цій стадії продавець повинен передати товар або передати послугу обумовленим способом. Після того, як товар буде переданий, для перевірки на відповідність покупцем.",
                "HOWITWORK_WARNING_TITLE": "Період захисту угоди",
                "HOWITWORK_WARNING_DESCRIPTION": "Увага! Період захисту Угоди поширюється тільки в разі купівлі-продажу матеріальних товарів. Становить 21 день з моменту відправки товару, якщо в умовах угоди не вказано інший термін. Якщо протягом періоду захисту угоди покупець не здійснив дії «прийняти угоду» або «відкрити претензію», угода вважається завершеною, гроші переводяться продавцю.",
                "ADDBALANCE_TITLE": "Поповнення рахунку Monero (XMR)",
                "ADDBALANCE_DESCRIPTION_1": "Відправка коштів відбувається в ручному режимі. Для здійснення відправки - Вам необхідно зробити наступні дії описані нижче, після чого звернеться в чат Підтримки:",
                "ADDBALANCE_DESCRIPTION_2": "Важливо: курс по заявці буде зафіксований на 1 годину! Якщо протягом цього часу не отримано жодне підтвердження від мережі Monero, тоді курс зафіксується в момент отримання 1-го підтвердження, обмін виконаний після надходження коштів на наш гаманець Monero.",
                "ADDBALANCE_STEP_1": "Крок 1. Зверніться в чат Підтримки, вкажіть свій банк і суму поповнення. Оператор підбере для вас обмінник.",
                "ADDBALANCE_STEP_2": "Крок 2. Створіть заявку на сервісі обмінника. Реквізити гаманця Monero (XMR) сервісу:",
                "ADDBALANCE_STEP_3": "Крок 3. Дотримуйтесь інструкцій обмінника. Заповнюєте поля в заявці, вказуєте відповідні дані в необхідні поля.",
                "ADDBALANCE_STEP_4": "Крок 4. Надайте скріншот обробленої заявки в чат Підтримки.",
                "ADDBALANCE_WARNING_TITLE": "Увага!",
                "ADDBALANCE_WARNING_DESCRIPTION": "Оплата заявки на обміннику відбувається в ручному режимі. Вам потрібно оплатити її в додатку банку переказом за реквізитами, які надасть обмінник, після перекладу не забудьте натиснути кнопку 'я оплатив' на обміннику.",
                "BACK": "Назад",
                "BLOCKEDBALANCE_TITLE": "Ваш рахунок заблоковано",
                "BLOCKEDBALANCE_DESCRIPTION": "Ви не маєте права для перегляду цієї сторінки до його розблокування.",
                "BLOCKED_TITLE": "Ваш акаунт заблоковано",
                "BLOCKED_DESCRIPTION": "Ваш аккаунт був заблокований адміністратором у зв'язку з порушенням правил сайту.",
                "CREATE_TITLE": "Назва угоди",
                "CREATE_TITLE_PLACEHOLDER": "Назва . . .",
                "CREATE_ROLE": "Ваша роль",
                "CREATE_ROLE_1": "Замовник",
                "CREATE_ROLE_2": "Виконавець",
                "CREATE_LOGIN_PLACEHOLDER": "Введіть логін . . .",
                "CREATE_DESCRIPTION": "Опис угоди",
                "CREATE_DESCRIPTION_PLACEHOLDER": "Опис . . .",
                "CREATE_SUM": "Сума угоди",
                "CREATE_SUM_PLACEHOLDER": "Наприклад, 2000",
                "CREATE_DURATION": "Термін (днів)",
                "CREATE_DURATION_PLACEHOLDER": "Наприклад, 15",
                "CREATE_BUTTON": "Створити угоду",
                "CREATE_CALCULATOR_TITLE": "Розрахунок суми угоди з урахуванням комісії",
                "CREATE_CALCULATOR_1": "Сума угоди",
                "CREATE_CALCULATOR_2": "Комісія сервісу",
                "CREATE_CALCULATOR_3": "Підсумкова сума угоди",
                "ERROR_LOGIN_NOT_FOUND": "Користувача з таким логіном не знайдено",
                "ERROR_REQUIRED_FIELD": "Поле обов'язково для заповнення",
                "ERROR_INCORRECT_NUMBER": "Введіть коректне число",
                "ERROR_MIN_AMOUNT": "Мінімальна сума угоди:",
                "ERROR_MIN_DURATION": "Мінімальний термін:",
                "ERROR_NOT_ENOUGH_BALANCE": "Недостатньо коштів на рахунку",
                "ERROR_BLOCKEDBALANCE": "Ваш рахунок заблоковано",
                "ERROR_LOW_AMOUNT": "Занадто маленька сума",
                "ERROR_INCORRECT_EMAIL": "Некоректний email",
                "ERROR_SHORT_PASSWORD": "Пароль занадто короткий",
                "ERROR_PASSWORD_REPEAT": "Паролі не збігаються",
                "ERROR_LOGIN": "Неправильний логін або пароль",
                "CHAT_TITLE": "Чат угоди",
                "CHAT_PLACEHOLDER": "Введіть повідомлення . . .",
                "LAST_ONLINE_CONTACT": "Співрозмовник ",
                "LAST_ONLINE_WAS": "був у мережі ",
                "LAST_ONLINE_AGO": " тому",
                "LAST_ONLINE_ONLINE": " в мережі",
                "STATUS": "Статус",
                "DEAL_STATUS": "Статус угоди",
                "DEAL_BUTTON_GO": "Перейти",
                "DEAL_BUTTON_CONFIRM": "Підтвердити участь",
                "DEAL_BUTTON_PAY": "Оплатити",
                "DEAL_BUTTON_ARBITRAGE": "Відкрити арбітраж",
                "DEAL_BUTTON_UNARBITRAGE": "Скасувати арбітраж",
                "DEAL_BUTTON_CLOSE": "Підтвердивши",
                "BALANCE_BUTTON": "Поповнити",
                "BALANCE_DESCRIPTION_1": `${appDomain.toLowerCase()} не є банком, платіжною системою або іншою фінансовою організацією і не веде розрахункові рахунки користувачів.< br > Кабінет ${appDomain.toLowerCase()} забезпечує лише зручність розрахунків між клієнтами.`,
                "BALANCE_DESCRIPTION_2": "Переклад здійснюється протягом 24год.",
                "BALANCE_WARNING_DESCRIPTION": "на даний момент, переклади і висновки для вашого облікового запису припинені.",
                "BALANCE_WARNING_LINK": "Прочитайте опис причини обмежень",
                "BALANCE_HISTORY_TITLE": "Історія",
                "BALANCE_HISTORY_TAB_1": "Історія поповнень",
                "BALANCE_HISTORY_TAB_2": "Висновки за реквізитами",
                "BALANCE_HISTORY_TAB_3": "Переклад користувачеві",
                "OUTPUT_STATUS_1": "В обробці",
                "OUTPUT_STATUS_2": "Скасовуючи",
                "OUTPUT_STATUS_3": "Схвалений",
                "DEPOSIT_DATE": "Дата",
                "DEPOSIT_TYPE": "Тип реквізиту",
                "DEPOSIT_NUMBER_1": "Номер рахунку",
                "DEPOSIT_NUMBER_2": "Реквізит",
                "DEPOSIT_AMOUNT": "Сума",
                "DEPOSIT_STATUS": "Статус",
                "OUTPUT_BUTTON": "Створити переклад",
                "OUTPUT_NUMBER_PLACEHOLDER": "Реквізит",
                "OUTPUT_AMOUNT_PLACEHOLDER": "Сума",
                "TRANSACTION_SUCCESS": "Переклад відправлено",
                "TRANSACTION_LOGIN": "Логін користувача",
                "TRANSACTION_LOGIN_PLACEHOLDER": "Логін",
                "TRANSACTION_AMOUNT": "Сума",
                "TRANSACTION_AMOUNT_PLACEHOLDER": "Наприклад, 450",
                "TRANSACTION_BUTTON": "Відправивши",
                "NOTFOUND_TITLE": "Сторінку не знайдено",
                "NOTFOUND_DESCRIPTION": "Запитувана сторінка не знайдена, переконайтеся в правильності посилання в адресному рядку.",
                "NOTFOUND_BUTTON": "На головну",
                "NOTIFICATIONS_TITLE": `Повідомлення від ${appDomain}`,
                "NOTIFICATIONS_VOID": "У вас поки немає від нас повідомлень...",
                "DEALS_TITLE": "Мої угоди",
                "DEALS_VOID": "Поки що тут порожньо, тому що у вас ще жодної угоди",
                "SETTINGS_TITLE": "Настройка аккаунта",
                "SETTINGS_LOGIN": "Ім'я користувача",
                "SETTINGS_CURRENCY": "Відображена валюта",
                "SETTINGS_EMAIL_PLACEHOLDER": "Нова електронна пошта",
                "SETTINGS_PASSWORD": "Пароль",
                "SETTINGS_PASSWORD_PLACEHOLDER": "Новий пароль",
                "SETTINGS_BUTTON": "Зберегти зміни",
                "SETTINGS_SUCCESS": "Зміни збережені",
                "REGISTER_TITLE": "Реєстрація",
                "REGISTER_LOGIN_PLACEHOLDER": "Логін",
                "REGISTER_PASSWORD_PLACEHOLDER": "Пароль",
                "REGISTER_PASSWORD_REPEAT_PLACEHOLDER": "Повторіть пароль",
                "REGISTER_BUTTON": "Зареєструватися",
                "REGISTER_PRIVACY_1": "Я ознайомлений з",
                "REGISTER_PRIVACY_2": "Угодою користувача",
                "REGISTER_HAS_ACCOUNT": "Вже є рахунок?",
                "LOGIN_NOT_HAS_ACCOUNT": "Немає облікового запису?",
                "REVIEWS_DESCRIPTION": "Ми зібрали чесні відгуки від наших користувачів і ось що вони думають про нас",
                "REVIEWS_CARD_1": "Датська компанія, що управляє веб-сайтом відгуків, заснована в 2007 році, на якому розміщені численні позитивних відгуки про нашу діяльність.",
                "REVIEWS_CARD_2": "Найкорисніший форум відгуків, де мільйони людей обмінюються корисним досвідом, діляться фото і відео, ставлять оцінки і створюють рейтинги кращих.",
                "REVIEWS_CARD_3": "Електронна система розрахунків, заснована в 1998, де ми пройшли атестацію, що гарантує вам проведення операції з конкретною людиною.",
                "REVIEWS_CARD_BUTTON": "Читати більше",
                "REVIEWS_PROMO": "Якість і гарантія безпеки - наші головні пріоритети для створення сприятливого співробітництва. Для нас важливо підтримувати імідж для продовження стабільної роботи. Інтереси клієнтів для нас завжди знаходяться в пріоритеті, завдяки чому ми показуємо високий гарант якості.",
                "REVIEWS_REVIEW_NAME": "Аліна, дизайнер, м Челябінськ",
                "REVIEWS_REVIEW_TEXT": "Ми пропонуємо надійний і безпечний Гарант-сервіс для онлайн-угод. Наша команда фахівців забезпечить захист ваших інтересів і допоможе уникнути фінансових ризиків при проведенні угод в Інтернеті.",
                "REVIEWS_REVIEW_DEAL_BUTTON": "Почати угоду!",
                "REVIEWS_REVIEW_AUTH_BUTTON": "Зареєструватися зараз!",
                "REVIEWS_WHYME_TITLE": "Чому ми?",
                "REVIEWS_WHYME_1_TITLE": "Надійність",
                "REVIEWS_WHYME_1_DESCRIPTION": "Персональний атестат Webmoney виданий в 2009 році.",
                "REVIEWS_WHYME_2_TITLE": "Безпека",
                "REVIEWS_WHYME_2_DESCRIPTION": "Фінансові операції проходять через рахунок у Державному банку.",
                "REVIEWS_WHYME_3_TITLE": "Зручність",
                "REVIEWS_WHYME_3_DESCRIPTION": "Захист угод купівлі-продажу 24/7 без походу до юриста і в банк.",
                "REVIEWS_WHYME_4_TITLE": "Автоматизація",
                "REVIEWS_WHYME_4_DESCRIPTION": "Захист угод купівлі-продажу 24/7 без походу до юриста і в банк.",
                "REVIEWS_END_TEXT": "Чудовий сервіс, всім рекомендую, ми продали акаунтів більш ніж на 50к рублів саме через цей майданчик",
                "SERTIFICATES_DESCRIPTION": `Використовуйте наш сервіс ${appName}, не ризикуючи самостійно перевіряти людину на чесність. Шахрай ніколи і ні за яких умов не погодитися проводити безпечну угоду.`,
                "SERTIFICATES_1": "Протокол SSL забезпечує захищений обмін даних між користувачами Сервісу.",
                "SERTIFICATES_2": "Сертифікат розширеної перевірки (сертифікат EV) доводить, що сайт не є одним із шахрайських або підроблених сайтів. Власник сертифіката проходить повну перевірку автентичності згідно з найвищими стандартами індустрії безпеки. Перевірку і випуск сертифіката здійснює спеціалізований центр сертифікації (GeoTrust).",
                "SERTIFICATES_3": "Операції з банківським картами та електронні платежі здійснюються через партнерів MandarinPay і Robokassa, що мають діючі сертифікати безпеки PCI DSS версії 3.2",
                "SERTIFICATES_WARNING": `Пам'ятайте! Ніколи не перевіряйте людину на чесність самостійно, так як ризик втратити ваш товар дуже і дуже великий! Використовуйте гарант сервіс${appName}. І пам'ятайте, шахрай ніколи, ні за яких умов не погодиться на безпечну угоду, на гаранта.`,
                "FORWHOM_DESCRIPTION": `${appName} - сервіс для прийому платежів і захисту онлайн угод послуг. Для фізичних та юридичних осіб.`,
                "FORWHOM_CARD_1": "Продаж послуг по 100% передоплаті",
                "FORWHOM_CARD_2": "Гарантія оплати при виконанні зобов'язань",
                "FORWHOM_CARD_3": "Захист від підробки та шахрайства",
                "FORWHOM_STAGES_TITLE": "Етапи угоди",
                "FORWHOM_STAGES_DESCRIPTION": "Всі захищені угоди мають певні статуси в залежності від етапу. Статуси управляються Користувачами або сервісом, згідно заданих умов.",
                "FORWHOM_STAGES_1": "Переваги сервісу",
                "FORWHOM_STAGES_1_1": "Контроль ведення угоди",
                "FORWHOM_STAGES_1_2": "Вигідно і надійно",
                "FORWHOM_STAGES_1_3": "Професійна команда з врегулювання спорів",
                "FORWHOM_STAGES_1_4": "Прозорі умови, автоматичний розрахунок комісії",
                "FORWHOM_STAGES_2": `Кому корисний ${appName}`,
                "FORWHOM_STAGES_2_1": `Віртуальні послуги- ${appName} захистить ваші інтереси при продажу послуг, коли ви не можете заздалегідь підтвердити якість виконавця і не готові ризикувати, віддаючи послуги без передоплати.`,
                "FORWHOM_STAGES_2_2": `При продажу дорогої послуги ${appName} необхідний, так як передоплата і перевірка справжності товару, невід'ємні умови, які дозволяють провести операцію для обох сторін без ризиків.`,
                "FORWHOM_STAGES_2_3": `${appName} дозволить замовникам безпечно зробити передоплату онлайн, а вам отримати гарантію, що замовник заплатить після виконання вами всіх зобов'язань.`,
                "FORWHOM_STAGES_2_4": `Надання послуг, в тому числі віддалено - ${appName} спрощує віддалену роботу, так як захищає сторони від ризику невиконання зобов'язань: замовник отримує гарантію, що робота буде зроблена, Виконавець – оплату наданих послуг. ${appName} забезпечить виконання умов Угоди.`,
                "FORWHOM_STAGES_3": "Попереджений-значить озброєний",
                "FORWHOM_STAGES_3_1": "Останнім часом набирає оборот дуже хитрий спосіб шахрайства. Шахрай-замовник готовий вам оплатити повну ціну послуги. Ви надаєте йому гаманець для оплати, а замість оплати вам приходить рахунок, за яким ви повинні сплатити досить чималу комісію. Це шахрайство! Жодна платіжна система не вимагає оплати комісії від людини, що приймає грошові кошти. Якщо ви підтвердите оплату, ви просто переведете гроші лжезамовнику, який після їх отримання просто пропаде з мережі.",
                "FORWHOM_STAGES_3_2": "Кардінг - якщо у вас запитують CVV код карти і термін її дії-отримавши ці дані шахрай може зняти з карти всі гроші. Можуть надати фейковий скріншот сторінки оплати.",
                "FORWHOM_STAGES_3_3": "Якщо вам пише людина зі сторінки-одноденки (припустимо, 20 друзів, 1 фото, немає старих записів на стіні в vk.com), то це швидше за все шахрай, але в будь-якому випадку шахрай ніколи не погодиться на гаранта. Те ж саме відноситься і до скайпу. У Скайпі можна реєструватися хоч 100 разів на день, тому після здійснення угоди ви можете просто не знайти цю людину при появі будь-яких проблем.",
                "FORWHOM_STAGES_3_4": "Будьте пильні, не попадіться на виверти шахраїв",
                "FORWHOM_STAGES_4": "Переказ коштів з сайту",
                "FORWHOM_STAGES_4_1": "Переказ коштів з сайту проводиться протягом 24 годин, з 3% комісією при перекладі на банківську карту. Переказ на платіжні системи Qiwi Wallet, Yandex Money, Webmoney, PayPal здійснюються з комісією 1.5%",
                "FORWHOM_WARNING": `У разі виконання зобов'язань перед замовником грошові кошти перейдуть на ваш рахунок, при неналежному виконанні умов Угоди — повернуті замовнику. Пам'ятайте! Ніколи не перевіряйте людину на чесність самостійно, так як ризик втратити ваш товар дуже і дуже великий! Використовуйте гарант сервіс${appName}. Пам'ятайте, шахрай ніколи, ні за яких умов не погодиться на безпечну угоду, на гарант сервіс.`,
                "RULES_TITLE": "Угода користувача",
                "FROZEN": "заморожений",
                "FROZEN_TOOLTIP": "Заморожені кошти будуть доступні після завершення угоди",
                "RULES_TEXT": `
                    1. Терміни та визначення<br>
                    У цій Угоді користувача використовуються наступні терміни та визначення:<br>
                    1.1. Сайт – Сайт - сукупність програмних і апаратних засобів, об'єднаних в межах простору доменного імені
                    ${appDomain.toLowerCase()}, забезпечують взаємодію між користувачами.<br>
                    1.2. Користувач-фізична або юридична особа, зареєстрована на сайті і використовує його від свого імені.<br>
                    1.3. Обліковий запис (аккаунт) - унікальний запис Користувача, який створюється на сайті при реєстрації, Захищений паролем користувача.<br>
                    1.4. Покупець-користувач, який здійснює пошук продавців в рамках будь-яких ресурсів а також сайту, з метою
                    замовлення певного виду робіт.<br>
                    1.5. Продавець-користувач, що надає послуги виконання різного роду робіт.<br>
                    1.6. Контент-дані, інформація, матеріали, документи, файли, повідомлення і т. п. в електронній формі,
                    розташовані на сайті.<br>
                    2. Опис послуг<br>
                    Біржа ${appDomain.toLowerCase()} пропонує користувачам доступ до широкого спектру сервісів, без перерв, за винятком
                    часу проведення необхідних ремонтних і регламентних робіт. Всі існуючі на даний момент сервіси, а
                    також будь-який розвиток їх та / або додавання нових є предметом цієї Угоди. Ви розумієте і
                    погоджуєтеся з тим, що всі сервіси надаються за принципом "як є" і що адміністрація не несе
                    відповідальності ні за які затримки, збої, невірну або несвоєчасну доставку, видалення або незбереження
                    будь-якої користувальницької персональної інформації.<br>
                    3. Предмет угоди<br>
                    3.1. Користувач, який пройшов реєстрацію, має можливість використовувати Сайт і отримує право на наступні види
                    послуги:<br>
                    3.1.1. Проведення угод.<br>
                    3.1.2. Пошук продавців для виконання різного роду робіт.<br>
                    4.Надання послуг<br>
                    4.1. Персонал сайту має право на наступні дії:<br>
                    4.1.1. Відмовити Користувачеві в наданні послуг без вказівки причин в наступних випадках:<br>
                    4.1.1.1. Порушення Користувачем умов цієї Угоди або діючих правил.<br>
                    4.1.1.2. Невиконання Користувачем фінансових зобов'язань перед Сайтом та/або третіми особами.<br>
                    4.1.1.3. Наявності претензій на адресу користувача, підкріплених доказами в рамках сайту.<br>
                    4.1.1.4. Виникнення у персоналу сайту сумнівів у належному використанні Сайту Користувачем.<br>
                    4.1.1.5. Спроби з боку Користувача заподіяння будь-якого виду збитку Сайту.<br>
                    4.1.1.6. Відмови користувача від виконання будь-яких вимог з боку персоналу сайту.<br>
                    4.1.1.7. Прояви неповаги з боку Користувача по відношенню до персоналу сайту і / або іншим користувачам
                    Сайту.<br>
                    4.1.2. Видалити будь-яку інформацію зі сторінок сайту без вказівки причин або відмовити в її розміщенні.<br>
                    4.1.3. Вимагати від користувача пред'явлення документів, що засвідчують особу, а також надання
                    додаткових даних, що ідентифікують Користувача, в наступних випадках:<br>
                    4.1.3.1. При наявності підстав припускати, що Користувач порушує чинне законодавство, умови
                    цієї Угоди, права та інтереси персоналу сайту та / або третіх осіб.<br>
                    4.1.3.2. Інших випадках необхідності ідентифікації користувача на розсуд персоналу сайту.<br>
                    4.1.4. Призупинити роботу Програмного та / або апаратного забезпечення Сайту з метою оновлення, а також
                    проведення профілактичних робіт.<br>
                    4.1.5. Не обслуговувати запити до Сайту з анонімних проксі-серверів.<br>
                    5. Права та Обов'язки користувача сайту<br>
                    5.1. Користувач Сайту ${appDomain.toLowerCase()} має наступні права та обов'язки:<br>
                    5.1.1. Реєструвати тільки один обліковий запис.<br>
                    5.1.2. Надати достовірні персональні дані і надалі підтримувати їх в актуальному стані.<br>
                    5.1.3. Слідувати приписам і строго дотримуватися чинних правил, що визначають порядок використання
                    Сайту.<br>
                    5.1.4. Не використовувати Сайт в протиправних цілях.<br>
                    5.1.5. Не вчиняти протизаконних дій з використанням сайту.<br>
                    5.1.6. Не розголошувати конфіденційну інформацію, яка використовується для доступу до облікового запису.<br>
                    5.2. Користувачам Сайту ${appDomain.toLowerCase()} заборонений:<br>
                    5.2.1. Використовувати в своїх повідомленнях нецензурних слів, лайки, образливих для інших користувачів.<br>
                    5.2.2. Використовувати чужі роботи, які не мають відношення до Вашого особистого портфоліо<br>
                    5.2.3. Знижувати / підвищувати рейтинг несанкціонованими методами (за допомогою відгуків, рекомендацій, рекламних
                    повідомлень в блогах, проектах, особистих повідомленнях, коментарях).<br>
                    5.2.4. Порушувати права неповнолітніх осіб та / або заподіяння їм шкоди в будь-якій формі.<br>
                    5.2.5. Завантажувати, надсилати, передавати або будь-яким іншим способом розміщувати контент, який ви не маєте права
                    робити доступним за законом Російської Федерації або згідно з якими-небудь контрактними відносинами.<br>
                    5.2.6. Порушення будь-яким способом нормальної роботи сайту ${appDomain.toLowerCase()};<br>
                    5.2.7. Розміщення посилань на ресурси, зміст яких суперечить чинному законодавству Росії;<br>
                    5.2.8. Масова публікація повідомлень (коментарів, відгуків, особистих повідомлень користувачам) рекламного
                    характеру (спам) і флуду;<br>
                    5.2.9. Розміщення і пропаганда порнографії та дитячої еротики, а також реклама інтимних послуг<br>
                    5.2.10 перекази доступні користувачеві, після того як він поповнить рахунок на сайті, саме що сума поповнення
                    встановлюється для кожного індивідуально, в залежності від проведеної(їх) їм угод. Кошти отримані іншим
                    шляхом (перекладом від інших користувачів, проведенням угод), враховуватися не будуть.<br>
                    5.2.11 адміністрація сервісу, зобов'язує себе встановити суму мінімального поповнення виходячи з угод на сайті на
                    момент перекладу, для доступу Користувачеві до переказів валюти сайту іншим користувачам і дод.функцій сервісу.
                    Так само Мінімальна сума поповнення потрібна для верифікації особистості.<br>
                    5.2.12 У разі порушення даних Умов та інших зобов'язань за цією Угодою Адміністрація ресурсу
                    ${appDomain.toLowerCase()} (або представники адміністрації в особі модераторів) мають право:<br>
                    попередити вас в письмовому вигляді;<br>
                    заблокувати ваш аккаунт;<br>
                    обнулити Ваш рейтинг.<br>
                   5.2.13 всі реальні платежі які надходять на баланс сервісу є пожертвуванням на розвиток проекту,
                    натомість на це, адміністрація сервісу поповнює рахунок користувача який пожертвував N-ю суму, на суму рівну
                    пожертвуванням користувача.<br>
                    6. Відповідальність сторін<br>
                    6.1. Персонал сайту не несе відповідальність:<br>
                    6.1.1. За дії користувачів, що порушують чинне законодавство.<br>
                    6.1.2. За інформацію і матеріали, що розміщуються Користувачами в рамках сайту.<br>
                    6.1.3. За якість товарів і послуг, що надаються користувачами і самим сервісом ${appDomain.toLowerCase()}<br>
                    6.1.4. За будь-який збиток і / або упущену вигоду користувачів і / або третіх осіб в результаті використання або
                    неможливості використання Сайту.<br>
                    6.1.5 за незнання правил сервісу, а так само за незнання тих. чи інших пунктів які стосуються самого
                    користувач. PS: незнання закону не звільняє від відповідальності. <br>
                    6.2. Користувач несе
                    відповідальність:<br>
                    6.2.1. За всі дії, здійснені від імені користувача в рамках сайту.<br>
                    6.2.2. За якість і своєчасність виконання прийнятих в рамках сайту зобов'язань.<br>
                    6.2.3. За безпеку конфіденційної інформації, яка використовується для доступу до облікового запису.<br>
                    6.2.4. У разі блокувань / підозр користувача (лей) в Frod платежі, для розблокування рахунку, проводиться
                    повторна транзакція на ту ж суму (остання сума), що була проведена перед блокуванням з іншої банківської
                    карта. Якщо у користувача немає такої можливості, Адміністрація зобов'язує себе видалити платіжні дані
                    Користувача, за запитом самого користувача, для проведення транзакції з тієї ж карти, що була здійснена перед
                    блокування.<br>
                    6.3 відповідно до Правил проведення платежів на сервісі, для уникнення відмивання / шахрайства через сервіс,
                    Користувач зобов'язаний проводити всі транзакції з реквізитів які були внесені спочатку.<br>
                    7. Розірвання угоди<br>
                    Ви згодні з тим, що Сайт ${appDomain.toLowerCase()} залишає за собою право припинити дію Вашого Облікового запису і видалити
                    будь-який контент з будь-якої причини, в тому числі при невикористанні доступу або при порушенні правил Угоди.<br>
                    7.1. Персонал сайту має право в односторонньому порядку вносити зміни в цю угоду, а також
                    чинні правила.<br>
                    7.2. Використання Сайту після внесення змін означає безумовне прийняття (акцепт) Користувачем умов
                    оновленої редакції Угоди.<br>
                    7.3. Персонал сайту має право в односторонньому порядку анулювати угоду, якщо обліковий запис
                    Користувача не використовувалася більше 12 календарних місяців поспіль. Під використанням облікового запису
                    мається на увазі авторизація Користувача на сайті. 7.4. ${appDomain.toLowerCase()} може в будь-який момент закрити будь-який зі своїх
                    сервісів, з або без попереднього повідомлення. ${appDomain.toLowerCase()} також не несе відповідальності за припинення
                    доступу до своїх сервісів.<br>
                    8. Прийняття умов Угоди<br>
                    8.1. Безумовним прийняттям (акцептом) умов цієї Угоди є Реєстрація на сайті.<br>
                    8.2. Прийняття умов цієї Угоди означає безумовне прийняття Користувачем умов Політики
                    конфіденційності, а також інших діючих правил.<br>
                    9. Звільнення від гарантій<br>
                    9.1. Ви розумієте і погоджуєтеся з тим, що:<br>
                    9.1.1. Ви використовуєте послуги ${appDomain.toLowerCase()} на ваш власний ризик. Служби надаються"як є". ${appDomain.toLowerCase()} не
                    приймає на себе ніякої відповідальності, в тому числі і за відповідність сервісів Мети користувача;<br>
                    9.1.2. ${appDomain.toLowerCase()} не гарантує, що: послуги будуть відповідати вашим вимогам; послуги будуть
                    надаватися безперервно, швидко, надійно і без помилок; результати, які можуть бути отримані з
                    використанням сервісів, будуть точними і надійними; якість будь-якого продукту, послуги, інформації та ін.,
                    отриманого з використанням Сервісу, буде відповідати вашим очікуванням; всі помилки в програмах будуть
                    виправлені;<br>
                    9.1.3. Будь-які матеріали, отримані вами з використанням сервісів ${appDomain.toLowerCase()}, ви можете використовувати на свій
                    власний страх і ризик, на вас покладається відповідальність за будь-яку шкоду, яка може бути завдана вашому
                    комп'ютера і ваших даних в результаті завантаження цих матеріалів; Валюта на сайті є крипто-валютою і не
                    має реальної цінності. Валюту може придбати будь-який користувач, як за допомогою угод, так і купивши її за
                    реальні гроші.<br>
                    9.1.4.${appDomain.toLowerCase()} не несе відповідальності за будь-які прямі або непрямі збитки, що сталися через: використання
                    або неможливості використання сервісів; несанкціонованого доступу до ваших комунікацій; заяви або
                    поведінка будь-якої третьої особи в сервісах.<br>
                    10. Сервіси, сайти та контент третіх осіб<br>
                    10.1. Сервіси Сайту можуть містити посилання на інші сервіси та сайти в мережі Інтернет (сервіси та Сайти Третіх
                    осіб), включаючи послуги онлайн-спілкування (електронна пошта, Skype, ICQ), конструктор договорів і т. п. зазначені
                    треті особи та їх контент не перевіряються на відповідність тим чи іншим вимогам (достовірності, повноти,
                    законності і т.п.). Сервіс знаходиться в бета тесті, висновки на реальні електронні гаманці, банківські карти і
                    тп. не можливі. Біржа ${appDomain.toLowerCase()} не несе відповідальність за будь-яку інформацію, матеріали, розміщені на сервісах
                    і сайтах третіх осіб, до яких Користувач отримує доступ з використанням сервісів Сайту, в тому числі, за
                    будь-які думки або твердження, виражені на сервісах і сайтах третіх осіб, рекламу і т. п., а також за
                    доступність таких сервісів і сайтів або контенту і наслідки їх використання Користувачем.<br>
                    10.2. Посилання (в будь-якій формі) на будь-який сайт, продукт, послугу, будь-яку інформацію комерційного або некомерційного
                    характеру, розміщена на сайті, не є схваленням або рекомендацією даних продуктів (послуг,
                    діяльності), за винятком випадків, коли на це прямо вказується Сайтом.<br>
                    11. Порядок розгляду спорів<br>
                    11.1. Угода є юридично зобов'язуючим договором між Вами і ${appDomain.toLowerCase()} і регламентує використання
                    Вами сервісів ${appDomain.toLowerCase()}. Відповідними договорами на вас також можуть бути накладені Додаткові
                    зобов'язання, пов'язані з використанням інших сервісів, а також контенту або програмного забезпечення,
                    належить третій стороні.<br>
                    11.2. Ви і ${appDomain.toLowerCase()} погоджуєтеся на те, що всі можливі суперечки з приводу Угоди будуть вирішуватися за нормами
                    російського права.<br>
                    11.3. Ніщо в Угоді не можна розуміти як встановлення між Вами та ${appDomain.toLowerCase()} агентських відносин,
                    відносин товариства, відносин по спільній діяльності, відносин особистого найму, або якихось інших
                    відносин, прямо не передбачених Угодою.<br>
                    11.4. Визнання судом будь-якого положення Угоди недійсним або таким, що не підлягає примусовому
                    виконання не тягне недійсності або нездійсненності інших положень Угоди.<br>
                    11.5. Бездіяльність з боку ${appDomain.toLowerCase()} у разі порушення вами або іншими користувачами положень Угоди не
                    позбавляє ${appDomain.toLowerCase()} права вжити відповідних дій на захист своїх інтересів пізніше, а також не означає
                    відмови ${appDomain.toLowerCase()} від своїх прав у разі вчинення в подальшому подібних або подібних порушень.<br>
                    12. Вступ угоди в силу і дію<br>
                    12.1. Угода вступає в силу з моменту акцепту цієї Оферти Покупцем. Безумовним прийняттям
                    (акцептом) умов цієї Оферти угоди вважається здійснення Покупцем реєстрації на сайті шляхом
                    заповнення реєстраційної форми та вираження згоди з умовами угоди при натисканні кнопки
                    "Зареєструватися", розміщеної на сторінці сайту з реєстраційною формою. Безумовним прийняттям (акцептом)
                    всіх умов надання платних послуг також вважається здійснення Покупцем платежу в рахунок оплати
                    Платні послуги.<br>
                    12.2. Угода укладається на невизначений термін і може бути розірвано з ініціативи будь-якої зі сторін в
                    будь-який час. Для цього продавець розміщує повідомлення про розірвання угоди на сайті та / або направляє
                    Покупцеві на його профіль (або реєстраційні дані) відповідне повідомлення, з моменту такого
                    розміщення / направлення такого повідомлення Угода вважається розірваною. Покупець може розірвати
                    Угода шляхом запиту на видалення свого профілю з сайту, при цьому далі обробка персональних даних
                    здійснюється згідно з пунктами 4.8. - 4.11. цієї Угоди.<br>
                    12.3. Ця Угода поширює свою дію на покупців, які здійснили реєстрацію до дати
                    опублікування цієї угоди на сайті.<br>
                    12.4. Користувач зобов'язується самостійно знайомитися з умовами Угоди. Якщо Користувач не згоден з
                    умовами цієї Угоди, то він повинен негайно видалити свій профіль з сайту, в іншому випадку
                    продовження використання Користувачем Сайту означає, що Користувач згоден з умовами Угоди.<br>
                    12.5. При визначенні термінів визначення часу вчинення стороною або третіми особами дії (бездіяльності)
                    обчислюється за московським часом.<br>
                    13. Інші умови<br>
                    13.1. Сторони домовилися, що при виконанні (зміні, доповненні, припиненні) Угоди допускається
                    використання підписів представників сторін, а також їх печаток, за допомогою засобів факсимільного зв'язку,
                    механічного або іншого копіювання, електронно-цифрового підпису або іншого аналога власноручного підпису
                    керівників і печаток організацій. Сторони підтверджують, що документи до угоди, підписані та оформлені
                    зазначеним в цьому пункті способом, мають юридичну силу і обов'язкові для виконання Сторонами.<br>
                    13.2. Сторони визнають будь-яку інформацію, що стосується укладення угоди, включаючи будь-які додатки і доповнення
                    до нього, комерційною таємницею і зобов'язуються строго зберігати конфіденційний характер такої інформації, не
                    розголошуючи її третім особам без попередньої письмової на те згоди іншої сторони, за винятком
                    випадків, коли це необхідно для цілей Угоди або для розкриття відповідним державним органам в
                    випадках, визначених законом. Зазначене положення не відноситься до загальновідомої або загальнодоступної інформації.
                    Сторони вважають конфіденційною всю ділову інформацію, передану один одному, як-то: інформація про їх
                    клієнтах, партнерах, бізнес-планах, цінах, фінансовому стані і т. д. сторони не повинні відкривати таку
                    інформацію кому б то не було, за винятком випадків, коли це необхідно для належного виконання їх
                    зобов'язань за угодою, або якщо така інформація є загальнодоступною, або за взаємним погодженням
                    Сторона.<br>
                    13.3. Будь-які повідомлення, запити або інші повідомлення (кореспонденція), що подаються Сторонами один одному
                    повинні бути оформлені в письмовому вигляді і направлені одержуючій стороні поштою, шляхом направлення замовний
                    кореспонденції, електронною поштою, факсом або з кур'єром, як буде визнано доцільним. Датою отримання
                    кореспонденції вважається момент отримання поштового відправлення, в тому числі рекомендованої кореспонденції,
                    електронного підтвердження доставки при відправленні електронною поштою або факсом, або день доставки в разі
                    відправлення кореспонденції з кур'єром. При розгляді спорів в суді листування сторін по електронній пошті,
                    факсимільні повідомлення будуть визнані Сторонами достатніми доказами, якщо цією Угодою прямо
                    не передбачено інший спосіб направлення кореспонденції.<br>
                    14. Припинення дії облікового запису користувача<br>
                    14.1. Користувач згоден з тим, що Товариство залишає за собою право припинити дію облікового запису
                    Користувача на ${appDomain.toLowerCase()} в будь-який час без попереднього повідомлення Користувача.<br>
                    14.2. Припинення дії облікового запису може бути вироблено в зв'язку з наступними причинами: а) порушення
                    положень цієї Угоди, а також доповнень до нього, що є їх невід'ємною частиною; б) по
                    відповідному запиту органів влади, згідно із законодавством Російської Федерації; в) у разі
                    поширення Користувачем непідтвердженої негативної інформації про суспільство; г) у зв'язку з непередбаченими
                    проблемами технічного характеру або обставинами, пов'язаними з безпекою і т. п.<br>
                    14.3. Суспільство має право видалити обліковий запис Користувача на ${appDomain.toLowerCase()} та / або призупинити, обмежити або
                    припинити доступ до будь-якого з сервісів, якщо суспільство виявить в діях Користувача ознаки порушення
                    умов цієї Угоди, без пояснення причин таких дій.<br>
                    15. Виключні права на утримання сервісів і контент<br>
                    15.1. Користувач зобов'язується не відтворювати, не копіювати, не повторювати, не продавати і не перепродавати, а
                    також не використовувати для будь-яких комерційних цілей і не доводити до загального відома, не поширювати
                    програми ${appDomain.toLowerCase()}, цілком або будь-які частини сервісів ${appDomain.toLowerCase()} (включаючи вміст, доступний Користувачеві
                    за допомогою сервісів), або доступ до них, крім тих випадків, коли Користувач попередньо отримав таке
                    дозвіл від суспільства.<br>
                    15.2. Всі об'єкти, доступні за допомогою сервісів і ${appDomain.toLowerCase()}, в тому числі елементи дизайну, текст, Графічні
                    зображення, ілюстрації, відео, програми для ЕОМ, бази даних, музика, звуки та інші об'єкти (більше —
                    зміст сервісів), а також будь-який контент, розміщений на сервісах і ${appDomain.toLowerCase()}, є об'єктами
                    виключних прав правовласника, та інших правовласників.<br>
                    15.3. Використання контенту, а також будь-яких інших елементів сервісів і ${appDomain.toLowerCase()} можливо тільки в рамках
                    функціоналу, пропонованого тим чи іншим сервісом. Ніякі елементи змісту сервісів і ${appDomain.toLowerCase()}, а також будь-який
                    вміст, розміщений у сервісах та ${appDomain.toLowerCase()}, не можуть бути використані іншим чином без попереднього
                    дозволи Правовласника. Під використанням маються на увазі, в тому числі: відтворення, копіювання,
                    переробка, поширення на будь-якій основі, відображення у фреймі і т. д. виняток становлять випадки, прямо
                    передбачені законодавством РФ.<br>
                    15.4. Використання Користувачем елементів змісту сервісів, а також будь-якого контенту для особистого
                    некомерційного використання, допускається за умови збереження всіх знаків охорони авторського права, суміжних
                    прав, товарних знаків, інших повідомлень про авторство, збереження імені (або псевдоніма) автора/найменування
                    Правовласника в незмінному вигляді, збереженні відповідного об'єкта в незмінному вигляді. Виняток становлять
                    випадки, прямо передбачені законодавством РФ.<br>
                    16. Інші положення<br>
                    16.1. Ця Угода являє собою договір між Користувачем і суспільством щодо порядку
                    використання Сервісів ${appDomain.toLowerCase()} і замінює собою всі попередні угоди між Користувачем і суспільством по
                    вищевказаним питань.<br>
                    16.2. Ця Угода регулюється і тлумачиться відповідно до законодавства Російської Федерації.
                    Питання, не врегульовані цією Угодою, підлягають вирішенню відповідно до законодавства
                    Російська Федерація. Всі можливі суперечки, що випливають з відносин, регульованих цією Угодою,
                    дозволяються в порядку, встановленому чинним законодавством Російської Федерації, за нормами російського
                    право. Скрізь по тексту цієї Угоди, якщо явно не вказано інше, під терміном»законодавство"
                    розуміється як законодавство Російської Федерації.<br>
                    16.3. Зважаючи на безоплатність послуг, що надаються в рамках цієї Угоди, норми про захист прав споживачів,
                    передбачені законодавством Російської Федерації, не можуть бути застосовними до відносин між
                    Користувачем (продавцем і/або Покупцем) і суспільством.<br>
                    16.4. Ніщо в Угоді не може розумітися як встановлення між Користувачем і суспільством відносин
                    товариства, відносин по спільній діяльності, відносин особистого найму, або якихось інших відносин, прямо
                    не передбачених Угодою.<br>
                    16.5. Якщо з тих чи інших причин одне або кілька положень цієї Угоди будуть визнані
                    недійсними або такими, що не мають юридичної сили, це не впливає на дійсність або
                    застосовність інших положень Угоди.<br>
                    16.6. Бездіяльність з боку суспільства в разі порушення Користувачем або іншими користувачами положень
                    Угод не позбавляє суспільства права вжити відповідних дій на захист своїх інтересів пізніше, а
                    також не означає відмови суспільства від своїх прав у разі вчинення в подальшому подібних або подібних
                    порушення.<br>
                    16.7. Будь-який реальний платіж, переказ реальних коштів сайту, є добровільним, адміністрація може тільки
                    проінформувати про мінімальну суму поповнення або ж дати поради як це зробити. Ніхто ніяким чином не
                    примушує і не змушує кого або переводити кошти сайту, це чисто добровільне і усвідомлене рішення
                    користувач.
                `
            }
        }
    }
}

export default LanguageProvider
