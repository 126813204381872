<template>
    <div :class="{ fixed: this.$store.state.isShowMobileMenu }">
        <div class="wrapper">
            <container class="vah-content">
                <logo />
                <button v-if="this.$store.state.isShowMobileMenu" class="menu-button" @click="closeMenu">
                    <img src="@/assets/img/close.svg" class="menu-button__image">
                </button>
                <button v-else class="menu-button" @click="openMenu">
                    <img src="@/assets/img/burger.svg" class="menu-button__image">
                </button>
            </container>
        </div>
        <div class="menu" v-show="this.$store.state.isShowMobileMenu">
            <container class="vah-content">
                <div class="links">
                    <router-link class="links__item" to="/howitwork">{{ this.$store.getters.content.HEADER_HOWITWORK
                        }}</router-link>
                    <router-link class="links__item" to="/forwhom">{{ this.$store.getters.content.HEADER_FORWHOM
                        }}</router-link>
                    <router-link class="links__item" to="/sertificates">{{
        this.$store.getters.content.HEADER_SERTIFICATES }}</router-link>
                    <router-link class="links__item" to="/reviews">{{ this.$store.getters.content.HEADER_REVIEWS
                        }}</router-link>
                    <template v-if="this.$store.state.isAuth">
                        <router-link class="links__item" to="/notifications">{{
        this.$store.getters.content.HEADER_NOTIFICATIONS }}</router-link>
                        <router-link class="links__item" to="/deals">{{ this.$store.getters.content.HEADER_DEALS
                            }}</router-link>
                        <router-link class="links__item" to="/balance">{{ this.$store.getters.content.HEADER_BALANCE }}
                            <CurrentCurrency class="primary-text" :value="this.$store.state.currentUser.balance" />
                            <span v-if="this.$store.state.currentUser.frozenBalance"> (<CurrentCurrency :value="this.$store.state.currentUser.frozenBalance" />)</span>
                        </router-link>
                        <p class="links__item" @click="$emit('changeLanguage')">
                            {{ this.$store.getters.content.HEADER_CURRENT_LANGUAGE }}
                            <span class="primary-text">{{ this.$store.state.currentLanguage }}</span>
                        </p>
                        <router-link v-if="this.$store.state?.currentUser.roles.includes('Admin')" class="links__item"
                            to="/admin">{{ this.$store.getters.content.HEADER_ADMIN }}</router-link>
                        <router-link class="links__item" to="/settings">{{ this.$store.getters.content.HEADER_SETTINGS
                            }}</router-link>
                        <p @click="$emit('logout')" class="links__item">
                            <span class="primary-text">
                                {{ this.$store.getters.content.HEADER_LOGOUT }}
                            </span>
                        </p>
                    </template>
                    <template v-else>
                        <p class="links__item balance-wrapper" @click="$emit('changeLanguage')">
                            {{ this.$store.getters.content.HEADER_CURRENT_LANGUAGE }}
                            <span class="balance primary-text">{{ this.$store.state.currentLanguage }}</span>
                        </p>
                        <p v-if="!this.$store.state.isAuth" @click="this.$store.dispatch('showLoginWindow')"
                            class="links__item">{{ this.$store.getters.content.HEADER_LOGIN }}</p>
                        <p v-if="!this.$store.state.isAuth" @click="this.$store.dispatch('showRegisterWindow')"
                            class="links__item"><span class="primary-text">{{ this.$store.getters.content.HEADER_SIGNUP
                                }}</span></p>
                    </template>
                </div>
            </container>
        </div>
    </div>
</template>

<style scoped>
.fixed {
    position: fixed;
    width: 100%;
    z-index: 2;
    top: 0;
}

.wrapper {
    padding: 12px 0;
    background-color: var(--neutral-background-90);
}

.vah-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-button {
    background: none;
    border: none;
    cursor: pointer;
    width: 36px;
    height: 36px;
}

.menu-button__image {
    width: 100%;
    height: 100%;
}

.menu {
    background-color: var(--neutral-background-100);
    padding-top: 36px;
    padding-bottom: 100vh;
}

.links {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.links__item {
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: var(--neutral-white);
    display: block;
}
</style>

<script>
import CurrentCurrency from '../../currency/CurrentCurrency.vue';

export default {
    emits: ['logout', 'changeLanguage'],
    methods: {
        openMenu() {
            this.$store.dispatch("showMobileMenu");
        },
        closeMenu() {
            this.$store.dispatch("hideMobileMenu");
        }
    },
    components: {
        CurrentCurrency
    }
}
</script>