<template>
    <header v-if="this.$store.state.isReady">
        <DesktopHeader @logout="logout" @changeLanguage="changeLanguage" class="desktop" />
        <MobileHeader @logout="logout" @changeLanguage="changeLanguage" class="mobile" />
    </header>
</template>

<style>
.mobile {
    display: none;
}

@media (max-width: 1400px) {
    .mobile {
        display: block;
    }

    .desktop {
        display: none !important;
    }
}
</style>

<script>
import DesktopHeader from './DesktopHeader.vue'
import MobileHeader from './MobileHeader.vue'

export default {
    components: {
        DesktopHeader,
        MobileHeader
    },
    methods: {
        logout() {
            delete localStorage.accessToken
            this.$store.dispatch('updateCurrentUser')
            this.$router.push("/")
        },
        changeLanguage() {
            this.$store.dispatch('changeCurrentLanguage')
        }
    }
}
</script>