<template>
    <div class="auth-window__header">
        <h2 class="auth-window__title">{{ this.$store.getters.content.REGISTER_TITLE }}</h2>
        <img @click="this.$store.dispatch('hideAuthWindow')" class="auth-window__close" src="@/assets/img/close.svg">
    </div>
    <form class="auth-window__form" @submit.prevent="register">
        <div class="auth-window__form__inputs-wraper">
            <div class="auth-window__input-wrapper">
                <primary-input @input="validateUserName" maxlength="30" v-model="username"
                    :isError="!!errors.username.length" :placeholder="this.$store.getters.content.REGISTER_LOGIN_PLACEHOLDER" />
                <small class="auth-window__error-text" v-if="errors.username.length">{{ errors.username.join("\n")
                }}</small>
            </div>
            <div class="auth-window__input-wrapper">
                <primary-input @input="validateEmail" v-model="email" :isError="!!errors.email.length"
                    placeholder="Email" />
                <small class="auth-window__error-text" v-if="errors.email.length">{{ errors.email.join("\n") }}</small>
            </div>
            <div class="auth-window__input-wrapper">
                <primary-input @input="validatePassword" type="password" v-model="password"
                    :isError="!!errors.password.length" :placeholder="this.$store.getters.content.REGISTER_PASSWORD_PLACEHOLDER" />
                <small class="auth-window__error-text" v-if="errors.password.length">{{ errors.password.join("\n")
                }}</small>
            </div>
            <div class="auth-window__input-wrapper">
                <primary-input @input="validateConfirmPassword" type="password" v-model="confirmPassword"
                    :isError="!!errors.confirmPassword.length" :placeholder="this.$store.getters.content.REGISTER_PASSWORD_REPEAT_PLACEHOLDER" />
                <small class="auth-window__error-text" v-if="errors.confirmPassword.length">{{
                    errors.confirmPassword.join("\n") }}</small>
            </div>
        </div>
        <primary-button class="auth-window__button">{{ this.$store.getters.content.REGISTER_BUTTON }}</primary-button>
        <div class="auth-window__policy">
            <input @input="validateIsAcceptPolicy" v-model="isAcceptPolicy" type="checkbox" class="auth-window__checkbox">
            <p :class="{ error: errors.isAcceptPolicy.length }">{{ this.$store.getters.content.REGISTER_PRIVACY_1 }} <router-link class="primary-text"
                    @click="this.$store.dispatch('hideAuthWindow')" to="/policy">"{{ this.$store.getters.content.REGISTER_PRIVACY_2 }}"</router-link></p>
        </div>
        <p class="auth-window__change-window-text">{{ this.$store.getters.content.REGISTER_HAS_ACCOUNT }} <span class="primary-text"
                @click="this.$store.dispatch('showLoginWindow')">{{ this.$store.getters.content.HEADER_LOGIN }}</span></p>
    </form>
</template>

<style scoped>
.auth-window__policy {
    margin-top: 24px;
    display: flex;
    gap: 8px;
    align-items: center;
}

.auth-window__checkbox {
    width: 20px;
    height: 20px;
    border: none;
    background: var(--primary-pink);
    accent-color: var(--primary-pink);
    outline: none;
}

.auth-window__policy>p {
    font-size: 12px;
    font-weight: 400;
    color: var(--neutral-white);
}

.auth-window__policy>p.error {
    color: var(--red);
}
</style>

<script>
import AuthService from "@/services/AuthService.js"

export default {
    data() {
        return {
            username: "",
            email: "",
            password: "",
            confirmPassword: "",
            isAcceptPolicy: false,
            errors: {
                username: [],
                email: [],
                password: [],
                confirmPassword: [],
                isAcceptPolicy: []
            }
        }
    },
    methods: {
        async register() {
            if (!this.isValid()) {
                return
            }

            try {
                const response = await AuthService.register(this.username, this.email, this.password)
                localStorage.accessToken = response.accessToken.token
                this.$store.dispatch("updateCurrentUser")
                this.$store.dispatch("hideAuthWindow")
            }
            catch (e) {
                if (e.response.data?.errorCode === "Validation") {
                    this.errors.username = e.response.data.description?.UserName?.map(
                        e => {
                            if (this.$store.state.currentLanguage == "EN") {
                                return e.replace(
                                    "Пользователь с таким именем уже существует",
                                    "This username is already taken"
                                )
                            }
                            if (this.$store.state.currentLanguage == "UA") {
                                return e.replace(
                                    "Пользователь с таким именем уже существует",
                                    "Користувач з таким ім'ям вже існує"
                                )
                            }
                            return e
                        }
                    ) || []
                    this.errors.email = e.response.data.description?.Email?.map(
                        e => {
                            if (this.$store.state.currentLanguage == "EN") {
                                return e.replace(
                                    "Пользователь с такой почтой уже существует",
                                    "This email is already taken"
                                )
                            }
                            if (this.$store.state.currentLanguage == "UA") {
                                return e.replace(
                                    "Пользователь с такой почтой уже существует",
                                    "Користувач з такою поштою вже існує"
                                )
                            }
                            return e    
                        }
                    ) || []
                    this.errors.password = e.response.data.description.Password || []
                }
            }
        },
        isValid() {
            this.validateUserName()
            this.validateEmail()
            this.validatePassword()
            this.validateConfirmPassword()
            this.validateIsAcceptPolicy()
            return !Object.entries(this.errors).some(([key, value]) => value.length)
        },
        validateUserName() {
            this.username = this.username.replace(/ /g, '');
            if (this.username.length === 0) {
                this.errors.username = [this.$store.getters.content.ERROR_REQUIRED_FIELD]
            }
            else {
                this.errors.username = []
            }
        },
        validateEmail() {
            if (this.email.length === 0) {
                this.errors.email = [this.$store.getters.content.ERROR_REQUIRED_FIELD]
            }
            else if (!this.email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
                this.errors.email = [this.$store.getters.content.ERROR_INCORRECT_EMAIL]
            }
            else {
                this.errors.email = []
            }
        },
        validatePassword() {
            this.validateConfirmPassword()
            if (this.password.length === 0) {
                this.errors.password = [this.$store.getters.content.ERROR_REQUIRED_FIELD]
            }
            else if (this.password.length < 8) {
                this.errors.password = [this.$store.getters.content.ERROR_SHORT_PASSWORD]
            }
            else {
                this.errors.password = []
            }
        },
        validateConfirmPassword() {
            if (this.confirmPassword.length === 0) {
                this.errors.confirmPassword = [this.$store.getters.content.ERROR_REQUIRED_FIELD]
            }
            else if (this.password != this.confirmPassword) {
                this.errors.confirmPassword = [this.$store.getters.content.ERROR_PASSWORD_REPEAT]
            }
            else {
                this.errors.confirmPassword = []
            }
        },
        validateIsAcceptPolicy(event) {
            this.isAcceptPolicy = event?.target.checked ?? this.isAcceptPolicy
            if (!this.isAcceptPolicy) {
                this.errors.isAcceptPolicy = [this.$store.getters.content.ERROR_REQUIRED_FIELD]
            }
            else {
                this.errors.isAcceptPolicy = []
            }
        }
    }
}
</script>
