import axios from "axios"
import store from "../store"

const NotificationService = {
    async getNotifications({ perPage = 30, page = 1 } = {}) {
        const response = await axios.get("/api/notifications", { headers: { Authorization: `Bearer ${store.state.accessToken}` }, params: arguments[0] })
        return response.data
    },
}

export default NotificationService
