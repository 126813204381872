<template>
    <window-wrapper :isShow="isShow" @hideWindow="$emit('hideWindow')">
        <div @click.stop class="output-window primary-linear-stroke">
            <div class="auth-window__header">
                <h2 class="auth-window__title">Вывод средств</h2>
                <img @click="$emit('hideWindow')" class="auth-window__close" src="@/assets/img/close.svg">
            </div>
            <form @submit.prevent="createOutputRequest" class="auth-window__form">
                <div class="auth-window__form__inputs-wraper">
                    <div class="auth-window__input-wrapper">
                        <select class="primary-input" v-model="paymentSystemId">
                            <option v-for="paymentSystem in paymentSystems" :value="paymentSystem.id">
                                {{ paymentSystem.name }}
                            </option>
                        </select>
                    </div>
                    <div class="auth-window__input-wrapper">
                        <primary-input
                            @input="validatePaymentDetails"
                            type="text" v-model="paymentDetails"
                            :isError="!!errors.paymentDetails.length"
                            :placeholder="this.$store.getters.content.OUTPUT_NUMBER_PLACEHOLDER"
                        />
                        <small class="auth-window__error-text" v-if="errors.paymentDetails.length">{{
                            errors.paymentDetails.join("\n")
                        }}</small>
                    </div>
                    <div class="auth-window__input-wrapper">
                        <primary-input
                            @input="validateAmount"
                            type="number"
                            v-model="amount"
                            step="0.01"
                            :isError="!!errors.amount.length"
                            :placeholder="`${this.$store.getters.content.OUTPUT_AMOUNT_PLACEHOLDER} (${currentCurrency.symbol})`" />
                        <small class="auth-window__error-text" v-if="errors.amount.length">{{
                            errors.amount.join("\n")
                        }}</small>
                    </div>
                </div>
                <primary-button class="auth-window__button">{{ this.$store.getters.content.OUTPUT_BUTTON }}</primary-button>
            </form>
        </div>
    </window-wrapper>
</template>

<style scoped>
.output-window {
    width: 390px;
    padding: 24px 35px;
    background: var(--neutral-background-100);
    margin: auto;
    border-radius: 16px;
}
</style>

<script>
import PaymentSystemService from "@/services/PaymentSystemService"
import BalanceService from "@/services/BalanceService"

export default {
    emits: ["hideWindow", "newRequest"],
    props: {
        isShow: {
            default: true
        }
    },
    data() {
        return {
            paymentSystemId: 0,
            paymentDetails: "",
            amount: "",
            errors: {
                paymentDetails: [],
                amount: []
            },
            paymentSystems: []
        }
    },
    async created() {
        this.paymentSystems = (await PaymentSystemService.getPaymentSystems()).filter(e => e.isForOutput)
        this.paymentSystemId = this.paymentSystems[0].id
    },
    methods: {
        async createOutputRequest() {
            if (!this.isValid()) {
                return
            }

            try {
                await BalanceService.createOutputRequest({ paymentSystemId: this.paymentSystemId, paymentDetails: this.paymentDetails, amount: this.rubAmount })
                this.$store.dispatch("updateCurrentUser")
                this.$emit("hideWindow")
                this.$emit("newRequest")
                this.paymentSystemId = this.paymentSystems[0].id
                this.paymentDetails = ""
                this.amount = ""
            }
            catch (e) {
                if (e.response.data?.errorCode == "NotEnoughtBalance") {
                    this.$notify({ text: this.$store.getters.content.ERROR_NOT_ENOUGH_BALANCE })
                }
            }
        },
        isValid() {
            this.validatePaymentDetails()
            this.validateAmount()
            return !Object.entries(this.errors).some(([key, value]) => value.length)
        },
        validatePaymentDetails() {
            if (this.paymentDetails.length === 0) {
                this.errors.paymentDetails = [this.$store.getters.content.ERROR_REQUIRED_FIELD]
            }
            else {
                this.errors.paymentDetails = []
            }
        },
        validateAmount() {
            if (this.$store.state.currentCurrency == "RUB") {
                if (!/^[0-9]+$/.test(this.amount)) {
                    this.errors.amount = [this.$store.getters.content.ERROR_INCORRECT_NUMBER]
                    return
                }   
            }
            else {
                let isValidDecimalLength = !(this.amount.split(".").length == 2) || this.amount.split(".")[1].length <= 2
                if (isNaN(this.amount) || isNaN(parseFloat(this.amount)) || !isValidDecimalLength) {
                    this.errors.amount = [this.$store.getters.content.ERROR_INCORRECT_NUMBER]
                    return
                }
            }
            if (this.rubAmount < 1) {
                this.errors.amount = [this.$store.getters.content.ERROR_LOW_AMOUNT]
            }
            else {
                this.errors.amount = []
            }
        },
    },
    computed: {
        currentCurrency() {
            return this.$store.state.currencies.find(e => e.id === this.$store.state.currentCurrency)
        },
        rubAmount() {
            return Math.floor(this.amount / this.currentCurrency.rate)
        }
    },
}
</script>