<template>
    <container>
        <div class="admin-deals__header">
            <form @submit.prevent="setUsers" class="admin-deals__filter">
                <div class="admin-deals__input-wrapper">
                    <p>
                        Сортировать по
                    </p>
                    <select class="admin-deals__filter__input" v-model="sortBy">
                        <option value="UserName">Алфавиту (По возрастанию)</option>
                        <option value="DescUserName">Алфавиту (По убыванию)</option>
                        <option value="Balance">Балансу (По возрастанию)</option>
                        <option value="DescBalance">Балансу (По убыванию)</option>
                        <option value="LastOnline">Последнему онлайну (По возрастанию)</option>
                        <option value="DescLastOnline">Последнему онлайну (По убыванию)</option>
                    </select>
                </div>
                <div class="admin-deals__input-wrapper search">
                    <p>
                        Поиск
                    </p>
                    <primary-input placeholder="Часть логина . . ." v-model="searchQuery" />
                </div>
                <button type="sumbit" class="admin-deals__filter__button primary-linear-stroke">Найти</button>
            </form>
        </div>
        <table class="admin-deals__main" v-if="usersCount">
            <AdminUserCard v-for="user in users" :user="user" :key="user.id" />
        </table>
        <div class="admin-deals__footer" v-if="usersCount">
            <primary-pagination class="admin__pagination" :count="usersCount" :perPage="9" v-model="page"
                @update:modelValue="setUsers" />
        </div>
        <p class="admin-deals__notfound-text" v-else>Пользователей не найдено</p>
    </container>
</template>

<script>
import AdminUserService from "@/services/AdminUserService.js"
import AdminUserCard from "@/components/partials/admin/AdminUserCard.vue"

export default {
    components: {
        AdminUserCard
    },
    data() {
        return {
            searchQuery: "",
            sortBy: "UserName",
            users: [],
            usersCount: 0,
            page: 1
        }
    },
    async created() {
        await this.setUsers();
    },
    methods: {
        async setUsers() {
            const response = await AdminUserService.getUsers(
                {
                    searchQuery: this.searchQuery,
                    isDesc: this.sortBy.startsWith("Desc"),
                    sortBy: this.sortBy.replace(/^Desc/, ''),
                    page: this.page,
                    perPage: 9
                }
            )
            this.users = response.items
            this.usersCount = response.count
        }
    }
}
</script>
