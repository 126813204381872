import axios from "axios"
import store from '../store'

const PaymentSystemService = {
    async getPaymentSystems() {
        const response = await axios.get(`/api/paymentSystems`, { headers: { Authorization: `Bearer ${store.state.accessToken}` }, params: arguments[1] })
        return response.data
    }
}

export default PaymentSystemService
