<template>
    <container class="content">
        <logo />
        <div class="links">
            <router-link class="links__item" to="/reviews">{{ this.$store.getters.content.HEADER_REVIEWS }}</router-link>
            <router-link class="links__item" to="/forwhom">{{ this.$store.getters.content.HEADER_FORWHOM }}</router-link>
            <router-link class="links__item" to="/sertificates">{{ this.$store.getters.content.HEADER_SERTIFICATES }}</router-link>
            <router-link class="links__item" to="/howitwork">{{ this.$store.getters.content.HEADER_HOWITWORK }}</router-link>
        </div>
        <div class="user-info" v-if="this.$store.state.isAuth">
            <router-link class="links__item" to="/notifications">{{ this.$store.getters.content.HEADER_NOTIFICATIONS }}</router-link>
            <router-link class="links__item" to="/deals">{{ this.$store.getters.content.HEADER_DEALS }}</router-link>
            <router-link class="links__item balance-wrapper" to="/balance">
                {{ this.$store.getters.content.HEADER_BALANCE }}
                <span class="balance">
                    <CurrentCurrency class="primary-text" :value="this.$store.state.currentUser.balance" />
                    <br>
                    <span v-if="this.$store.state.currentUser.frozenBalance" :title="this.$store.getters.content.FROZEN_TOOLTIP"> (<CurrentCurrency :value="this.$store.state.currentUser.frozenBalance" />)</span>
                </span>
            </router-link>
            <p class="links__item balance-wrapper" @click="$emit('changeLanguage')">
                {{ this.$store.getters.content.HEADER_CURRENT_LANGUAGE }}
                <span class="balance primary-text">{{ this.$store.state.currentLanguage }}</span>
            </p>
            <div @click="toggleMenu" class="user-info__menu">
                <img src="@/assets/img/user.svg">
                <p class="user-info__username">{{ this.$store.state.currentUser.userName }}</p>
                <img src="@/assets/img/menuarrow.svg">
                <div @click.stop class="user-info__menu__window" v-show="isShowMenu">
                    <router-link class="user-info__menu__window__item"
                        v-if="this.$store.state.currentUser.roles.includes('Admin')" to="/admin">
                        {{ this.$store.getters.content.HEADER_ADMIN }}
                    </router-link>
                    <router-link class="user-info__menu__window__item"
                        to="/settings">
                        {{ this.$store.getters.content.HEADER_SETTINGS }}
                    </router-link>
                    <div @click="$emit('logout')" class="user-info__menu__window__item">
                        {{ this.$store.getters.content.HEADER_LOGOUT }}  
                    </div>
                </div>
            </div>
        </div>
        <div class="auth" v-else>
            <p class="links__item balance-wrapper" @click="$emit('changeLanguage')">
                {{ this.$store.getters.content.HEADER_CURRENT_LANGUAGE }}
                <span class="balance primary-text">{{ this.$store.state.currentLanguage }}</span>
            </p>
            <p @click="this.$store.dispatch('showLoginWindow')" class="links__item">{{ this.$store.getters.content.HEADER_LOGIN }}</p>
            <linear-stroke-button @click="this.$store.dispatch('showRegisterWindow')" class="auth__button">{{ this.$store.getters.content.HEADER_SIGNUP }}</linear-stroke-button>
        </div>
    </container>
</template>

<style scoped>
.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
}

.links {
    display: flex;
    gap: 30px;
    align-items: center;
}

@media (max-width: 1300px) {
    .links {
        gap: 25px;
    }
}

.links__item {
    color: var(--neutral-white);
    text-wrap: nowrap;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.7;
}

.links__item:hover {
    opacity: 1;
}

.auth {
    display: flex;
    gap: 16px;
    align-items: center;
}

.user-info {
    display: flex;
    align-items: center;
    gap: 24px;
}

.balance-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
}

.balance {
    text-align: center;
    position: absolute;
    top: 20px;
}

.user-info__menu {
    display: flex;
    align-items: center;
    gap: 4px;
    position: relative;
    cursor: pointer;
}

.user-info__menu>img {
    width: 24px;
    height: 24px;
}

.user-info__username {
    color: var(--neutral-white);
    font-size: 16px;
    font-weight: 700;
}

.user-info__menu__window {
    position: absolute;
    right: 0;
    width: 150px;
    padding: 10px 0;
    top: 30px;
    background-color: var(--neutral-background-90);
    border-radius: 5px;
    border: 2px solid var(--gray);
}

.user-info__menu__window__item {
    cursor: pointer;
    width: 100%;
    padding: 6px 0;
    text-align: center;
    font-size: 14px;
    color: var(--neutral-white);
    font-weight: 600;
    display: block;
    text-decoration: none;
}

.user-info__menu__window__item:hover {
    background: var(--neutral-background-100);
}
</style>

<script>
import CurrentCurrency from '../../currency/CurrentCurrency.vue'

export default {
    emits: ['logout', 'changeLanguage'],
    data() {
        return {
            isShowMenu: false
        }
    },
    methods: {
        toggleMenu() {
            this.isShowMenu = !this.isShowMenu
        }
    },
    components: {
        CurrentCurrency
    }
}
</script>