<template>
    <div class="container">
        <slot></slot>
    </div>
</template>

<style>
.container {
    width: 1230px;
    margin: auto;
}

@media (max-width: 1300px) {
    .container {
        width: 1100px
    }
}

@media (max-width: 1200px) {
    .container {
        width: 1000px
    }
}

@media (max-width: 1100px) {
    .container {
        width: 900px
    }
}

@media (max-width: 1000px) {
    .container {
        width: 90%;
    }
}
</style>

<script>
export default {
    name: "container"
}
</script>