<template>
    <AdminTabs v-model:state="state" />
    <router-view></router-view>
</template>

<script>
import AdminTabs from "@/components/partials/admin/AdminTabs.vue"

export default {
    components: {
        AdminTabs
    },
    data() {
        return {
            state: 1
        }
    }
}
</script>