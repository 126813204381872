<template>
    <container>
        <div class="deals__header">
            <h2 class="deals__header__title">{{ this.$store.getters.content.DEALS_TITLE }}</h2>
            <router-link to="/deals/create">
                <primary-button class="deals__header__button">{{ this.$store.getters.content.PROMO_BUTTON }}</primary-button>
            </router-link>
        </div>
        <table class="deals__main" v-if="dealsCount">
            <DealCard v-for="deal in deals" :deal="deal" :key="deal.id" />
        </table>
        <p class="deals__error-text" v-if="dealsCount === 0">{{ this.$store.getters.content.DEALS_VOID }}</p>
        <div class="deals-footer" v-if="dealsCount">
            <primary-pagination class="admin__pagination" :count="dealsCount" :perPage="9" v-model="page"
                @update:modelValue="setDeals" />
        </div>
    </container>
</template>

<style scoped>
.deals__header {
    padding: 36px 24px;
    display: flex;
    justify-content: space-between;
    background-color: var(--neutral-background-90);
    margin-top: 56px;
    border-radius: 8px;
}

.deals__header__title {
    color: var(--neutral-white);
    font-size: 36px;
    font-weight: 600;
}

@media (max-width: 700px) {
    .deals__header {
        flex-direction: column;
        gap: 16px;
    }

    .deals__header__title {
        font-size: 28px;
    }

    .deals__header__button {
        width: 100%;
    }
}

.deals__main {
    margin-top: 36px;
    display: table;
    border-spacing: 0 24px;
    border-collapse: separate;
    flex-direction: column;
    width: 100%;
}

.deals-footer {
    display: flex;
    justify-content: center;
    margin-top: 26px;
}

.deals__error-text {
    margin-top: 220px;
    margin-bottom: 220px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: var(--neutral-white);
}

@media (max-width: 700px) {
    .deals__error-text {
        font-size: 16px;
    }
}
</style>

<script>
import DealCard from "@/components/partials/deal/DealCard.vue"
import DealService from "@/services/DealService.js"

export default {
    components: {
        DealCard
    },
    data() {
        return {
            deals: [],
            dealsCount: null,
            page: 1
        }
    },
    async created() {
        if (this.$store.state.currentUser.roles.includes("BlockedBalance")) {
            this.$router.push("/blockedBalance")
            return
        }
        await this.setDeals()
    },
    methods: {
        async setDeals() {
            const response = await DealService.getDeals({ page: this.page, perPage: 9 })
            this.deals = response.items
            this.dealsCount = response.count
        }
    }
}
</script>