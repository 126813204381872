<template>
    <div class="add-balance container">
        <section class="accountInfo screen">
            <div class="container">
                <div class="row">
                    <div class="accountInfo__title position-relative">
                        <div class="prev">
                            <router-link class="fc__warning" to="/balance">{{ this.$store.getters.content.BACK }}</router-link>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="accountInfo__description bg__third">
                        <h2>{{ this.$store.getters.content.ADDBALANCE_TITLE }}</h2>
                        <div class="accountInfo__description__txt mt-36">
                            <p>
                                {{ this.$store.getters.content.ADDBALANCE_DESCRIPTION_1 }}
                            </p>
                            <p>
                                {{ this.$store.getters.content.ADDBALANCE_DESCRIPTION_2 }}
                            </p>
                            <br>
                            <p>
                                {{ this.$store.getters.content.ADDBALANCE_STEP_1 }}
                            </p>
                            <p>
                                {{ this.$store.getters.content.ADDBALANCE_STEP_2 }}
                                <span class="primary-text">45qCpu1GVsz2UoN3TkFgHu8R3oocHVXucAs9KscnrgDYETiMCUA86ue9tdMNHZQ4rb3MTAfogrdGfX1jspRY54t8CzVJY8X</span>
                            </p>
                            <p>
                                {{ this.$store.getters.content.ADDBALANCE_STEP_3 }}
                            </p>
                            <p class="primary-text">{{ this.$store.getters.content.ADDBALANCE_WARNING_TITLE }}</p>
                            <p>{{ this.$store.getters.content.ADDBALANCE_WARNING_DESCRIPTION }}</p>
                            <p>
                                {{ this.$store.getters.content.ADDBALANCE_STEP_4 }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<style scoped>
.accountInfo {
    color: var(--neutral-white);
}

.add-balance {
    margin-top: 35px;
    flex: 1 0 auto;
}

.accountInfo__title {
    margin-bottom: 36px;
}

.list {
    list-style: none;
    margin-top: 40px;
}

.prev {
    margin-left: 12px;
    margin-right: 22px;
    transform: translateY(-3px);
}

.prev a:before {
    margin-right: 10px;
    content: "";
    height: 100%;
    width: 38px;
    position: absolute;
    top: 1px;
    left: -20px;
    display: block;
    background: url('@/assets/img/arrow_back.svg') no-repeat;

}

.fc__warning {
    color: #FA20BF;
    margin-left: 8px;
    text-decoration: none;
}

.accountInfo__description {
    border-radius: 16px;
    padding: 40px 115px 40px 24px;
    background: var(--neutral-background-90);

}

.accountInfo__description__items {
    border-top: 1px solid white;
    border-image: linear-gradient(to right, #FFFFFF, #FA20BF, #6F3BF7, #000000) 1;
    margin-top: 24px;
    padding-top: 24px;
}

.accountInfo__description__txt ul li span,
.accountInfo__description__txt ul li span a,
.accountInfo__description__txt__change a {
    color: #FA20BF;
}

.accountInfo__description__txt>p {
    margin-top: 10px;
}

.primary-text {
    word-break: break-all;
}

.accountInfo__description__txt__change li:before {
    content: "";
    position: absolute;
    top: 12px;
    left: -10px;
    display: block;
    width: 4px;
    height: 4px;
    background: #3B5FDB;
    border-radius: 100px;
}

@media (max-width:1200px) {
    .screen {
        height: auto;
    }
}
</style>