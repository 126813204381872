import store from "@/store"

function wordFactory(count, wordIf1, wordIf234, wordElse) {
    if ([11, 12, 13, 14, 15, 16, 17, 18, 19].includes(count)) {
        return wordElse
    }
    else if (count % 10 == 1) {
        return wordIf1
    }
    else if ([2, 3, 4].includes(count % 10)) {
        return wordIf234
    }
    else {
        return wordElse
    }
}

function getMinutesWord(count) {
    if (store.state.currentLanguage == "RU") {
        return wordFactory(count, "минуту", "минуты", "минут")
    }
    else if (store.state.currentLanguage == "UA") {
        return wordFactory(count, "хвилину", "хвилини", "хвилин")
    }
    else {
        return count > 1 ? "minutes" : "minute"
    }
}

function getHoursWord(count) {
    if (store.state.currentLanguage == "RU") {
        return wordFactory(count, "час", "часа", "часов")
    }
    else if (store.state.currentLanguage == "UA") {
        return wordFactory(count, "годину", "години", "годин")
    }
    else {
        return count > 1 ? "hours" : "hour"
    }
}

function getDaysWord(count) {
    if (store.state.currentLanguage == "RU") {
        return wordFactory(count, "день", "дня", "дней")
    }
    else if (store.state.currentLanguage == "UA") {
        return wordFactory(count, "день", "дні", "днів")
    }
    else {
        return count > 1 ? "day" : "days"
    }
}

const WordService = {
    getLastOnlineMessage(lastOnline) {
        let lastOnlineDifference = (Date.now() - new Date(lastOnline)) / 1000
        let minutes = Math.round(lastOnlineDifference / 60)
        let hours = Math.round(minutes / 60)
        let days = Math.round(hours / 24)
        if (lastOnlineDifference / 60 < 3) {
            return store.getters.content.LAST_ONLINE_ONLINE
        }
        else if (minutes < 60) {
            return store.getters.content.LAST_ONLINE_WAS + minutes.toString() + " " + getMinutesWord(minutes) + store.getters.content.LAST_ONLINE_AGO
        }
        else if (hours < 24) {
            return store.getters.content.LAST_ONLINE_WAS + hours.toString() + " " + getHoursWord(hours) + store.getters.content.LAST_ONLINE_AGO
        }
        else if (days <= 30) {
            return store.getters.content.LAST_ONLINE_WAS + days.toString() + " " + getDaysWord(days) + store.getters.content.LAST_ONLINE_AGO
        }
        else {
            return store.getters.content.LAST_ONLINE_WAS + new Date(lastOnline).toLocaleString("ru-RU")
        }
    }
}

export default WordService
