<template>
    <section class="reviews container">
        <h1 class="reviews-title">{{ this.$store.getters.content.REVIEWS_TITLE }}</h1>
        <p class="reviews-desc">
            {{ this.$store.getters.content.REVIEWS_DESCRIPTION }}
        </p>
        <div class="reviews-cards">
            <div class="reviews-card">
                <div class="review-card-content">
                    <div class="review-card-top">
                        <img src="@/assets/img/trustpilot.png" alt="">
                    </div>
                    <div class="review-card-bottom">
                        <p class="review-card-text">
                            {{ this.$store.getters.content.REVIEWS_CARD_1 }}
                        </p>
                        <a class="review-card-btn btn" :href="`https://trustpilol.su/showthread_81_0_t352211.php?i=${this.$store.state.appDomain.toLowerCase()}`" target="_blank">{{ this.$store.getters.content.REVIEWS_CARD_BUTTON }}</a>
                    </div>
                </div>
            </div>
            <div class="reviews-card">
                <div class="review-card-content">
                    <div class="review-card-top">
                        <img src="@/assets/img/otzovik.png" alt="">
                    </div>
                    <div class="review-card-bottom">
                        <p class="review-card-text">
                            {{ this.$store.getters.content.REVIEWS_CARD_2 }}
                        </p>
                        <a :href="`https://otzovil.su/reviews/garant-service/?i=${this.$store.state.appDomain.toLowerCase()}`" target="_blank" class="review-card-btn btn">{{ this.$store.getters.content.REVIEWS_CARD_BUTTON }}</a>
                    </div>
                </div>
            </div>
            <div class="reviews-card">
                <div class="review-card-content">
                    <div class="review-card-top">
                        <img src="@/assets/img/webmoney.png" alt="">
                    </div>
                    <div class="review-card-bottom">
                        <p class="review-card-text">
                            {{ this.$store.getters.content.REVIEWS_CARD_3 }}
                        </p>
                        <a href="https://passport.webmoney.ru/asp/CertView.asp?wmid=305928582560" target="_blank" class="review-card-btn btn">{{ this.$store.getters.content.REVIEWS_CARD_BUTTON }}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="reviews-info">
            <div class="reviews-info-content">
                <p>
                    {{ this.$store.getters.content.REVIEWS_PROMO }}
                </p>
            </div>
        </div>
    </section>
    <section class="real-review container">
        <div class="real-review-left-content">
            <p class="real-review-user">{{ this.$store.getters.content.REVIEWS_REVIEW_NAME }}</p>
            <p class="real-review-text">
                {{ this.$store.getters.content.REVIEWS_REVIEW_TEXT }}
            </p>
            <button v-if="!this.$store.state.isAuth" class="real-review-btn btn" @click="this.$store.dispatch('showRegisterWindow')">{{ this.$store.getters.content.REVIEWS_REVIEW_AUTH_BUTTON }}</button>
            <button v-else class="real-review-btn btn" @click="this.$router.push('/deals/create')">{{ this.$store.getters.content.REVIEWS_REVIEW_DEAL_BUTTON }}</button>
        </div>
        <div class="real-review-right-content">
            <img src="@/assets/img/Frame3097.png" alt="">
        </div>
    </section>
    <section class="whywe container">
        <h1 class="whywe-title">{{ this.$store.getters.content.REVIEWS_WHYME_TITLE }}</h1>
        <div class="whywe-cards">
            <div class="whywe-card">
                <div class="whywe-card-content">
                    <div class="whywe-card-img">
                        <img src="@/assets/img/locked.png" alt="">
                    </div>
                    <h1 class="whywe-card-title">{{ this.$store.getters.content.REVIEWS_WHYME_1_TITLE }}</h1>
                    <p class="whywe-card-desc">{{ this.$store.getters.content.REVIEWS_WHYME_1_DESCRIPTION }}</p>
                </div>
            </div>
            <div class="whywe-card">
                <div class="whywe-card-content">
                    <div class="whywe-card-img">
                        <img src="@/assets/img/locked2.png" alt="">
                    </div>
                    <h1 class="whywe-card-title">{{ this.$store.getters.content.REVIEWS_WHYME_2_TITLE }}</h1>
                    <p class="whywe-card-desc">{{ this.$store.getters.content.REVIEWS_WHYME_2_DESCRIPTION }}</p>
                </div>
            </div>
            <div class="whywe-card">
                <div class="whywe-card-content">
                    <div class="whywe-card-img">
                        <img src="@/assets/img/locked3.png" alt="">
                    </div>
                    <h1 class="whywe-card-title">{{ this.$store.getters.content.REVIEWS_WHYME_3_TITLE }}</h1>
                    <p class="whywe-card-desc">{{ this.$store.getters.content.REVIEWS_WHYME_3_DESCRIPTION }}</p>
                </div>
            </div>
            <div class="whywe-card">
                <div class="whywe-card-content">
                    <div class="whywe-card-img">
                        <img src="@/assets/img/locked4.png" alt="">
                    </div>
                    <h1 class="whywe-card-title">{{ this.$store.getters.content.REVIEWS_WHYME_4_TITLE }}</h1>
                    <p class="whywe-card-desc">{{ this.$store.getters.content.REVIEWS_WHYME_4_DESCRIPTION }}</p>
                </div>
            </div>
        </div>
    </section>
    <section class="real-review-2 container">
        <div class="real-review-2-left-content">
            <img src="@/assets/img/Frame3096.png" alt="">
        </div>
        <div class="real-review-2-right-content">
            <div class="real-review-2-img-block">
                <img src="@/assets/img/locked4.png" alt="">
            </div>
            <p class="real-review-2-text">
                {{ this.$store.getters.content.REVIEWS_END_TEXT }}
            </p>
            <p class="real-review-2-user">
                Yudaev team, Worldwide
            </p>
        </div>
    </section>
</template>

<style scoped>
.reviews {
    color: var(--neutral-white);
    margin-top: 100px;
}

.reviews-title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    margin-bottom: 24px;
}

.reviews-desc {
    text-align: center;
    width: 470px;
    margin: auto;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 64px;
}

.reviews-card {
    padding: 1px;
    border-radius: 8px;
    margin-bottom: 40px;
    width: 395px;
    background: linear-gradient(to right, #FFFFFF, #FA20BF, #6F3BF7, #000000 100%);
    margin-bottom: 48px;
}

.reviews-card:nth-child(1),
.reviews-card:nth-child(3) {
    margin-right: 22px;
}

.review-card-content {
    width: 100%;
    height: 360px;
    background: var(--neutral-background-90);
    border-radius: 8px;
    position: relative;
}

.review-card-top {
    background-color: var(--primary-violet-main);
    width: 100%;
    height: 96px;
    border-radius: 8px 8px 0 0;
}

.review-card-top img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    margin: 25px auto;
}

.review-card-bottom {
    padding: 24px;
}

.review-card-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 16px;
}

.review-card-btn {
    border: none;
    width: 100%;
    height: 56px;
    background: var(--primary-gradient);
    color: var(--neutral-white);
}

.reviews-cards {
    display: flex;
    flex-wrap: wrap;
    width: 812px;
    margin: auto;
    justify-content: center;
}

.reviews-info {
    padding: 1px;
    border-radius: 8px;
    margin-bottom: 40px;
    width: 808px;
    background: linear-gradient(to right, #FFFFFF, #FA20BF, #6F3BF7, #000000 100%);
    margin-bottom: 48px;
    margin: auto;
    margin-bottom: 56px;
}

.reviews-info-content {
    width: 100%;
    height: 144px;
    padding: 24px 16px;
    background: var(--neutral-background-90);
    border-radius: 8px;
}

.reviews-info-content {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.real-review-user {
    color: var(--gray);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 25px;
}

.real-review-text {
    width: 523px;
    color: var(--neutral-text);
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 33px;
}

.real-review-btn {
    border: none;
    width: 321px;
    height: 56px;
    background: var(--primary-gradient);
    color: var(--neutral-white);
}

.real-review {
    margin-bottom: 56px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.whywe-title {
    color: var(--neutral-text);
    font-size: 47px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 38px;
}

.whywe-cards {
    display: flex;
    justify-content: space-between;
    margin-bottom: 56px;
}

.whywe-card {
    padding: 1px;
    border-radius: 8px;
    width: 288px;
    background: linear-gradient(to right, #FFFFFF, #FA20BF, #6F3BF7, #000000 100%);
}

.whywe-card-content {
    width: 100%;
    height: 308px;
    padding: 24px 16px 12px 16px;
    background: var(--neutral-background-90);
    border-radius: 8px;
    position: relative;
}

.whywe-card-img {
    height: calc(124px + 24px);
}

.whywe-card-img img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    margin: 24px auto;
}

.whywe-card-title {
    color: var(--neutral-white);
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 8px;
}

.whywe-card-desc {
    color: var(--neutral-text);
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

.real-review-2 {
    display: flex;
    height: 482px;
    background: var(--neutral-background-90);
    border-radius: 8px;
    margin-bottom: 100px;
}

.real-review-2-right-content {
    margin: auto;
    margin-top: 64px;
}

.real-review-2-img-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.real-review-2-text {
    width: 420px;
    color: var(--neutral-white);
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    margin-bottom: 50px;
}

.real-review-2-user {
    margin: auto;
    width: 160px;
    color: var(--neutral-text);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

@media (max-width:1400px) {
    .whywe-cards {
        flex-wrap: wrap;
        margin-bottom: 36px;
    }

    .whywe-card {
        width: 560px;
        margin-bottom: 24px;
    }

    .whywe-card-content {
        width: 560px;
    }
}

@media (max-width:1300px) {
    .whywe-card {
        width: 500px;
    }

    .whywe-card-content {
        width: 500px;
    }

}

@media (max-width:1200px) {
    .real-review-2-text {
        width: 100%;
        margin-bottom: 20px;
    }

    .real-review-2-right-content {
        margin: auto;
        margin-top: 20px;
        width: 350px;
    }

    .real-review-2 {
        height: unset;
        padding-bottom: 50px;
    }

    .real-review-2-left-content img {
        width: 450px;
    }

    .whywe-card {
        width: 100%;
    }

    .whywe-card-content {
        width: 100%;
    }

    .real-review {
        flex-direction: column-reverse;
        align-items: start;
    }

    .real-review-left-content {
        width: 600px;
        margin-top: 38px;
    }

    .real-review-right-content {
        width: 100%;
    }

    .real-review-right-content img {
        width: 100%;
    }
}

@media (max-width:1100px) {
    .real-review-2-left-content img {
        width: 100%;
    }

    .real-review-2 {
        flex-direction: column;
    }
}

@media (max-width:991px) {
    .reviews-cards {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .reviews-card:nth-child(1),
    .reviews-card:nth-child(3) {
        margin-right: 0px;
    }

    .reviews-info {
        display: none;
    }

    .real-review-2 {
        margin-bottom: 56px;
    }
}

@media (max-width:680px) {
    .real-review-user {
        font-size: 14px;
        line-height: 20px;
    }

    .real-review-text {
        font-size: 16px;
        line-height: 24px;
    }

    .real-review-left-content {
        width: 100%;
    }

    .real-review-right-content img {
        width: 100%;
    }

    .real-review-text {
        width: 100%;
    }

    .real-review-btn {
        width: 100%;
    }
}

@media (max-width:600px) {
    .real-review-2-user {
        font-size: 14px;
        line-height: 20px;
    }

    .real-review-2-text {
        font-size: 16px;
        width: 250px;
        margin: auto;
        margin-bottom: 20px;
    }

    .real-review-2-right-content {
        width: 100%;
    }
}

@media (max-width:520px) {
    .reviews-title {
        color: var(--neutral-text);
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 36px;
    }

    .reviews-desc {
        color: var(--neutral-text);
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        margin-bottom: 56px;
    }

    .reviews-card {
        width: 100%;
        height: 100%;
        margin-bottom: 36px;
    }

    .review-card-content {
        height: 100%;
    }
}
</style>