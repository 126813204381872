import axios from "axios"

const AuthService = {
    async register(username, email, password) {
        let data = new FormData()
        data.append("username", username)
        data.append("email", email)
        data.append("password", password)
        const response = await axios.post("/api/auth/register/", data)
        return response.data
    },
    async login(login, password) {
        let data = new FormData()
        data.append("login", login)
        data.append("password", password)
        const response = await axios.post("/api/auth/login/", data)
        return response.data
    }
}

export default AuthService
