<template>
    <div id="content">
        <section class="accountInfo">
            <div class="container">
                <div class="row">
                    <div class="accountInfo__title d-flex align-items-center">
                        <div class="prev__btn position-relative">
                            <div class="prev">
                                <router-link class="fc__warning" to="/">{{ this.$store.getters.content.BACK }}</router-link>
                            </div>
                        </div>
                        <h2>{{ this.$store.getters.content.SETTINGS_TITLE }}</h2>
                    </div>
                </div>
            </div>
        </section>
        <section class="settings">
            <div class="container">
                <div class="row">
                    <div class="settingsBlock d-flex justify-content-center align-items-center col-12">
                        <form @submit.prevent="updateCurrentUser" class="form__settings d-flex flex-column align-items-center">
                            <div class="form__field d-flex flex-column flex-xl-row align-items-xl-center">
                                <label class="set-lab">{{ this.$store.getters.content.SETTINGS_LOGIN }}</label>
                                <div class="auth-window__input-wrapper settings__field__wrapper">
                                    <primary-input type="text" required="true" :value="$store.state.currentUser.userName"
                                        disabled="" />
                                </div>
                            </div>
                            <div class="form__field d-flex flex-column flex-xl-row align-items-xl-center">
                                <label class="set-lab">{{ this.$store.getters.content.SETTINGS_CURRENCY }}</label>
                                <div class="auth-window__input-wrapper settings__field__wrapper">
                                    <select class="primary-input" type="text" required="true" :value="this.$store.state.currentCurrency" @change="changeCurrency">
                                        <option v-for="currency in this.$store.state.currencies" :value="currency.id">{{ currency.name[this.$store.state.currentLanguage] }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form__field d-flex flex-column flex-xl-row align-items-xl-center">
                                <label class="set-lab">Email</label>
                                <div class="auth-window__input-wrapper settings__field__wrapper">
                                    <primary-input type="email" v-model="email" :placeholder="this.$store.getters.content.SETTINGS_EMAIL_PLACEHOLDER"
                                        :isError="errors.email.length" @input="validateEmail" />
                                    <small class="auth-window__error-text" v-if="errors.email.length">{{
                                        errors.email.join("\n")
                                    }}</small>
                                </div>
                            </div>
                            <div class="form__field d-flex flex-column flex-xl-row align-items-xl-center">
                                <label class="set-lab">{{ this.$store.getters.content.SETTINGS_PASSWORD }}</label>
                                <div class="auth-window__input-wrapper settings__field__wrapper">
                                    <primary-input :type="isShowPassword ? 'text' : 'password'" class="password_field"
                                        :placeholder="this.$store.getters.content.SETTINGS_PASSWORD_PLACEHOLDER" v-model="password" :isError="errors.password.length"
                                        @input="validatePassword" />
                                    <small class="auth-window__error-text" v-if="errors.password.length">{{
                                        errors.password.join("\n")
                                    }}</small>
                                </div>
                                <div @click="isShowPassword = !isShowPassword" class="hide_password">
                                    <img src="@/assets/img/hidepassword.png">
                                </div>
                            </div>
                            <primary-button class="btn set_btn bg__btnColor fc__mainInvert align-self-center"
                                type="submit">{{ this.$store.getters.content.SETTINGS_BUTTON }}</primary-button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<style scoped>
.accountInfo {
    padding-top: 55px;
    padding-bottom: 18px;
}

.accountInfo__title {
    margin-bottom: 36px;
}

.accountInfo__description {
    border-radius: 16px;
    padding: 40px 115px 40px 24px;
}

.accountInfo__description__items {
    border-top: 1px solid white;
    border-image: linear-gradient(to right, #FFFFFF, #FA20BF, #6F3BF7, #000000) 1;
    margin-top: 24px;
    padding-top: 24px;
}

.accountInfo__description__txt ul li span,
.accountInfo__description__txt ul li span a,
.accountInfo__description__txt__change a {
    color: #FA20BF;
}

.accountInfo__description__txt ul li {
    position: relative;
}

.accountInfo__description__txt ul li span {
    word-break: break-all;
}

.accountInfo__description__txt__change li:before {
    content: "";
    position: absolute;
    top: 12px;
    left: -10px;
    display: block;
    width: 4px;
    height: 4px;
    background: #3B5FDB;
    border-radius: 100px;
}

.accountInfo {
    color: #fff;
}

.fc__warning {
    color: #FA20BF;
    text-decoration: none;
    margin-left: 8px;
}

input {
    border: none;
}

#content {
    min-height: calc(100vh - 198px);
}

.set_btn {
    width: 288px;
    height: 56px;
    border: none;
}

.settings {
    height: 70vh;
    color: #fff;
}

.prev {
    margin-left: 12px;
    margin-right: 22px;
    transform: translateY(-3px);
    text-decoration: none;
}

.prev a:before {
    margin-right: 10px;
    content: "";
    height: 100%;
    width: 38px;
    position: absolute;
    top: 1px;
    left: -20px;
    display: block;
    background: url("@/assets/img/arrow_back.svg") no-repeat;
}

.settingsBlock .btn {
    margin-top: 24px;
}

.admin-btn-block {
    margin-top: 10px;
}

.form__field textarea {
    height: 305px;
}

.form__field {
    position: relative;
}

.form__field:not(:last-child) {
    margin-bottom: 24px;
    margin-top: 24px;
}

.form__login .form__field {
    width: 287px;
}

.form__field label {
    width: 287px;
}

.bg__btnColor {
    background: linear-gradient(90deg, #FA20BF 0%, #6F3BF7 100%);
    ;
}

.fc__mainInvert {
    color: #FFFFFF;
}

.form__field input {
    height: 45px;
    width: 287px;
    padding-left: 12px;
    background: #091A24;
    border-radius: 4px;
    color: white;
}

.form__field>.hide_password {
    position: absolute;
    top: 9px;
    right: 15px;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .settingsBlock {
        height: auto;
        margin-top: 36px;
    }

    .form__settings {
        width: 100%;
    }

    .form__settings .btn {
        margin-top: 24px;
    }

    .settings {
        margin-bottom: 50px;
    }

    .set-lab {
        margin-bottom: 10px;
    }

    .form__field>.hide_password {
        position: absolute;
        top: 43px;
        right: 10px;
    }
}

@media (max-width: 600px) {
    .accountInfo__title {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
    }
}

.settings__field__wrapper {
    width: 100%;
}

.settings__field__wrapper>input {
    width: 100% !important;
}
</style>

<script>
import UserService from "@/services/UserService"

export default {
    data() {
        return {
            isShowPassword: false,
            email: "",
            password: "",
            errors: {
                email: [],
                password: []
            }
        }
    },
    created() {
        this.email = this.$store.state.currentUser.email
    },
    methods: {
        async updateCurrentUser() {
            if (!this.isValid()) {
                return
            }

            try {
                await UserService.updateCurrentUser(this.email, this.password)
                this.$notify({ text: this.$store.getters.content.SETTINGS_SUCCESS })
            }
            catch (e) {
                if (e.response.data?.errorCode == "Validation") {
                    this.errors.email = e.response.data.description.Email || []
                    this.errors.password = e.response.data.description.Password || []
                }
            }
        },
        isValid() {
            this.validateEmail()
            this.validatePassword()
            return !Object.entries(this.errors).some(([key, value]) => value.length)
        },
        validateEmail() {
            if (this.email.length === 0) {
                this.errors.email = [this.$store.getters.content.ERROR_REQUIRED_FIELD]
            }
            else if (!this.email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
                this.errors.email = [this.$store.getters.content.ERROR_INCORRECT_EMAIL]
            }
            else {
                this.errors.email = []
            }
        },
        validatePassword() {
            if (this.password.length === 0) {
                this.errors.password = [this.$store.getters.content.ERROR_REQUIRED_FIELD]
            }
            else if (this.password.length < 8) {
                this.errors.password = [this.$store.getters.content.ERROR_SHORT_PASSWORD]
            }
            else {
                this.errors.password = []
            }
        },
        changeCurrency(event) {
            this.$store.dispatch("setCurrentCurrency", event.target.value)
        }
    }
}
</script>