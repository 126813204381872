import axios from "axios"

const defaultResponse = JSON.parse(`
    {
        "disclaimer": "https://www.cbr-xml-daily.ru/#terms",
        "date": "2024-08-10",
        "timestamp": 1723237200,
        "base": "RUB",
        "rates": {
            "AUD": 0.0172401,
            "AZN": 0.0193199,
            "GBP": 0.008938,
            "AMD": 4.41199179,
            "BYN": 0.0355112,
            "BGN": 0.020336,
            "BRL": 0.063834,
            "HUF": 4.117530799,
            "VND": 275.7069125,
            "HKD": 0.08847365,
            "GEL": 0.0307618,
            "DKK": 0.0775867,
            "AED": 0.0417367,
            "USD": 0.0113646695,
            "EUR": 0.01050592,
            "EGP": 0.560042,
            "INR": 0.9534979,
            "IDR": 181.289147,
            "KZT": 5.422552,
            "CAD": 0.01561845977,
            "QAR": 0.0413674,
            "KGS": 0.9705439899,
            "CNY": 0.0840965,
            "MDL": 0.200746778,
            "NZD": 0.01887975,
            "NOK": 0.123551,
            "PLN": 0.04500855,
            "RON": 0.0518024668,
            "XDR": 0.0085158565,
            "SGD": 0.01505364,
            "TJS": 0.12033028,
            "THB": 0.400525,
            "TRY": 0.3804567,
            "TMT": 0.039776298,
            "UZS": 143.7855869,
            "UAH": 0.465868,
            "CZK": 0.262751,
            "SEK": 0.119526579,
            "CHF": 0.00983612,
            "RSD": 1.217439578,
            "ZAR": 0.208738,
            "KRW": 15.657097,
            "JPY": 1.676743897
        }
    }
`)

const CurrencyProvider = {
    async getCurrencies() {
        let response;
        try {
            response = (await axios.get("https://www.cbr-xml-daily.ru/latest.js")).data
        }
        catch {
            response = defaultResponse;
        }
        return [
            {
                id: "RUB",
                name: {
                    "RU": "Рубль",
                    "EN": "Ruble",
                    "UA": "Рубель"
                },
                rate: 1,
                symbol: "₽"
            },
            {
                id: "USD",
                name: {
                    "RU": "Доллар",
                    "EN": "Dollar",
                    "UA": "Долар"
                },
                rate: response.rates["USD"],
                symbol: "$"
            },
            {
                id: "EUR",
                name: {
                    "RU": "Евро",
                    "EN": "Euro",
                    "UA": "Євро"
                },
                rate: response.rates["EUR"],
                symbol: "€"
            },
            {
                id: "KZT",
                name: {
                    "RU": "Тенге",
                    "EN": "Tenge",
                    "UA": "Тенге"
                },
                rate: response.rates["KZT"],
                symbol: "₸",
            },
            {
                id: "UAH",
                name: {
                    "RU": "Гривна",
                    "EN": "Hryvnia",
                    "UA": "Гривня"
                },
                rate: response.rates["UAH"],
                symbol: "₴",
            },
        ]
    }
}

export default CurrencyProvider
