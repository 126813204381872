import axios from "axios"
import store from "../store"

const BalanceService = {
    async getInputRequests({ page = 1, perPage = 9 } = {}) {
        const response = await axios.get("/api/inputRequests", { headers: { Authorization: `Bearer ${store.state.accessToken}` }, params: arguments[0] })
        return response.data
    },
    async getOutputRequests({ page = 1, perPage = 9 } = {}) {
        const response = await axios.get("/api/outputRequests", { headers: { Authorization: `Bearer ${store.state.accessToken}` }, params: arguments[0] })
        return response.data
    },
    async createTransaction(receiverId, amount) {
        let data = new FormData()
        data.append("receiverId", receiverId)
        data.append("amount", amount)

        await axios.post("/api/transactions", data, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    },
    async createOutputRequest({ paymentSystemId = null, paymentDetails = null, amount = null } = {}) {
        let data = new FormData()

        data.append("paymentSystemId", paymentSystemId)
        data.append("paymentDetails", paymentDetails)
        data.append("amount", amount)

        await axios.post("/api/outputRequests", data, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    }
}

export default BalanceService
