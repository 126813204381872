<template>
    <span>
        <span v-if="value != null">{{ currencyValue }}</span>
        <span v-if="isShowSymbol">{{ currentCurrency.symbol }}</span>
    </span>
</template>

<script>
export default {
    computed: {
        currentCurrency() {
            return this.$store.state.currencies.find(e => e.id === this.$store.state.currentCurrency)
        },
        currencyValue() {
            let currencyValue = (this.value * this.currentCurrency.rate).toFixed(2).replace(/[,.]?0+$/, '')

            if (this.isSplit) {
                currencyValue = Number(currencyValue).toLocaleString("en-EN")
            }

            return currencyValue
        }
    },
    props: {
        value: Number | null,
        isShowSymbol: {
            type: Boolean,
            default: true
        },
        isSplit: {
            type: Boolean,
            default: false
        },
    }
}
</script>
