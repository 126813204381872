import axios from "axios"
import store from "../store"

const AdminDealService = {
    async getDeals({ searchQuery = null, status = null, perPage = 30, page = 1 } = {}) {
        const response = await axios.get("/api/admin/deals", { headers: { Authorization: `Bearer ${store.state.accessToken}` }, params: arguments[0] })
        return response.data
    },
    async getDeal(id) {
        const response = await axios.get(`/api/admin/deals/${id}`, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
        return response.data
    },
    async close(id) {
        await axios.post(`/api/admin/deals/${id}/close`, null, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    },
    async cancel(id) {
        await axios.post(`/api/admin/deals/${id}/cancel`, null, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    },
    async unarbitrage(id) {
        await axios.post(`/api/admin/deals/${id}/unarbitrage`, null, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    }
}

export default AdminDealService
