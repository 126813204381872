<template>
    <section class="container forwhom d-flex">
        <div class="forwhom-block-img mx-auto">
            <img src="@/assets/img/laptop.png">
        </div>
        <div class="forwhom-text mx-auto">
            <h1 class="forwhom-title text-center">{{ this.$store.getters.content.HEADER_FORWHOM }}</h1>
            <p class="forwhom-desc text-center">{{ this.$store.getters.content.FORWHOM_DESCRIPTION }}</p>
        </div>
    </section>
    <section class="dignity container">
        <div class="dignity-block">
            <div class="dignity-content">
                <div class="dignity-flex">
                    <img src="@/assets/img/attach_money.png" alt="">
                    <p class="dignity-text dignity-text-attach-money">{{ this.$store.getters.content.FORWHOM_CARD_1 }}</p>
                </div>
                <div class="dignity-flex">
                    <img src="@/assets/img/flag.png" alt="">
                    <p class="dignity-text dignity-text-flag">{{ this.$store.getters.content.FORWHOM_CARD_2 }}</p>
                </div>
                <div class="dignity-flex">
                    <img src="@/assets/img/shield.png" alt="">
                    <p class="dignity-text dignity-text-security">{{ this.$store.getters.content.FORWHOM_CARD_3 }}</p>
                </div>
            </div>
        </div>
    </section>
    <section class="steps container">
        <div class="steps-block">
            <div class="steps-content">
                <h1 class="steps-title">{{ this.$store.getters.content.FORWHOM_STAGES_TITLE }}</h1>
                <p class="steps-desc">
                    {{ this.$store.getters.content.FORWHOM_STAGES_DESCRIPTION }}
                </p>
                <div class="steps-line"></div>
                <div class="steps-advantages">
                    <h1 class="steps-ul-title">{{ this.$store.getters.content.FORWHOM_STAGES_1 }}</h1>
                    <ul class="steps-advantages-list steps-list">
                        <li class="steps-list-item">
                            <p class="steps-list-item-text">
                                {{ this.$store.getters.content.FORWHOM_STAGES_1_1 }}
                            </p>
                        </li>
                        <li class="steps-list-item">
                            <p class="steps-list-item-text">
                                {{ this.$store.getters.content.FORWHOM_STAGES_1_2 }}
                            </p>
                        </li>
                        <li class="steps-list-item">
                            <p class="steps-list-item-text">
                                {{ this.$store.getters.content.FORWHOM_STAGES_1_3 }}
                            </p>
                        </li>
                        <li class="steps-list-item">
                            <p class="steps-list-item-text">
                                {{ this.$store.getters.content.FORWHOM_STAGES_1_4 }}
                            </p>
                        </li>
                    </ul>
                </div>
                <div class="steps-line"></div>
                <div class="steps-usefulness">
                    <h1 class="steps-ul-title">{{ this.$store.getters.content.FORWHOM_STAGES_2 }}</h1>
                    <ul class="steps-usefulness-list steps-list">
                        <li class="steps-list-item">
                            <p class="steps-list-item-text">
                                {{ this.$store.getters.content.FORWHOM_STAGES_2_1 }}
                            </p>
                        </li>
                        <li class="steps-list-item">
                            <p class="steps-list-item-text">
                                {{ this.$store.getters.content.FORWHOM_STAGES_2_2 }}
                            </p>
                        </li>
                        <li class="steps-list-item">
                            <p class="steps-list-item-text">
                                {{ this.$store.getters.content.FORWHOM_STAGES_2_3 }}
                            </p>
                        </li>
                        <li class="steps-list-item">
                            <p class="steps-list-item-text">
                                {{ this.$store.getters.content.FORWHOM_STAGES_2_4 }}
                            </p>
                        </li>
                    </ul>
                </div>
                <div class="steps-line"></div>
                <div class="steps-warning">
                    <h1 class="steps-ul-title">{{ this.$store.getters.content.FORWHOM_STAGES_3 }}</h1>
                    <ul class="steps-warning-list steps-list">
                        <li class="steps-list-item">
                            <p class="steps-list-item-text">
                                {{ this.$store.getters.content.FORWHOM_STAGES_3_1 }}
                            </p>
                        </li>
                        <li class="steps-list-item">
                            <p class="steps-list-item-text">
                                {{ this.$store.getters.content.FORWHOM_STAGES_3_2 }}
                            </p>
                        </li>
                        <li class="steps-list-item">
                            <p class="steps-list-item-text">
                                {{ this.$store.getters.content.FORWHOM_STAGES_3_3 }}
                            </p>
                        </li>
                        <li class="steps-list-item">
                            <p class="steps-list-item-text">
                                {{ this.$store.getters.content.FORWHOM_STAGES_3_4 }}
                            </p>
                        </li>
                    </ul>
                </div>
                <div class="steps-line"></div>
                <div class="steps-transfer">
                    <h1 class="steps-ul-title">{{ this.$store.getters.content.FORWHOM_STAGES_4 }}</h1>
                    <p class="steps-list-item-text">
                        {{ this.$store.getters.content.FORWHOM_STAGES_4_1 }}
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section class="protection container">
        <div class="protect">
            <p class="protect-warning protect-text">
                {{ this.$store.getters.content.FORWHOM_WARNING }}
            </p>
        </div>
    </section>
</template>

<style scoped>
.forwhom {
    margin-top: 30px;
    flex-direction: column;
}

.forwhom-block-img {
    margin-bottom: 36px;
}

.forwhom-text {
    color: var(--neutral-white);
}

.forwhom-title {
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    margin-bottom: 24px;
}

.forwhom-desc {
    width: 600px;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 56px;
}

.dignity-block {
    margin: auto;
    padding: 1px;
    border-radius: 8px;
    margin-bottom: 56px;
    width: 808px;
    background: linear-gradient(to right, #FFFFFF, #FA20BF, #6F3BF7, #000000 100%);
}

.dignity-content {
    width: 808px;
    height: 96px;
    padding: 24px;
    background: var(--neutral-background-90);
    border-radius: 8px;
    position: relative;
    color: var(--neutral-text);
    display: flex;
}

.dignity-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.08px;
}

.dignity-flex {
    margin-right: 24px;
}

.dignity-flex:nth-child(3) {
    margin-right: 0px;

}

.dignity-flex {
    display: flex;
    align-items: center;
}

.dignity-flex img {
    margin-right: 9px;
}

.steps-block {
    margin: auto;
    padding: 1px;
    margin-bottom: 56px;
    width: 808px;
    background: linear-gradient(to right, #FFFFFF, #FA20BF, #6F3BF7, #000000 100%);
}

.steps-content {
    width: 808px;
    height: 100%;
    padding: 24px;
    position: relative;
    background: var(--neutral-background-90);
    color: var(--neutral-text);
}

.steps-title {
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    margin-bottom: 36px;
    text-align: center;
}

.steps-desc {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
}

.steps-line {
    background: linear-gradient(to right, #FFFFFF, #FA20BF, #6F3BF7, #000000 100%);
    height: 1px;
    width: 100%;
    margin: 36px 0;
}

.steps-ul-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 16px;
}

.steps-list {
    padding-left: 24px;
}

.steps-list-item-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.steps-usefulness-list .steps-list-item-text,
.steps-warning-list .steps-list-item-text {
    margin-bottom: 20px;
}

.protection {
    color: var(--neutral-white);
    margin-bottom: 100px;
}

.protect {
    background: var(--neutral-background-90);
    width: 808px;
    padding: 10px 24px;
    margin: auto;
    border: 1px solid var(--red);
    position: relative;
    border-radius: 8px;
}

.protect::before {
    content: '';
    display: inline-block;
    background: var(--red);
    width: 8px;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 140px;
    position: absolute;
    border: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.protect-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.protect-warning {
    margin-bottom: 20px;
}

@media (max-width:992px) {
    .protect {
        width: 100%;
    }
}

@media (max-width:1000px) {
    .dignity-content {
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    .dignity-block {
        width: 100%;
    }

    .dignity-flex {
        margin-right: 0;
        margin-bottom: 24px;
    }

    .dignity-flex:nth-child(3) {
        margin-left: 3px;
        margin-bottom: 0px;
    }

    .steps-content {
        width: 100%;
    }

    .steps-block {
        width: 100%;
    }
}

@media (max-width:800px) {
    .forwhom {
        flex-direction: column-reverse;
        margin-top: 64px;
    }

    .forwhom-block-img img {
        width: 301px;
        height: 301px;
    }

    .forwhom-desc {
        margin-bottom: 2px;
        font-size: 16px;
        line-height: 24px;
        color: var(--neutral-text);
        width: 100%;
    }

    .forwhom-title {
        margin-bottom: 36px;
        font-size: 28px;
        color: var(--neutral-text);
        line-height: 36px;
    }

    .dignity-block {
        margin-bottom: 72px;
    }

    .protect-text {
        font-size: 14px;
        line-height: 20px;
    }

    .protect {
        margin-bottom: 30px;
    }
}

@media (max-width:776px) {
    .steps-ul-title {
        font-size: 18px;
        line-height: 24px;
    }

    .steps-title {
        font-size: 20px;
        line-height: 28px;
    }

    .steps-desc {
        font-size: 16px;
        line-height: 24px;
    }
}
</style>