<template>
    <tr class="defaul-card">
        <td class="defaul-card__property">
            <router-link :to="`/deals/${deal.id}`" class="defaul-card__detail-link__wrapper">
                <linear-stroke-button class="defaul-card__detail-link">{{ this.$store.getters.content.DEAL_BUTTON_GO }}</linear-stroke-button>
            </router-link>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">ID</h3>
            <p class="defaul-card__property__value">{{ deal.id }}</p>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">{{ this.$store.getters.content.CREATE_TITLE }}</h3>
            <p class="defaul-card__property__value">{{ deal.title.slice(0, 22) }}</p>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">{{ this.$store.getters.content.DEPOSIT_AMOUNT }}</h3>
            <p class="defaul-card__property__value"><CurrentCurrency :value="deal.amount" /></p>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">{{ this.$store.getters.content.STATUS }}</h3>
            <p class="defaul-card__property__value" :style="{ color: status.color }">{{ status.name[this.$store.state.currentLanguage] }}</p>
        </td>
    </tr>
</template>

<script>
import StatusProvider from '@/services/StatusProvider'
import CurrentCurrency from '../../currency/CurrentCurrency.vue'

export default {
    props: {
        deal: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            status: {}
        }
    },
    created() {
        this.status = StatusProvider.getStatusById(this.deal.status)
    },
    components: {
        CurrentCurrency
    }
}
</script>