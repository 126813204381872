<template>
    <tr class="defaul-card">
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">ID</h3>
            <p class="defaul-card__property__value">{{ transaction.id }}</p>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">Дата</h3>
            <p class="defaul-card__property__value">{{ new Date(transaction.createdAt).toLocaleString("ru-RU") }}</p>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">От кого</h3>
            <p class="defaul-card__property__value">{{ transaction.sender.userName }}</p>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">Кому</h3>
            <p class="defaul-card__property__value">{{ transaction.receiver.userName }}</p>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">Сумма</h3>
            <p class="defaul-card__property__value"><CurrentCurrency :value="transaction.amount" /></p>
        </td>
    </tr>
</template>

<script>
import CurrentCurrency from '../../currency/CurrentCurrency.vue'

export default {
    props: {
        transaction: {
            required: true
        }
    },
    components: {
        CurrentCurrency
    }
}
</script>
