<template>
    <container>
        <form @submit.prevent="createDeal" class="create-deal">
            <div class="create-deal__property">
                <p class="create-deal__property__name">
                    {{ this.$store.getters.content.CREATE_TITLE }}
                </p>
                <div class="auth-window__input-wrapper">
                    <primary-input @input="validateTitle" maxlength="250" v-model="title" :isError="!!errors.title.length"
                        :placeholder="this.$store.getters.content.CREATE_TITLE_PLACEHOLDER" />
                    <small class="auth-window__error-text" v-if="errors.title.length">{{ errors.title.join("\n")
                    }}</small>
                </div>
            </div>
            <div class="create-deal__property">
                <p class="create-deal__property__name">
                    {{ this.$store.getters.content.CREATE_ROLE }}
                </p>
                <div class="auth-window__input-wrapper">
                    <select v-model="creatorRole" class="primary-input">
                        <option value="1">{{ this.$store.getters.content.CREATE_ROLE_1 }}</option>
                        <option value="2">{{ this.$store.getters.content.CREATE_ROLE_2 }}</option>
                    </select>
                </div>
            </div>
            <div class="create-deal__property">
                <p class="create-deal__property__name">
                    {{ creatorRole == 1 ? this.$store.getters.content.CREATE_ROLE_2 : this.$store.getters.content.CREATE_ROLE_1 }}
                </p>
                <div class="auth-window__input-wrapper">
                    <primary-input @input="validateInvitedUserName" maxlength="30" v-model="invitedUserName"
                        :isError="!!errors.invitedUserName.length" :placeholder="this.$store.getters.content.CREATE_LOGIN_PLACEHOLDER" />
                    <small class="auth-window__error-text" v-if="errors.invitedUserName.length">{{
                        errors.invitedUserName.join("\n")
                    }}</small>
                </div>
            </div>
            <div class="create-deal__property">
                <p class="create-deal__property__name">
                    {{ this.$store.getters.content.CREATE_DESCRIPTION }}
                </p>
                <div class="auth-window__input-wrapper">
                    <primary-textarea class="create-deal__description" @input="validateDescription" v-model="description"
                        :isError="!!errors.description.length" :placeholder="this.$store.getters.content.CREATE_DESCRIPTION_PLACEHOLDER">
                    </primary-textarea>
                    <small class="auth-window__error-text" v-if="errors.description.length">{{ errors.description.join("\n")
                    }}</small>
                </div>
            </div>
            <div class="create-deal__property">
                <p class="create-deal__property__name">
                    {{ this.$store.getters.content.CREATE_SUM }}
                </p>
                <div class="auth-window__input-wrapper">
                    <primary-input type="number" @input="validateAmount" v-model="amount" :isError="!!errors.amount.length"
                        :placeholder="this.$store.getters.content.CREATE_SUM_PLACEHOLDER" step="0.01" />
                    <small class="auth-window__error-text" v-if="errors.amount.length">{{ errors.amount.join("\n")
                    }}</small>
                </div>
                <p class="create-deal__amount-icon"><CurrentCurrency /></p>
            </div>
            <div class="create-deal__property">
                <p class="create-deal__property__name">
                    {{ this.$store.getters.content.CREATE_DURATION }}
                </p>
                <div class="auth-window__input-wrapper">
                    <primary-input type="number" @input="validateDurationDays" v-model="durationDays"
                        :isError="!!errors.durationDays.length" :placeholder="this.$store.getters.content.CREATE_DURATION_PLACEHOLDER" />
                    <small class="auth-window__error-text" v-if="errors.durationDays.length">{{
                        errors.durationDays.join("\n")
                    }}</small>
                </div>
            </div>
            <div class="create-deal__amount-calculate primary-linear-stroke" v-if="amount">
                <h2 class="create-deal__amount-calculate__title">{{ this.$store.getters.content.CREATE_CALCULATOR_TITLE }}</h2>
                <div class="create-deal__amount-calculate__property">
                    <p class="create-deal__amount-calculate__property__text">{{ this.$store.getters.content.CREATE_CALCULATOR_1 }}</p>
                    <p class="create-deal__amount-calculate__property__text"><CurrentCurrency :value="Number(rubAmount)" /></p>
                </div>
                <div class="create-deal__amount-calculate__property">
                    <p class="create-deal__amount-calculate__property__text">{{ this.$store.getters.content.CREATE_CALCULATOR_2 }}</p>
                    <p class="create-deal__amount-calculate__property__text"><CurrentCurrency :value="Math.ceil((rubAmount) * 0.05)" /></p>
                </div>
                <div class="create-deal__amount-calculate__property">
                    <p class="create-deal__amount-calculate__property__text">{{ this.$store.getters.content.CREATE_CALCULATOR_3 }}</p>
                    <p class="create-deal__amount-calculate__property__text"><CurrentCurrency :value="Number(rubAmount) + Math.ceil((rubAmount) * 0.05)" /></p>
                </div>
            </div>
            <primary-button type="sumbit" class="create-deal__button">{{ this.$store.getters.content.CREATE_BUTTON }}</primary-button>
        </form>
    </container>
</template>

<style scoped>
.create-deal {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: auto;
    margin-top: 50px;
    width: 704px;
}

.create-deal__property {
    display: flex;
}

.create-deal__property__name {
    margin-top: 12px;
    color: var(--neutral-white);
    font-size: 18px;
    font-weight: 400;
    min-width: 222px;
    margin-right: 90px;
}

.auth-window__input-wrapper {
    width: 100%;
}

.create-deal__description {
    height: 380px;
}

.create-deal__amount-icon {
    color: var(--neutral-text);
    font-size: 28px;
    font-weight: 600;
    line-height: 45px;
    opacity: 0.6;
    margin-left: 16px;
}

@media (max-width: 950px) {
    .create-deal {
        width: 100%;
    }

    .create-deal__property {
        flex-direction: column;
        gap: 12px;
    }

    .create-deal__property__name {
        font-size: 14px;
    }

    .create-deal__amount-icon {
        display: none;
    }
}

.create-deal__amount-calculate {
    width: 100%;
    margin: auto;
    margin-top: 56px;
    border-radius: 16px;
    padding: 24px;
    background: var(--neutral-background-90);
}

.create-deal__amount-calculate__title {
    color: var(--neutral-white);
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 8px;
}

.create-deal__amount-calculate__property {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid transparent;
    border-image: var(--primary-linear-stroke) 1;
}

.create-deal__amount-calculate__property:last-child {
    border: none;
    padding-bottom: 0;
}

.create-deal__amount-calculate__property__text {
    color: var(--neutral-white);
    font-size: 16px;
    font-weight: 400;
}

.create-deal__button {
    display: block;
    margin: auto;
    margin-top: 36px;
}

@media (max-width: 950px) {
    .create-deal__button {
        width: 100%;
    }
}

.create-deal__error {
    font-size: 24px;
    font-family: 500;
    color: var(--neutral-white);
    margin-top: 35px;
    text-align: center;
}
</style>

<script>
import DealService from "@/services/DealService.js"
import UserService from "@/services/UserService.js"
import CurrentCurrency from '../components/currency/CurrentCurrency.vue'

export default {
    data() {
        return {
            title: "",
            description: "",
            amount: "",
            durationDays: "",
            creatorRole: 1,
            invitedUserName: "",
            errors: {
                title: [],
                description: [],
                amount: [],
                durationDays: [],
                creatorRole: [],
                invitedUserName: []
            },
            isInProccess: false
        }
    },
    created() {
        if (this.$store.state.currentUser.roles.includes("BlockedBalance")) {
            this.$router.push("/blockedBalance")
        }
    },
    methods: {
        async createDeal() {
            if (this.isInProccess) {
                return
            }

            if (!this.isValid()) {
                return
            }

            this.isInProccess = true
            let invitedUserId
            try {
                const userResponse = await UserService.getUserByUserName(this.invitedUserName)
                invitedUserId = userResponse.id
            }
            catch (e) {
                if (e.response.status === 404) {
                    this.errors.invitedUserName = [this.$store.getters.content.ERROR_LOGIN_NOT_FOUND]
                    window.scrollBy(0, -1000)
                    this.isInProccess = false
                    return
                }
            }
            try {
                const dealId = await DealService.create(
                    {
                        title: this.title,
                        description: this.description,
                        amount: this.rubAmount,
                        durationDays: this.durationDays,
                        creatorRole: this.creatorRole,
                        invitedUserId: invitedUserId
                    }
                )
                this.$router.push(`/deals/${dealId}`)
            }
            catch (e) {
                console.error(e)
                if (e.response.data?.errorCode === "Validation") {
                    this.errors.invitedUserName = e.response.data.description?.InvitedUserId?.map(
                        e => {
                            if (this.$store.state.currentLanguage == "EN") {
                                return e
                                    .replace("Вы не можете создать сделку с самим собой", "You can't create a deal with yourself")
                                    .replace("Пользователь заблокирован", "User is blocked")
                            }
                            if (this.$store.state.currentLanguage == "UA") {
                                return e
                                    .replace("Вы не можете создать сделку с самим собой", "Ви не можете створити угоду з самим собою")
                                    .replace("Пользователь заблокирован", "Користувач заблокований")
                            }
                            return e
                        }
                    ) || []
                    this.errors.amount = e.response.data.description.Amount || []
                }
            }
            this.isInProccess = false
        },
        isValid() {
            this.validateTitle()
            this.validateInvitedUserName()
            this.validateDescription()
            this.validateAmount()
            this.validateDurationDays()
            return !Object.entries(this.errors).some(([key, value]) => value.length)
        },
        validateTitle() {
            if (this.title.length === 0) {
                this.errors.title = [this.$store.getters.content.ERROR_REQUIRED_FIELD]
            }
            else {
                this.errors.title = []
            }
        },
        validateInvitedUserName() {
            if (this.invitedUserName.length === 0) {
                this.errors.invitedUserName = [this.$store.getters.content.ERROR_REQUIRED_FIELD]
            }
            else {
                this.errors.invitedUserName = []
            }
        },
        validateDescription() {
            if (this.description.length === 0) {
                this.errors.description = [this.$store.getters.content.ERROR_REQUIRED_FIELD]
            }
            else {
                this.errors.description = []
            }
        },
        validateAmount() {
            if (this.$store.state.currentCurrency == "RUB") {
                if (!/^[0-9]+$/.test(this.amount)) {
                    this.errors.amount = [this.$store.getters.content.ERROR_INCORRECT_NUMBER]
                    return
                }   
            }
            else {
                let isValidDecimalLength = !(this.amount.split(".").length == 2) || this.amount.split(".")[1].length <= 2
                if (isNaN(this.amount) || isNaN(parseFloat(this.amount)) || !isValidDecimalLength) {
                    this.errors.amount = [this.$store.getters.content.ERROR_INCORRECT_NUMBER]
                    return
                }
            }
            if (this.rubAmount < 1500) {
                this.errors.amount = [this.$store.getters.content.ERROR_MIN_AMOUNT + (1500 * this.currentCurrency.rate).toFixed(2) + this.currentCurrency.symbol]
            }
            else {
                this.errors.amount = []
            }
        },
        validateDurationDays() {
            if (!/^[0-9]+$/.test(this.durationDays)) {
                this.errors.durationDays = [this.$store.getters.content.ERROR_INCORRECT_NUMBER]
            }
            else if (this.durationDays < 1) {
                this.errors.durationDays = [this.$store.getters.content.ERROR_MIN_DURATION + "1"]
            }
            else {
                this.errors.durationDays = []
            }
        }
    },
    computed: {
        currentCurrency() {
            return this.$store.state.currencies.find(e => e.id === this.$store.state.currentCurrency)
        },
        rubAmount() {
            return Math.floor(this.amount / this.currentCurrency.rate)
        }
    },
    components: {
        CurrentCurrency
    }
}
</script>