<template>
    <container class="wrapper">
        <div class="promo">
            <section class="promo__description">
                <h1 class="title">{{ this.$store.getters.content.PROMO_TITLE }}</h1>
                <p class="description">
                    {{ this.$store.getters.content.PROMO_DESCRIPTION }}
                </p>
                <router-link v-if="this.$store.state.isAuth" to="/deals/create">
                    <primary-button class="button">
                        {{ this.$store.getters.content.PROMO_BUTTON }}
                    </primary-button>
                </router-link>
                <primary-button v-else class="button" @click="this.$store.dispatch('showRegisterWindow')">
                    {{ this.$store.getters.content.PROMO_BUTTON_AUTH }}
                </primary-button>
            </section>
            <section class="promo__image">
                <img src="@/assets/img/promo.png">
            </section>
        </div>
    </container>
</template>

<style scoped>
.promo {
    margin-top: 100px;
    display: flex;
    gap: 60px;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 64px;
    font-weight: 600;
    color: var(--neutral-text);
    max-width: 561px;
    line-height: 76px;
}

.description {
    font-size: 18px;
    font-weight: 400;
    color: var(--neutral-text);
    max-width: 523px;
    line-height: 28px;
    margin-top: 36px;
}

.button {
    margin-top: 36px;
}

.promo__description {
    position: relative;
}


.promo__image {
    min-width: 613px;
    height: 613px;
    position: relative;
}

.promo__image>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 1300px) {
    .title {
        font-size: 44px;
        line-height: 58px;
        max-width: 423px;

    }

    .description {
        max-width: 450px;
    }

    .promo__image {
        min-width: 400px;
        height: 400px;
    }
}

@media (max-width: 1000px) {
    .title {
        line-height: 44px;
        font-size: 36px;
        max-width: 323px;

    }

    .description {
        max-width: 350px;
    }

    .promo__image {
        min-width: 350px;
        height: 350px;
    }
}

@media (max-width: 850px) {
    .promo {
        flex-wrap: wrap;
    }

    .title {
        max-width: 100%;
    }

    .description {
        max-width: 100%;
    }

    .button {
        width: 100%;
    }

    .promo__image {
        width: 100%;
        min-width: unset;
        height: auto;
    }
}

@media (max-width: 500px) {
    .title {
        font-size: 28px;
        line-height: 32px;
    }

    .description {
        font-size: 16px;
        line-height: 24px;
    }
}
</style>