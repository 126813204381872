<template>
    <div class="auth-window__header">
        <h2 class="auth-window__title">{{ this.$store.getters.content.HEADER_LOGIN }}</h2>
        <img @click="this.$store.dispatch('hideAuthWindow')" class="auth-window__close" src="@/assets/img/close.svg">
    </div>
    <form class="auth-window__form" @submit.prevent="login">
        <div class="auth-window__form__inputs-wraper">
            <div class="auth-window__input-wrapper">
                <primary-input @input="clearErrors" maxlength="30" v-model="username" :isError="!!errors.username.length"
                    :placeholder="this.$store.getters.content.REGISTER_LOGIN_PLACEHOLDER" />
                <small class="auth-window__error-text" v-if="errors.username.length">{{ errors.username.join("\n")
                }}</small>
            </div>
            <div class="auth-window__input-wrapper">
                <primary-input @input="clearErrors" type="password" v-model="password" :isError="!!errors.password.length"
                    :placeholder="this.$store.getters.content.REGISTER_PASSWORD_PLACEHOLDER" />
                <small class="auth-window__error-text" v-if="errors.password.length">{{ errors.password.join("\n")
                }}</small>
            </div>
        </div>
        <primary-button class="auth-window__button">{{ this.$store.getters.content.HEADER_LOGIN }}</primary-button>
        <p class="auth-window__change-window-text">{{ this.$store.getters.content.LOGIN_NOT_HAS_ACCOUNT }} <span class="primary-text"
                @click="this.$store.dispatch('showRegisterWindow')">{{ this.$store.getters.content.HEADER_SIGNUP }}</span></p>
    </form>
</template>

<script>
import AuthService from "@/services/AuthService.js"

export default {
    data() {
        return {
            username: "",
            password: "",
            errors: {
                username: [],
                password: []
            }
        }
    },
    methods: {
        async login() {
            try {
                this.username = this.username.replace(/ /g, '');
                const response = await AuthService.login(this.username, this.password)
                localStorage.accessToken = response.accessToken.token
                this.$store.dispatch("updateCurrentUser")
                this.$store.dispatch("hideAuthWindow")
            }
            catch (e) {
                if (e.response.data?.errorCode === "InvalidLogin") {
                    this.errors.username = [this.$store.getters.content.ERROR_LOGIN]
                    this.errors.password = [this.$store.getters.content.ERROR_LOGIN]
                }
            }
        },
        clearErrors() {
            this.errors = {
                username: [],
                password: []
            }
        }
    }
}
</script>