<template>
    <tr class="defaul-card">
        <td class="defaul-card__property">
            <router-link :to="`/admin/users/${user.id}`" class="defaul-card__detail-link__wrapper">
                <linear-stroke-button class="defaul-card__detail-link">Перейти</linear-stroke-button>
            </router-link>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">Логин</h3>
            <p class="defaul-card__property__value">{{ user.userName }}</p>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">E-mail</h3>
            <p class="defaul-card__property__value">{{ user.email }}</p>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">Баланс</h3>
            <p class="defaul-card__property__value"><CurrentCurrency :value="user.balance" /></p>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">Последний онлайн</h3>
            <p class="defaul-card__property__value">{{ lastOnlineMessage }}</p>
        </td>
    </tr>
</template>

<script>
import WordService from "@/services/WordService.js"
import CurrentCurrency from '../../currency/CurrentCurrency.vue'

export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    computed: {
        lastOnlineMessage() {
            return WordService.getLastOnlineMessage(this.user.lastOnline)
        }
    },
    components: {
        CurrentCurrency
    }
}
</script>
