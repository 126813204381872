<template>
    <input :value="modelValue" @input="onUpdateValue" class="primary-input" :class="{ error: isError }">
</template>

<script>
export default {
    name: "primary-input",
    props: {
        isError: {
            default: false
        },
        modelValue: {
            type: String,
            default: null
        }
    },
    methods: {
        onUpdateValue(event) {
            this.$emit("update:modelValue", event.target.value)
        }
    }
}
</script>