<template>
    <div class="schemeBlockMain__item bg__secondary col-12 col-xl-12" :class="{ activeItem: isActive }"
        @click="isActive = !isActive">
        <div class="block__open d-flex align-items-center justify-content-between col-12 mb-1">
            <div class="d-flex align-items-center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_188_9909)">
                        <path
                            d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z"
                            :fill="isActive ? '#D8D6EC' : '#6F3BF7'" />
                    </g>
                    <defs>
                        <clipPath id="clip0_188_9909">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <h4>{{ title }}</h4>
            </div>
            <div class="position-relative">
                <div class="minus"></div>
                <div class="plus position-absolute"></div>
            </div>
        </div>
        <p class="item__open">
            {{ description }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String
        },
        description: {
            type: String
        },
        isActive: {
            type: Boolean,
            default: false
        }
    },
    methods: {

    }
}
</script>