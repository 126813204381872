<template>
    <container class="output">
        <router-link to="/" class="link-back">
            {{ this.$store.getters.content.BACK }}
        </router-link>
        <section class="account-info">
            <div class="account-info-desc d-flex justify-content-between">
                <h3>{{ this.$store.getters.content.HEADER_BALANCE }}: <CurrentCurrency :value="this.$store.state.currentUser.balance" /> <span v-if="this.$store.state.currentUser.frozenBalance" :title="this.$store.getters.content.FROZEN_TOOLTIP"> (<CurrentCurrency :value="this.$store.state.currentUser.frozenBalance" /> {{ this.$store.getters.content.FROZEN }})</span></h3>
                <primary-button @click="$router.push('/balance/add')" class="account-info-btn">{{ this.$store.getters.content.BALANCE_BUTTON }}</primary-button>
            </div>
            <div class="line"></div>
            <div class="account-info-desc-items">
                <div class="account-info-desc-item">
                    <p v-html="this.$store.getters.content.BALANCE_DESCRIPTION_1"></p>
                </div>
                <div class="account-info-desc-item">
                    <p v-html="this.$store.getters.content.BALANCE_DESCRIPTION_2"></p>
                </div>
                <div class="account-info-desc-item account-info-desc-item-warn"
                    v-if="this.$store.state.currentUser.roles.includes('BlockedBalance')">
                    <p>
                        {{ this.$store.state.currentUser.userName }}, {{ this.$store.getters.content.BALANCE_WARNING_DESCRIPTION }}
                        <router-link to="/notifications" class="warn-link">{{ this.$store.getters.content.BALANCE_WARNING_LINK }}</router-link>
                    </p>
                </div>
            </div>
        </section>
        <section class="history">
            <h1 class="history-title">{{ this.$store.getters.content.BALANCE_HISTORY_TITLE }}</h1>
            <div class="history-btns ">
                <div class="history-btn" @click="state = 1" :class="{ 'history-btn-active': state === 1 }">{{ this.$store.getters.content.BALANCE_HISTORY_TAB_1 }}</div>
                <div class="history-btn" @click="state = 2" :class="{ 'history-btn-active': state === 2 }">{{ this.$store.getters.content.BALANCE_HISTORY_TAB_2 }}</div>
                <div class="history-btn" @click="state = 3" :class="{ 'history-btn-active': state === 3 }">{{ this.$store.getters.content.BALANCE_HISTORY_TAB_3 }}</div>
            </div>
        </section>
        <InputTab v-if="state === 1" />
        <OutputTab v-if="state === 2" />
        <TransactionTab v-if="state === 3" />
    </container>
</template>

<script>
import InputTab from "@/components/partials/balance/InputTab.vue"
import OutputTab from "@/components/partials/balance/OutputTab.vue"
import TransactionTab from "@/components/partials/balance/TransactionTab.vue"
import CurrentCurrency from '../components/currency/CurrentCurrency.vue'

export default {
    data() {
        return {
            state: 1,
        }
    },
    components: { InputTab, TransactionTab, OutputTab, CurrentCurrency }
}
</script>