const StatusProvider = {
    getStatuses() {
        return [
            {
                id: 1,
                name: {
                    "RU": "На согласовании",
                    "EN": "Pending confirmation",
                    "UA": "Очікує підтвердження"
                },
                color: "rgba(216, 214, 236, 0.5)",
                barWidth: "25%",
                icon: require("@/assets/img/statuses/notconfirmed.svg")
            },
            {
                id: 2,
                name: {
                    "RU": "Ожидается оплата",
                    "EN": "Pending payment",
                    "UA": "Очікується оплата"
                },
                color: "#D8D6EC",
                barWidth: "50%",
                icon: require("@/assets/img/statuses/confirmed.svg")
            },
            {
                id: 3,
                name: {
                    "RU": "Оплачена",
                    "EN": "Paid",
                    "UA": "«Оплачена»"
                },      
                color: "#9DFF61",
                barWidth: "75%",
                icon: require("@/assets/img/statuses/paid.svg")
            },
            {
                id: 4,
                name: {
                    "RU": "Арбитраж",
                    "EN": "Arbitrage",
                    "UA": "«Арбітраж»"
                },
                color: "#FA20BF",
                barWidth: "0%",
                icon: require("@/assets/img/statuses/arbitrage.svg")
            },
            {
                id: 5,
                name: {
                    "RU": "Завершена",
                    "EN": "Completed",
                    "UA": "Завершена"
                },
                color: "#FFF",
                barWidth: "100%"
            },
            {
                id: 6,
                name: {
                    "RU": "Отменена",
                    "EN": "Canceled",
                    "UA": "Скасована"
                },
                color: "#F00",
                barWidth: "0%"
            }
        ]
    },
    getStatusById(id) {
        return this.getStatuses().find(e => e.id == id)
    }
}

export default StatusProvider
