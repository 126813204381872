<template>
    <container>
        <div class="not-found-card">
            <h2 class="not-found-card__title">{{ this.$store.getters.content.NOTFOUND_TITLE }}</h2>
            <p class="not-found-card__description">{{ this.$store.getters.content.NOTFOUND_DESCRIPTION }}</p>
            <router-link class="not-found-card__link" to="/">
                <primary-button>{{ this.$store.getters.content.NOTFOUND_BUTTON }}</primary-button>
            </router-link>
        </div>
    </container>
</template>

<style scoped>
.not-found-card {
    background-color: var(--neutral-background-90);
    display: flex;
    justify-content: center;
    gap: 35px;
    flex-direction: column;
    border-radius: 16px;
    margin-top: 75px;
    padding: 25px;
}

.not-found-card__title {
    font-size: 24px;
    font-weight: 600;
    color: var(--neutral-white);
    text-align: center;
}

.not-found-card__link {
    width: fit-content;
    display: block;
    margin: auto;
}

.not-found-card__description {
    font-size: 16px;
    font-weight: 400;
    color: var(--neutral-text);
    text-align: center;
}
</style>
