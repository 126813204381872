import axios from "axios"
import store from '../store'

const DealService = {
    async getDeals({ perPage = 30, page = 1 } = {}) {
        const response = await axios.get("/api/deals", { headers: { Authorization: `Bearer ${store.state.accessToken}` }, params: arguments[0] })
        return response.data
    },
    async getDeal(id) {
        const response = await axios.get(`/api/deals/${id}`, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
        return response.data
    },
    async confirm(id) {
        await axios.post(`/api/deals/${id}/confirm`, null, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    },
    async pay(id) {
        await axios.post(`/api/deals/${id}/pay`, null, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    },
    async arbitrage(id) {
        await axios.post(`/api/deals/${id}/arbitrage`, null, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    },
    async unarbitrage(id) {
        await axios.post(`/api/deals/${id}/unarbitrage`, null, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    },
    async close(id) {
        await axios.post(`/api/deals/${id}/close`, null, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    },
    async create({ title = null, description = null, creatorRole = null, invitedUserId = null, amount = null, durationDays = null } = {}) {
        var data = new FormData();

        for (var key in arguments[0]) {
            data.append(key, arguments[0][key]);
        }

        const response = await axios.post("/api/deals", data, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
        return response.data
    }
}

export default DealService
