<template>
    <div class="deal-chat primary-linear-stroke">
        <div class="deal-chat__header">
            <h3 class="deal-chat__header__title">{{ this.$store.getters.content.CHAT_TITLE }}</h3>
            <p class="deal-chat__header__last-online">{{ lastOnlineMessage }}</p>
        </div>
        <div class="deal-chat__main" ref="messages">
            <template v-for="[key, values] in groupedMessages">
                <div class="deal-chat__message-date">{{ key }}</div>
                <DealChatMessage v-for="message in values" :message="message" />
            </template>
        </div>
        <form @submit.prevent="sendMessage" class="deal-chat__footer">
            <input v-model="messageText" :placeholder="this.$store.getters.content.CHAT_PLACEHOLDER"
                class="deal-chat__footer__input" type="text">
            <button class="deal-chat__footer__button">
                <img src="@/assets/img/send.svg" class="deal-chat__footer__button__image">
            </button>
        </form>
    </div>
</template>

<style scoped>
.deal-chat {
    width: 1016px;
    background-color: var(--neutral-background-90);
    border-radius: 16px;
    margin: auto;
    margin-top: 35px;
}

@media (max-width: 1200px) {
    .deal-chat {
        width: 100%;
    }
}

.deal-chat__header {
    height: 68px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid var(--gray);
}

.deal-chat__header__title {
    font-size: 14px;
    font-weight: 500;
    color: var(--neutral-white);
    text-align: center;
}

.deal-chat__header__last-online {
    font-size: 12px;
    font-weight: 400;
    color: var(--gray);
    text-align: center;
    margin-top: 4px;
}

.deal-chat__main {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 430px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-top: auto;
    border-bottom: 1px solid var(--gray);
}

.deal-chat__main::-webkit-scrollbar {
    display: none;
}

.deal-chat__message-date {
    font-size: 12px;
    font-weight: 400;
    color: var(--neutral-white);
    padding: 3px 10px;
    border-radius: 26px;
    background-color: var(--primary-violet-main);
    width: fit-content;
    margin: 16px auto;
}

.deal-chat__footer {
    height: 52px;
    display: flex;
    align-items: center;
}

.deal-chat__footer__input {
    height: 100%;
    width: 100%;
    padding: 0 25px;
    background: none;
    border: none;
    color: var(--neutral-text);
    font-size: 16px;
    font-weight: 400;
}

.deal-chat__footer__input::placeholder {
    color: var(--gray);
}

.deal-chat__footer__input:focus {
    outline: none;
}

.deal-chat__footer__button {
    border: none;
    background: none;
    width: 52px;
    height: 100%;
    cursor: pointer;
}
</style>

<script>
import WordService from "@/services/WordService.js"
import DealMessageService from "@/services/DealMessageService.js"
import AdminDealMessageService from "@/services/AdminDealMessageService"
import DealChatMessage from "./DealChatMessage.vue"
import { HubConnectionBuilder, HttpTransportType } from "@aspnet/signalr"

export default {
    emits: ["newSystemMessage"],
    props: {
        deal: {
            type: Object,
            required: true
        }
    },
    components: { DealChatMessage },
    data() {
        return {
            messages: [],
            connection: null,
            messageText: "",
            audio: new Audio(require("@/assets/sounds/chat_sound.mp3"))
        }
    },
    async mounted() {
        await this.setConnection()
        await this.addMessages()
        await this.$nextTick()
        this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight
    },
    async unmounted() {
        await this.connection.stop()
    },
    methods: {
        async setConnection() {
            this.connection = new HubConnectionBuilder().withUrl(
                `/ws/deals/${this.deal.id}/chat?accessToken=${this.$store.state.accessToken}`,
                HttpTransportType.WebSockets
            ).build()
            await this.connection.start()
            this.connection.on("NewMessage", this.addMessage)
            this.connection.on("NewSystemMessage", this.addSystemMessage)
        },
        addMessage(message) {
            this.messages.push(message)
            if (this.$store.state.currentUser.id != message.author.id) {
                this.alertMessage()
            }
            this.$nextTick(() => {
                try {
                    this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight
                }
                catch (e) { console.log(e) }
            })
        },
        addSystemMessage(message) {
            this.messages.push(message)
            this.alertMessage()
            this.$emit("newSystemMessage")
            this.$nextTick(() => {
                try {
                    this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight
                }
                catch (e) { console.log(e) }
            })
        },
        alertMessage() {
            try {
                this.audio.pause()
                this.audio.currentTime = 0
                this.audio.play()
            }
            catch (e) { }
        },
        async addMessages() {
            let response;
            if (this.$store.state.currentUser.roles.includes("Admin")) {
                response = await AdminDealMessageService.getDealMessages(this.deal.id, { page: this.page, perPage: 500 })
            }
            else {
                response = await DealMessageService.getDealMessages(this.deal.id, { page: this.page, perPage: 500 })
            }
            this.messages.unshift(...response.items.reverse())
        },
        async sendMessage() {
            this.connection.invoke("SendMessage", this.messageText)
            this.messageText = ""
        },
        groupBy(list, keyGetter) {
            const map = new Map();
            list.forEach((item) => {
                const key = keyGetter(item);
                const collection = map.get(key);
                if (!collection) {
                    map.set(key, [item]);
                } else {
                    collection.push(item);
                }
            });
            return map;
        }
    },
    computed: {
        groupedMessages() {
            return this.groupBy(this.localizedMessages, message => {
                let createdDate = new Date(message.createdAt)
                return `${createdDate.getDate()}/${createdDate.getMonth() + 1}/${createdDate.getFullYear()}`
            })
        },
        localizedMessages() {
            return this.messages.map(
                message => {
                    if (typeof message.text === "object") {
                        return message
                    }
                    message.text = {
                        "RU": message.text,
                        "EN": message.text,
                        "UA": message.text
                    }
                    message.author.userName = {
                        "RU": message.author.userName,
                        "EN": message.author.userName,
                        "UA": message.author.userName
                    }
                    return message
                }
            ).map(message => {
                if (message.author.userName["RU"] == "Система") {
                    message.author.userName["EN"] = "System ⚙️"
                    message.text["EN"] = message.text["EN"]
                        .replace(
                            "Оба участника подтвердили своё участие в сделке ✅.",
                            "Both participants have confirmed their participation in the deal ✅."
                        )
                        .replace(
                            "Сделка оплачена ✅. Теперь исполнитель должен исполнить свои обязательства в рамках этой суммы. Для передачи информации и общения используйте данный чат.",
                            "The deal has been paid ✅. Now the contractor must fulfill his obligations within this amount. Use this chat for information and communication."
                        )
                        .replace(
                            "открыл арбитраж ❗ Опишите претензии в этом чате, администратор примет решение по сделке в ближайшее время.",
                            "create arbitrage ❗ Describe the claim in this chat, admin will decide on the deal soon."
                        )
                        .replace(
                            "Арбитраж закрыт 🟢. Сделка переходит в состояние оплаченной, исполнитель должен исполнить свои обязательства в рамках этой суммы.",
                            "The arbitrage is closed 🟢. The deal passes to the paid status, the contractor must fulfill his obligations within this amount."
                        )
                        .replace(
                            "Сделка завершена ✅. Средства переведены на счёт исполнителя.",
                            "The deal is completed ✅. The funds have been transferred to the contractor's account."
                        )
                        .replace(
                            "Сделка отменена ❌. Средства возвращены на счёт заказчика.",
                            "Deal canceled ❌. The funds are returned to the customer's account."
                        )
                        .replace(
                            "Пользователь ",
                            "User "
                        )

                    message.author.userName["UA"] = "Система ⚙️"
                    message.text["UA"] = message.text["UA"]
                        .replace(
                            "Оба участника подтвердили своё участие в сделке ✅.",
                            "Обидва учасники підтвердили свою участь в Угоді ✅."
                        )
                        .replace(
                            "Сделка оплачена ✅. Теперь исполнитель должен исполнить свои обязательства в рамках этой суммы. Для передачи информации и общения используйте данный чат.",
                            "Угода оплачена ✅. Тепер виконавець повинен виконати свої зобов'язання в рамках цієї суми. Для передачі інформації та спілкування використовуйте даний чат."
                        )
                        .replace(
                            "открыл арбитраж ❗ Опишите претензии в этом чате, администратор примет решение по сделке в ближайшее время.",
                            "відкрив арбітраж ❗ опишіть претензії в цьому чаті, адміністратор прийме рішення по угоді найближчим часом."
                        )
                        .replace(
                            "Арбитраж закрыт 🟢. Сделка переходит в состояние оплаченной, исполнитель должен исполнить свои обязательства в рамках этой суммы.",
                            "Арбітраж закрито. Угода переходить в стан оплаченої, виконавець повинен виконати свої зобов'язання в рамках цієї суми."
                        )
                        .replace(
                            "Сделка завершена ✅. Средства переведены на счёт исполнителя.",
                            "Угода завершена ✅. Кошти переведені на рахунок Виконавця."
                        )
                        .replace(
                            "Сделка отменена ❌. Средства возвращены на счёт заказчика.",
                            "Угода скасована ❌. Кошти повернуті на рахунок замовника."
                        )
                        .replace(
                            "Пользователь ",
                            ""
                        )
                }
                return message
            })
        },
        lastOnlineMessage() {
            let lastOnline = this.$store.state.currentUser.id == this.deal.customer.id ? this.deal.performer.lastOnline : this.deal.customer.lastOnline
            return this.$store.getters.content.LAST_ONLINE_CONTACT + WordService.getLastOnlineMessage(lastOnline)
        }
    }
}
</script>