import Container from "./Container.vue"
import Logo from "./Logo.vue"
import PrimaryButton from "./PrimaryButton.vue"
import WindowWrapper from "./WindowWraper.vue"
import PrimaryInput from "./PrimaryInput.vue"
import PrimaryTextarea from "./PrimaryTextarea.vue"
import PrimaryPagination from "./PrimaryPagination.vue"
import LinearStrokeButton from "./LinearStrokeButton.vue"

export default [
    Container,
    Logo,
    PrimaryButton,
    WindowWrapper,
    PrimaryInput,
    PrimaryTextarea,
    PrimaryPagination,
    LinearStrokeButton
]
