<template>
    <DealDetail @confirm="confirm" @pay="pay" @arbitrage="arbitrage" @unarbitrage="unarbitrage" @close="close"
        @adminCancel="adminCancel" @adminClose="adminClose" @adminUnarbitrage="adminUnarbitrage"
        @newSystemMessage="onNewSystemMessage" v-if="deal" :deal="deal" />
</template>

<script>
import DealDetail from "@/components/partials/deal/DealDetail.vue"
import AdminDealService from "@/services/AdminDealService.js"
import DealService from "@/services/DealService.js"

export default {
    components: {
        DealDetail
    },
    data() {
        return {
            deal: null
        }
    },
    created() {
        if (this.$store.state.currentUser.roles.includes("BlockedBalance")) {
            this.$router.push("/blockedBalance")
            return
        }
        this.setDeal()
    },
    methods: {
        async confirm() {
            await DealService.confirm(this.deal.id)
        },
        async pay() {
            try {
                await DealService.pay(this.deal.id)
            }
            catch (e) {
                if (e.response.data?.errorCode == "NotEnoughtBalance") {
                    this.$notify({
                        title: this.$store.getters.content.ERROR_NOT_ENOUGH_BALANCE,
                    })
                }
                if (e.response.status === 409) {
                    this.$notify({
                        title: this.$store.getters.content.ERROR_BLOCKEDBALANCE,
                    })
                }
            }
        },
        async arbitrage() {
            await DealService.arbitrage(this.deal.id)
        },
        async unarbitrage() {
            await DealService.unarbitrage(this.deal.id)
        },
        async close() {
            await DealService.close(this.deal.id)
        },
        async adminClose() {
            await AdminDealService.close(this.deal.id)
        },
        async adminCancel() {
            await AdminDealService.cancel(this.deal.id)
        },
        async adminUnarbitrage() {
            await AdminDealService.unarbitrage(this.deal.id)
        },
        async onNewSystemMessage() {
            this.$store.dispatch("updateCurrentUser")
            await this.setDeal()
        },
        async setDeal() {
            try {
                if (this.$store.state.currentUser.roles.includes("Admin")) {
                    this.deal = await AdminDealService.getDeal(this.$route.params.id)
                }
                else {
                    this.deal = await DealService.getDeal(this.$route.params.id)
                }
            }
            catch (e) {
                if (e.response.status === 404) {
                    this.$router.push("/notfound")
                }
                else if (e.response.status === 409) {
                    this.$router.push("/")
                }
            }
        },
    }
}
</script>