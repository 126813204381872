<template>
    <window-wrapper :isShow="isShow" @hideWindow="$emit('hideWindow')">
        <div @click.stop class="output-proccess primary-linear-stroke">
            <div class="auth-window__header">
                <h2 class="auth-window__title">Вывод пользователя {{ outputRequest.user.userName }} - <CurrentCurrency :value="outputRequest.amount" /></h2>
                <img @click="$emit('hideWindow')" class="auth-window__close" src="@/assets/img/close.svg">
            </div>
            <div class="output-proccess__main">
                <label class="output-proccess__label">
                    <primary-textarea v-model="message" class="output-proccess__message"
                        placeholder="Сообщение (опционально)"></primary-textarea>
                </label>
                <label class="output-proccess__label">
                    <input v-model="isBlockBalance" type="checkbox" class="output-proccess__checkbox">
                    Заблокировать счёт
                </label>
            </div>
            <div class="output-proccess__footer">
                <button @click="approve" class="output-proccess__button btn-success">Одобрить</button>
                <button @click="cancel" class="output-proccess__button btn-danger">Отклонить</button>
            </div>
        </div>
    </window-wrapper>
</template>

<style scoped>
.output-proccess {
    margin: auto;
    width: 1000px;
    padding: 24px 35px;
    background: var(--neutral-background-100);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.output-proccess__footer {
    display: flex;
    justify-content: center;
    gap: 25px;
}

@media (max-width: 600px) {
    .output-proccess__footer {
        flex-direction: column;
    }
}

.output-proccess__message {
    height: 100px;
    margin-top: 5px;
}

.output-proccess__main {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.output-proccess__label {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--neutral-text);
    font-size: 16px;
    font-weight: 500;
}

.output-proccess__checkbox {
    height: 20px;
    width: 20px;
    background: var(--primary-pink);
    accent-color: var(--primary-pink);
    border: none;
    outline: none;
}

.output-proccess__button {
    width: 100%;
    height: 45px;
    border-radius: 8px;
    border: none;
}
</style>

<script>
import AdminOutputService from '@/services/AdminOutputService'
import AdminUserService from '@/services/AdminUserService'
import CurrentCurrency from '../currency/CurrentCurrency.vue'

export default {
    emits: ["hideWindow", "requestProccessed"],
    props: {
        isShow: {
            default: false
        },
        outputRequest: {
            required: true
        }
    },
    data() {
        return {
            message: "",
            isBlockBalance: false
        }
    },
    methods: {
        async approve() {
            await AdminOutputService.approve(this.outputRequest.id)
            await this.handleAdditionalFields()
            this.clearProccess()
        },
        async cancel() {
            await AdminOutputService.cancel(this.outputRequest.id)
            await this.handleAdditionalFields()
            this.clearProccess()
        },
        async handleAdditionalFields() {
            if (this.isBlockBalance) {
                await AdminUserService.blockUserBalance(this.outputRequest.user.id)
            }
            if (this.message) {
                await AdminUserService.sendNotification(this.outputRequest.user.id, this.message)
            }
        },
        clearProccess() {
            this.message = ""
            this.isBlockBalance = false
            this.$emit("requestProccessed")
            this.$emit("hideWindow")
        }
    },
    components: {
        CurrentCurrency
    }
}
</script>
