<template>
    <router-link class="logo" to="/">
        <span class="logo__letter">{{ $store.state.appName[0] }}</span>{{ $store.state.appName.slice(1) }}
    </router-link>
</template>

<style scoped>
.logo {
    font-size: 28px;
    font-weight: 600;
    color: var(--neutral-white);
    text-decoration: none;
}

.logo::first-letter {
    background: var(--primary-gradient);
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>

<script>
export default {
    name: "logo"
}
</script>
