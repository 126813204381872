import axios from "axios"
import store from '../store'

const DealMessageService = {
    async getDealMessages(id, { page = null, perPage = null } = {}) {
        const response = await axios.get(`/api/deals/${id}/messages`, { headers: { Authorization: `Bearer ${store.state.accessToken}` }, params: arguments[1] })
        return response.data
    }
}

export default DealMessageService
