import axios from "axios"
import store from '../store'

const AdminUserService = {
    async getUsers({ searchQuery = null, sortBy = null, isDesc = null, perPage = 30, page = 1 } = {}) {
        const response = await axios.get("/api/admin/users", { headers: { Authorization: `Bearer ${store.state.accessToken}` }, params: arguments[0] })
        return response.data
    },
    async getUser(id) {
        const response = await axios.get(`/api/admin/users/${id}`, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
        return response.data
    },
    async blockUser(id) {
        await axios.post(`/api/admin/users/${id}/block`, null, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    },
    async unblockUser(id) {
        await axios.post(`/api/admin/users/${id}/unblock`, null, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    },
    async deleteUser(id) {
        await axios.delete(`/api/admin/users/${id}`, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    },
    async blockUserBalance(id) {
        await axios.post(`/api/admin/users/${id}/blockBalance`, null, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    },
    async unblockUserBalance(id) {
        await axios.post(`/api/admin/users/${id}/unblockBalance`, null, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    },
    async sendNotification(id, text) {
        let data = new FormData()
        data.append("userId", id)
        data.append("text", text)
        await axios.post(`/api/admin/notifications`, data, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    },
    async changePassword(id, password) {
        let data = new FormData()
        data.append("password", password)
        await axios.put(`/api/admin/users/${id}/password`, data, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    },
    async addInputRequest(id, paymentSystemId, amount) {
        let data = new FormData()
        data.append("userId", id)
        data.append("paymentSystemId", paymentSystemId)
        data.append("amount", amount)
        await axios.post(`/api/admin/inputRequests`, data, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    }
}

export default AdminUserService
