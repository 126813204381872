<template>
    <form class="user-admin__form primary-linear-stroke">
        <h2 class="user-admin__form__title">Блокировка</h2>
        <div class="user-admin__form__content" v-if="!user.roles.includes('Admin')">
            <button @click="blockUser" type="button" class="user-admin__form__button btn-danger"
                v-if="!user.roles.includes('Blocked')">Забанить пользователя</button>
            <button @click="unblockUser" type="button" class="user-admin__form__button btn-success"
                v-if="user.roles.includes('Blocked')">Разбанить пользователя</button>
            <button @click="blockUserBalance" type="button" class="user-admin__form__button btn-danger"
                v-if="!user.roles.includes('BlockedBalance')">Заблокировать счёт</button>
            <button @click="unblockUserBalance" type="button" class="user-admin__form__button btn-success"
                v-if="user.roles.includes('BlockedBalance')">Разблокировать счёт</button>
            <button @click="deleteUser" type="button" class="user-admin__form__button btn-danger">Удалить пользователя</button>
        </div>
    </form>
</template>

<script>
import AdminUserService from "@/services/AdminUserService.js"

export default {
    emits: ["updateUser", "deleteUser"],
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    methods: {
        async blockUser() {
            await AdminUserService.blockUser(this.user.id)
            await this.$emit("updateUser")
        },
        async unblockUser() {
            await AdminUserService.unblockUser(this.user.id)
            await this.$emit("updateUser")
        },
        async blockUserBalance() {
            await AdminUserService.blockUserBalance(this.user.id)
            await this.$emit("updateUser")
        },
        async unblockUserBalance() {
            await AdminUserService.unblockUserBalance(this.user.id)
            await this.$emit("updateUser")
        },
        async deleteUser() {
            await AdminUserService.deleteUser(this.user.id)
            await this.$emit("deleteUser")
        }
    }
}
</script>