import { createStore } from 'vuex'
import UserService from '@/services/UserService'
import CurrencyProvider from '@/services/CurrencyProvider'
import LanguageProvider from "@/services/LanguageProvider"

const content = LanguageProvider.getContent(process.env.VUE_APP_NAME, process.env.VUE_APP_DOMAIN)

export default createStore({
    state: {
        isShowMobileMenu: false,
        isShowAuthWindow: false,
        authWindowState: 1,
        isAuth: false,
        currentUser: null,
        accessToken: null,
        isReady: false,
        currencies: [],
        currentCurrency: null,
        currentLanguage: null,
        appName: process.env.VUE_APP_NAME,
        appDomain: process.env.VUE_APP_DOMAIN,
        chaportId: process.env.VUE_APP_CHAPORT,
    },
    getters: {
        content(state) {
            return content[state.currentLanguage]
        }
    },
    mutations: {
        setIsShowMobileMenu(action, payload) {
            action.isShowMobileMenu = payload
        },
        setIsShowAuthWindow(action, payload) {
            action.isShowAuthWindow = payload
        },
        setAuthWindowState(action, payload) {
            action.authWindowState = payload
        },
        setCurrentUser(action, payload) {
            action.currentUser = payload
        },
        setIsAuth(action, payload) {
            action.isAuth = payload
        },
        setAccessToken(action, payload) {
            action.accessToken = payload
        },
        setIsReady(action, payload) {
            action.isReady = payload
        },
        setCurrencies(action, payload) {
            action.currencies = payload
        },
        setCurrentCurrency(action, payload) {
            action.currentCurrency = payload
        },
        setCurrentLanguage(action, payload) {
            action.currentLanguage = payload
        }
    },
    actions: {
        showMobileMenu: (context) => {
            context.commit("setIsShowMobileMenu", true)
        },
        hideMobileMenu: (context) => {
            context.commit("setIsShowMobileMenu", false)
        },
        showLoginWindow: (context) => {
            context.commit("setIsShowAuthWindow", true)
            context.commit("setAuthWindowState", 1)
        },
        showRegisterWindow: (context) => {
            context.commit("setIsShowAuthWindow", true)
            context.commit("setAuthWindowState", 2)
        },
        hideAuthWindow: (context) => {
            context.commit("setIsShowAuthWindow", false)
        },
        updateCurrentUser: async (context) => {
            let token = localStorage.accessToken

            if (token) {
                try {
                    let currentUser = await UserService.getCurrentUser(token)
                    context.commit("setIsAuth", true)
                    context.commit("setCurrentUser", currentUser)
                    context.commit("setAccessToken", token)
                    return
                }
                catch (e) {
                    console.log(e)
                }
            }

            context.commit("setIsAuth", false)
            context.commit("setCurrentUser", null)
            context.commit("setAccessToken", null)
        },
        updateCurrencies: async (context) => {
            let currentCurrency = localStorage.currentCurrency

            if (!currentCurrency) {
                localStorage.currentCurrency = "USD"
                currentCurrency = "USD"
            }

            context.commit("setCurrentCurrency", currentCurrency)
            context.commit("setCurrencies", await CurrencyProvider.getCurrencies())
        },
        setCurrentCurrency: (context, payload) => {
            localStorage.currentCurrency = payload
            context.commit("setCurrentCurrency", payload)
        },
        updateLanguage: async (context) => {
            let currentLanguage = localStorage.currentLanguage;

            if (!currentLanguage) {
                currentLanguage = await LanguageProvider.getLanguage()
                localStorage.currentLanguage = currentLanguage
            }

            context.commit("setCurrentLanguage", currentLanguage)
        },
        changeCurrentLanguage: (context) => {
            const languages = ["EN", "RU", "UA"]
            let newIndex = languages.findIndex(e => e == context.state.currentLanguage) + 1
            if (newIndex >= languages.length)
            {
                newIndex = 0
            }
            let newLanguage = languages[newIndex]
            localStorage.currentLanguage = newLanguage
            context.commit("setCurrentLanguage", newLanguage)
        },
        ready: (context) => {
            context.commit("setIsReady", true)
        }
    },
    modules: {
    }
})
