<template>
    <section class="certificates container">
        <h1 class="certificates-title">{{ this.$store.getters.content.HEADER_SERTIFICATES }}</h1>
        <p class="certificates-desc">
            {{ this.$store.getters.content.SERTIFICATES_DESCRIPTION }}
        </p>
        <div class="certificates-cards">
            <div class="certificates-card-block">
                <div class="certificates-card-content">
                    <div class="certificates-card-img">
                        <img src="@/assets/img/dog_bone.png" alt="">
                    </div>
                    <h1 class="certificates-card-title">SSL</h1>
                    <div class="certificates-line"></div>
                    <p class="certificates-card-desc">
                        {{ this.$store.getters.content.SERTIFICATES_1 }}
                    </p>
                </div>
            </div>
            <div class="certificates-card-block">
                <div class="certificates-card-content">
                    <div class="certificates-card-img">
                        <img src="@/assets/img/dog_bone.png" alt="">
                    </div>
                    <h1 class="certificates-card-title">Extended Validation Certificate</h1>
                    <div class="certificates-line"></div>
                    <p class="certificates-card-desc">
                        {{ this.$store.getters.content.SERTIFICATES_2 }}
                    </p>
                </div>
            </div>
            <div class="certificates-card-block">
                <div class="certificates-card-content">
                    <div class="certificates-card-img">
                        <img src="@/assets/img/dog_bone.png" alt="">
                    </div>
                    <h1 class="certificates-card-title">PCI DSS</h1>
                    <div class="certificates-line"></div>
                    <p class="certificates-card-desc">
                        {{ this.$store.getters.content.SERTIFICATES_3 }}
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section class="protection container">
        <div class="protect">
            <p class="protect-warning protect-text">
                {{ this.$store.getters.content.SERTIFICATES_WARNING }}
            </p>
        </div>
    </section>
</template>

<style scoped>
.certificates {
    margin-top: 100px;
}

.certificates-title {
    color: #FFF;
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
}

.certificates-desc {
    width: 600px;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 46px;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

.certificates-card-block {
    margin: auto;
    width: 600px;
    padding: 1px;
    background: linear-gradient(to right, #FFFFFF, #FA20BF, #6F3BF7, #000000 100%);
    border-radius: 8px;
    margin-bottom: 46px;
}

.certificates-card-content {
    width: 600px;
    height: 100%;
    border-radius: 8px;
    padding: 24px 36px;
    background: var(--neutral-background-90);
}

.certificates-card-title {
    color: var(--neutral-text);
    text-align: center;
    font-size: 28px;
    margin-top: 8px;
    font-weight: 600;
    line-height: 36px;
}

.certificates-card-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.certificates-line {
    background: linear-gradient(to right, #FFFFFF, #FA20BF, #6F3BF7, #000000 100%);
    height: 1px;
    width: 100%;
    margin: 16px 0;
}

.certificates-card-desc {
    margin: auto;
    color: var(--neutral-text);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.08px;
}

.protection {
    color: var(--neutral-white);
    margin-bottom: 100px;
}

.protect {
    background: var(--neutral-background-90);
    width: 600px;
    padding: 10px 24px;
    margin: auto;
    border: 1px solid var(--red);
    position: relative;
    border-radius: 8px;
}

.protect::before {
    content: '';
    display: inline-block;
    background: var(--red);
    width: 8px;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 140px;
    position: absolute;
    border: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.protect-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.protect-warning {
    margin-bottom: 20px;
}

@media (max-width:770px) {
    .certificates-card-block {
        width: 100%;
    }

    .certificates-card-content {
        width: 100%;
    }

    .certificates-desc {
        width: 100%;
    }

    .protect {
        width: 100%;
    }

    .protection {
        margin-bottom: 56px;
        margin-top: 48px;
    }

    .certificates-card-block {
        margin-bottom: 24px;
    }
}

@media (max-width:550px) {
    .certificates-title {
        color: var(--neutral-text);
        font-size: 28px;
        line-height: 36px
    }

    .certificates-desc {
        color: var(--neutral-text);
        font-size: 16px;
        line-height: 24px;
    }

    .certificates-card-title {
        font-size: 33px;
        line-height: normal;
    }
}</style>

