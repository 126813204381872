<template>
    <section class="history-table my-table" v-if="requests.length">
        <template v-for="(request, index) in requests" :key="request.id">
            <div class="table-item">
                <div class="table-id">
                    <p class="table-title">ID</p>
                    <p class="table-desc">{{ request.id }}</p>
                </div>
                <div class="table-date">
                    <p class="table-title">{{ this.$store.getters.content.DEPOSIT_DATE }}</p>
                    <p class="table-desc">{{ new Date(request.createdAt).toLocaleString("ru-RU") }}</p>
                </div>
                <div class="table-type">
                    <p class="table-title">{{ this.$store.getters.content.DEPOSIT_TYPE }}</p>
                    <p class="table-desc">{{ request.paymentSystem.name }}</p>
                </div>
                <div class="table-number">
                    <p class="table-title">{{ this.$store.getters.content.DEPOSIT_NUMBER_2 }}</p>
                    <p class="table-desc">{{ request.number }}</p>
                </div>
                <div class="table-summ">
                    <p class="table-title">{{ this.$store.getters.content.DEPOSIT_AMOUNT }}</p>
                    <p class="table-desc"><CurrentCurrency :value="request.amount" /></p>
                </div>
                <div class="table-status">
                    <p class="table-title">{{ this.$store.getters.content.DEPOSIT_STATUS }}</p>
                    <p class="table-desc status-true">{{ this.$store.getters.content.OUTPUT_STATUS_3 }}</p>
                </div>
            </div>
            <div class="line" v-if="index != (requests.length - 1)"></div>
        </template>
    </section>
    <primary-pagination v-if="requests.length" class="input__pagination" v-model="page" :count="requestsCount" :perPage="perPage" @update:modelValue="setRequests" />
</template>

<script>
import BalanceService from "@/services/BalanceService.js"
import CurrentCurrency from '../../currency/CurrentCurrency.vue'

export default {
    data() {
        return {
            requests: [],
            requestsCount: 0,
            page: 1,
            perPage: 9
        }
    },
    async created() {
        await this.setRequests()
    },
    methods: {
        async setRequests() {
            const response = await BalanceService.getInputRequests({ page: this.page, perPage: this.perPage })
            this.requests = response.items
            this.requestsCount = response.count
        }
    },
    components: {
        CurrentCurrency
    }
}
</script>