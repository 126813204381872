<template>
    <section class="howitwork container">
        <h1 class="howitwork-title text-center">{{ this.$store.getters.content.HOWITWORK_TITLE }}</h1>
        <p class="howitwork-desc">{{ this.$store.getters.content.HOWITWORK_DESCRIPTION }}</p>
        <div class="howitwork-cards d-flex align-items-center flex-wrap">
            <div class="card-block">
                <div class="howitwork-card">
                    <div class="img-center">
                        <img class="d-block" src="@/assets/img/messages.png" alt="">
                    </div>
                    <h1 class="howitwork-card-title text-center">{{ this.$store.getters.content.HOWITWORK_STEP_TITLE }} 1</h1>
                    <p class="howitwork-card-desc text-center">{{ this.$store.getters.content.HOWITWORK_STEP_1_DESCRIPTION }}</p>
                </div>
            </div>
            <div class="arrow-right">
                <img src="@/assets/img/Arrow6.png" alt="">
            </div>
            <div class="arrow-bottom">
                <img src="@/assets/img/Arrow7.png" alt="">
            </div>
            <div class="card-block">
                <div class="howitwork-card">
                    <div class="img-center">
                        <img class="d-block" src="@/assets/img/locked.png" alt="">
                    </div>
                    <h1 class="howitwork-card-title text-center">{{ this.$store.getters.content.HOWITWORK_STEP_TITLE }} 2</h1>
                    <p class="howitwork-card-desc text-center">{{ this.$store.getters.content.HOWITWORK_STEP_2_DESCRIPTION }}</p>
                </div>
            </div>
            <div class="arrow-bottom">
                <img src="@/assets/img/Arrow7.png" alt="">
            </div>
            <div class="arrow-right arrow-right-1200-d-none">
                <img src="@/assets/img/Arrow6.png" class="" alt="">
            </div>
            <div class="card-block">
                <div class="howitwork-card">
                    <div class="img-center">
                        <img class="d-block" src="@/assets/img/locked.png" alt="">
                    </div>
                    <h1 class="howitwork-card-title text-center">{{ this.$store.getters.content.HOWITWORK_STEP_TITLE }} 3</h1>
                    <p class="howitwork-card-desc text-center">{{ this.$store.getters.content.HOWITWORK_STEP_3_DESCRIPTION }}</p>
                </div>
            </div>
            <div class="arrow-bottom">
                <img src="@/assets/img/Arrow7.png" alt="">
            </div>
            <div class="arrow-right arrow-right-1200-d-block">
                <img src="@/assets/img/Arrow6.png" class="" alt="">
            </div>
            <div class="card-block">
                <div class="howitwork-card">
                    <div class="img-center">
                        <img class="d-block" src="@/assets/img/calendar.png" alt="">
                    </div>
                    <h1 class="howitwork-card-title text-center">{{ this.$store.getters.content.HOWITWORK_STEP_TITLE }} 4</h1>
                    <p class="howitwork-card-desc text-center">{{ this.$store.getters.content.HOWITWORK_STEP_4_DESCRIPTION }}</p>
                </div>
            </div>
            <div class="arrow-bottom">
                <img src="@/assets/img/Arrow7.png" alt="">
            </div>
            <div class="arrow-right arrow-right-1200-d-none">
                <img src="@/assets/img/Arrow6.png" class="" alt="">
            </div>
            <div class="card-block">
                <div class="howitwork-card">
                    <div class="img-center">
                        <img class="d-block" src="@/assets/img/paper.png" alt="">
                    </div>
                    <h1 class="howitwork-card-title text-center">{{ this.$store.getters.content.HOWITWORK_STEP_TITLE }} 5</h1>
                    <p class="howitwork-card-desc text-center">{{ this.$store.getters.content.HOWITWORK_STEP_5_DESCRIPTION }}</p>
                </div>
            </div>
            <div class="arrow-bottom">
                <img src="@/assets/img/Arrow7.png" alt="">
            </div>
            <div class="arrow-right">
                <img src="@/assets/img/Arrow6.png" alt="">
            </div>
            <div class="card-block">
                <div class="howitwork-card">
                    <div class="img-center">
                        <img class="d-block" src="@/assets/img/done.png" alt="">
                    </div>
                    <h1 class="howitwork-card-title text-center">{{ this.$store.getters.content.HOWITWORK_STEP_TITLE }} 6</h1>
                    <p class="howitwork-card-desc text-center">{{ this.$store.getters.content.HOWITWORK_STEP_6_DESCRIPTION }}</p>
                </div>
            </div>
        </div>
    </section>
    <section class="stages container">
        <h1 class="stages-title text-center">{{ this.$store.getters.content.HOWITWORK_STAGE_TITLE }}</h1>
        <p class="stages-desc">{{ this.$store.getters.content.HOWITWORK_STAGE_DESCRIPTION }}</p>
        <div class="statues">
            <div class="status-block">
                <div class="status confirmation">
                    <h1 class="status-title status-title-confirmation">{{ this.$store.getters.content.HOWITWORK_STAGE_1_TITLE }}</h1>
                    <p class="status-desc">{{ this.$store.getters.content.HOWITWORK_STAGE_1_DESCRIPTION }}</p>
                </div>
            </div>
            <div class="status-block">
                <div class="status waiting">
                    <h1 class="status-title status-title-waiting">{{ this.$store.getters.content.HOWITWORK_STAGE_2_TITLE }}</h1>
                    <p class="status-desc">{{ this.$store.getters.content.HOWITWORK_STAGE_2_DESCRIPTION }}</p>
                </div>
            </div>
            <div class="status-block">
                <div class="status paid">
                    <h1 class="status-title status-title-paid">{{ this.$store.getters.content.HOWITWORK_STAGE_3_TITLE }}</h1>
                    <p class="status-desc">{{ this.$store.getters.content.HOWITWORK_STAGE_3_DESCRIPTION }}</p>
                </div>
            </div>
            <div class="status-block">
                <div class="status arbitration">
                    <h1 class="status-title status-title-arbitration">{{ this.$store.getters.content.HOWITWORK_STAGE_4_TITLE }}</h1>
                    <p class="status-desc">{{ this.$store.getters.content.HOWITWORK_STAGE_4_DESCRIPTION }}</p>
                </div>
            </div>
            <div class="status-block">
                <div class="status final">
                    <h1 class="status-title status-title-final">{{ this.$store.getters.content.HOWITWORK_STAGE_5_TITLE }}</h1>
                    <p class="status-desc">{{ this.$store.getters.content.HOWITWORK_STAGE_5_DESCRIPTION }}</p>
                </div>
            </div>
        </div>
    </section>
    <section class="protection container">
        <h1 class="protection-title">{{ this.$store.getters.content.HOWITWORK_WARNING_TITLE }}</h1>
        <div class="protect">
            <p class="protect-desc protect-text">
                {{ this.$store.getters.content.HOWITWORK_WARNING_DESCRIPTION }}
            </p>
        </div>
    </section>
</template>

<style scoped>
.howitwork {
    color: var(--neutral-white);
    padding-top: 100px;
    margin-bottom: 32px;
}

.howitwork-title {
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    margin-bottom: 24px;
}

.howitwork-desc {
    text-align: center;
    width: 520px;
    margin: auto;
    margin-bottom: 40px;
}

.howitwork-card {
    width: 288px;
    height: 308px;
    padding: 24px 8px;
    align-items: center;
    background: var(--neutral-background-90);
    border-radius: 8px;
    position: relative;
}

.card-block {
    padding: 1px;
    border-radius: 8px;
    margin-bottom: 32px;
    background: linear-gradient(to right, #FFFFFF, #FA20BF, #6F3BF7, #000000 100%);
    position: relative;
}

.howitwork-card::before {
    content: '';
    background: url(@/assets/img/Ellipse17.png);
    position: absolute;
    left: -50px;
    right: 0;
    bottom: 0;
    top: -80px;
    width: 389px;
    height: 389px;
    z-index: -1;
    background-size: cover;
}

.img-center {
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.howitwork-card-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 8px;
}

.arrow-right {
    margin-right: 53px;
    margin-left: 53px;
}

.arrow-right-1200-d-block {
    display: none;
}

.arrow-bottom {
    display: none;
}

@media (max-width: 1400px) {
    .arrow-right {
        margin-right: 20px;
        margin-left: 20px;
    }

    .howitwork-cards {
        justify-content: center !important;
    }
}

@media (max-width: 1200px) {
    .arrow-right-1200-d-none {
        display: none;
    }

    .arrow-right-1200-d-block {
        display: block;
    }

    .arrow-right {
        margin-right: 30px;
        margin-left: 30px;
    }
}

@media (max-width: 1150px) {
    .arrow-right {
        margin-right: 20px;
        margin-left: 20px;
    }
}

@media (max-width:772px) {
    .howitwork-title {
        font-size: 28px;
        line-height: 36px;
        color: var(--neutral-text);
    }

    .card-block {
        width: 100%;
        margin-bottom: 0px;
    }

    .howitwork-card {
        width: 100%;
    }

    .arrow-right {
        display: none;
    }

    .howitwork-card::before {
        content: '';
        background: url(@/assets/img/Ellipse18.png);
        position: absolute;
        background-position: center;
        left: 50px;
        top: -50px;
        width: 454px;
        height: 454px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .arrow-bottom {
        display: block;
        margin: 24px 0;
    }

    .howitwork-desc {
        width: 100%;
    }
}

@media (max-width:600px) {
    .howitwork-card::before {
        left: 20px;
    }
}

@media (max-width:520px) {
    .howitwork-card::before {
        left: 0;
    }
}

@media (max-width:480px) {
    .howitwork-card::before {
        width: 330px;
        height: 330px;
        top: -100px;
        left: 40px;
    }
}

@media (max-width:450px) {
    .howitwork-card::before {
        left: 30px;
    }
}

@media (max-width:410px) {
    .howitwork-card::before {
        left: 20px;
        top: -120px;
    }
}

@media (max-width:390px) {
    .howitwork-card::before {
        left: 10px;
    }
}

@media (max-width:390px) {
    .howitwork-card::before {
        left: 0;
    }
}

.stages {
    color: var(--neutral-white);
    margin-bottom: 20px;
}

.stages-title {
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    margin-bottom: 26px;
}

.stages-desc {
    width: 600px;
    text-align: center;
    margin: auto;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 26px;
}

.status {
    background: var(--neutral-background-90);
    padding: 24px 16px;
    border-radius: 8px;
}

.status-block {
    margin: auto;
    width: 808px;
    padding: 1px;
    margin-bottom: 32px;
    background: linear-gradient(to right, #FFFFFF, #FA20BF, #6F3BF7, #000000 100%);
    border-radius: 8px;
    margin-bottom: 36px;
}

.status-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    align-items: center;
    display: flex;
    margin-bottom: 16px;
}

.status-title::before {
    content: "";
    width: 24px;
    height: 24px;
    margin-right: 4px;
    background: url("@/assets/img/Clipboard.png");
}

.status-title-waiting::before {
    background: url("@/assets/img/oclock.png");
}

.status-title-paid::before {
    background: url("@/assets/img/Anticlockwise.png");
}

.status-title-arbitration::before {
    background: url("@/assets/img/Warning.png");
}

.status-title-final::before {
    background: url("@/assets/img/Tick.png");
}

.protection {
    color: var(--neutral-white);
    margin-bottom: 100px;
}

.protection-title {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 36px;
}

.protect {
    background: var(--neutral-background-90);
    width: 808px;
    padding: 10px 24px;
    margin: auto;
    border: 1px solid var(--red);
    position: relative;
    border-radius: 8px;
}

.protect::before {
    content: '';
    display: inline-block;
    background: var(--red);
    width: 8px;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 140px;
    position: absolute;
    border: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.protect-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.protect-warning {
    margin-bottom: 20px;
}

@media (max-width:992px) {
    .status-block {
        width: 100%;
    }

    .stages-desc {
        width: 100%;
    }

    .protect {
        width: 100%;
    }
}

@media (max-width:800px) {
    .stages-title {
        font-size: 20px;
        line-height: 28px
    }

    .stages-desc {
        font-size: 14px;
        line-height: 20px
    }

    .status-title {
        font-size: 16px;
        line-height: 20px;
    }

    .stages-desc {
        font-size: 14px;
        line-height: 20px;
    }

    .protection-title {
        text-align: left;
        font-size: 18px;
        line-height: 24px;
    }

    .protect-text {
        font-size: 14px;
        line-height: 20px;
    }

    .protect {
        margin-bottom: 30px;
    }
}</style>