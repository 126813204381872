<template>
    <section class="transfer">
        <div class="col-12" v-if="!this.$store.state.currentUser.roles.includes('BlockedBalance')">
            <div class="form-transfer">
                <form @submit.prevent="createTransaction" class="form__settings d-flex flex-column align-items-center align-items-xl-start">
                    <primary-button class="outputting-btn mt-3">{{ this.$store.getters.content.TRANSACTION_BUTTON }}</primary-button>
                    <div class="form__field d-flex flex-column flex-xl-row align-items-xl-center">
                        <label class="output__input-label">{{ this.$store.getters.content.TRANSACTION_LOGIN }}</label>
                        <div class="auth-window__input-wrapper">
                            <primary-input @input="validateLogin" class="output__input" type="text" v-model="login"
                                :placeholder="this.$store.getters.content.TRANSACTION_LOGIN_PLACEHOLDER" maxlength="30" :isError="errors.login.length" />
                            <small class="auth-window__error-text" v-if="errors.login.length">{{
                                errors.login.join("\n")
                            }}</small>
                        </div>
                    </div>
                    <div class="form__field d-flex flex-column flex-xl-row align-items-xl-center">
                        <label class="output__input-label">{{ this.$store.getters.content.TRANSACTION_AMOUNT }}</label>
                        <div class="d-flex">
                            <div class="auth-window__input-wrapper">
                                <primary-input @input="validateAmount" class="output__input" type="number" step="0.01" v-model="amount"
                                    :placeholder="this.$store.getters.content.TRANSACTION_AMOUNT_PLACEHOLDER" :isError="errors.amount.length" />
                                <small class="auth-window__error-text" v-if="errors.amount.length">{{
                                    errors.amount.join("\n")
                                }}</small>
                            </div>
                            <p class="output__currency">
                                {{ currentCurrency.symbol }}
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <p class="output___error-text" v-else>{{ this.$store.getters.content.ERROR_BLOCKEDBALANCE }}</p>
    </section>
</template>

<style>
.output___error-text {
    color: var(--neutral-white);
    font-size: 24px;
    text-align: center;
    margin-top: 150px;
    margin-bottom: 150px;
}
</style>

<script>
import BalanceService from "@/services/BalanceService"
import UserService from "@/services/UserService"
import CurrentCurrency from '../../currency/CurrentCurrency.vue'

export default {
    data() {
        return {
            login: "",
            amount: "",
            errors: {
                login: [],
                amount: []
            },
            isInProccess: false
        }
    },
    methods: {
        async createTransaction() {
            if (this.isInProccess) {
                return
            }

            if (!this.isValid()) {
                return
            }

            this.isInProccess = true

            let recieverId = await this.getRecieverId()

            if (!recieverId) {
                this.errors.login = [this.$store.getters.content.ERROR_LOGIN_NOT_FOUND]
                this.isInProccess = false
                return
            }

            try {
                await BalanceService.createTransaction(recieverId, this.rubAmount)
                this.$notify({
                    title: this.$store.getters.content.TRANSACTION_SUCCESS
                })
                this.$store.dispatch("updateCurrentUser")
            }
            catch (e) {
                if (e.response.data?.errorCode == "NotEnoughtBalance") {
                    this.errors.amount = [this.$store.getters.content.ERROR_NOT_ENOUGH_BALANCE]
                    this.isInProccess = false
                    return
                }
            }

            this.login = ""
            this.amount = ""
            this.isInProccess = false
        },
        isValid() {
            this.validateLogin()
            this.validateAmount()
            return !Object.entries(this.errors).some(([key, value]) => value.length)
        },
        validateLogin() {
            if (this.login.length === 0) {
                this.errors.login = [this.$store.getters.content.ERROR_REQUIRED_FIELD]
            }
            else {
                this.errors.login = []
            }
        },
        validateAmount() {
            if (this.$store.state.currentCurrency == "RUB") {
                if (!/^[0-9]+$/.test(this.amount)) {
                    this.errors.amount = [this.$store.getters.content.ERROR_INCORRECT_NUMBER]
                    return
                }   
            }
            else {
                let isValidDecimalLength = !(this.amount.split(".").length == 2) || this.amount.split(".")[1].length <= 2
                if (isNaN(this.amount) || isNaN(parseFloat(this.amount)) || !isValidDecimalLength) {
                    this.errors.amount = [this.$store.getters.content.ERROR_INCORRECT_NUMBER]
                    return
                }
            }
            if (this.rubAmount < 1) {
                this.errors.amount = [this.$store.getters.content.ERROR_LOW_AMOUNT]
            }
            else {
                this.errors.amount = []
            }
        },
        async getRecieverId() {
            try {
                const user = await UserService.getUserByUserName(this.login)
                return user.id
            }
            catch (e) {
                return null
            }
        }
    },
    components: {
        CurrentCurrency
    },
    computed: {
        currentCurrency() {
            return this.$store.state.currencies.find(e => e.id === this.$store.state.currentCurrency)
        },
        rubAmount() {
            return Math.floor(this.amount / this.currentCurrency.rate)
        }
    },
}
</script>
