<template>
    <form @submit.prevent="createInputRequest" class="user-admin__form primary-linear-stroke">
        <h2 class="user-admin__form__title">Уменьшить счёт</h2>
        <div class="user-admin__form__content">
            <div class="auth-window__input-wrapper">
                <primary-input type="number" step="0.01" :placeholder="`Сумма (${currentCurrency.symbol})`" @input="validateAmount" :isError="errors.amount.length"
                    v-model="amount" class="user-admin__form__input" />
                <small class="auth-window__error-text" v-if="errors.amount.length">{{ errors.amount.join("\n")
                }}</small>
            </div>
            <button type="sumbit" class="user-admin__form__button btn-success">Уменьшить</button>
        </div>
    </form>
</template>

<script>
import PaymentSystemService from "@/services/PaymentSystemService.js"
import AdminUserService from "@/services/AdminUserService.js"

export default {
    emits: ["updateUser"],
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            paymentSystemId: 1,
            amount: "",
            errors: {
                paymentSystemId: [],
                amount: []
            }
        }
    },
    async created() {
        const paymentSystems = (await PaymentSystemService.getPaymentSystems())
        this.paymentSystemId = paymentSystems.find(e => e.name == "AdminPanel").id
    },
    methods: {
        async createInputRequest() {
            if (!this.isValid()) {
                return
            }

            await AdminUserService.addInputRequest(this.user.id, this.paymentSystemId, -this.rubAmount)
            this.$emit("updateUser")
            this.$notify({text: "Счёт пользователя успешно уменьшен"})
            this.amount = ""

            if (this.user.id == this.$store.state.currentUser.id) {
                this.$store.dispatch("updateCurrentUser")
            }
        },
        isValid() {
            this.validateAmount()
            return !Object.entries(this.errors).some(([key, value]) => value.length)      
        },
        validateAmount() {
            if (this.$store.state.currentCurrency == "RUB") {
                if (!/^[0-9]+$/.test(this.amount)) {
                    this.errors.amount = [this.$store.getters.content.ERROR_INCORRECT_NUMBER]
                    return
                }   
            }
            else {
                let isValidDecimalLength = !(this.amount.split(".").length == 2) || this.amount.split(".")[1].length <= 2
                if (isNaN(this.amount) || isNaN(parseFloat(this.amount)) || !isValidDecimalLength) {
                    this.errors.amount = [this.$store.getters.content.ERROR_INCORRECT_NUMBER]
                    return
                }
            }
            if (this.rubAmount < 1) {
                this.errors.amount = ["Слишком маленькая сумма"]
            }
            else {
                this.errors.amount = []
            }
        },
    },
    computed: {
        currentCurrency() {
            return this.$store.state.currencies.find(e => e.id === this.$store.state.currentCurrency)
        },
        rubAmount() {
            return Math.floor(this.amount / this.currentCurrency.rate)
        }
    },
}
</script>
