<template>
    <tr class="defaul-card">
        <td class="defaul-card__property" v-if="outputRequest.status === 1">
            <linear-stroke-button class="defaul-card__detail-link" @click="isShowProcessWindow = true">Обработать</linear-stroke-button>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">Пользователь</h3>
            <p class="defaul-card__property__value">{{ outputRequest.user.userName }}</p>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">Дата</h3>
            <p class="defaul-card__property__value">{{ new Date(outputRequest.createdAt).toLocaleString("ru-RU") }}</p>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">Тип реквизита</h3>
            <p class="defaul-card__property__value">{{ outputRequest.paymentSystem.name }}</p>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">Реквизиты</h3>
            <p class="defaul-card__property__value">{{ outputRequest.paymentDetails }}</p>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">Сумма</h3>
            <p class="defaul-card__property__value"><CurrentCurrency :value="outputRequest.amount" /></p>
        </td>
        <td class="defaul-card__property">
            <h3 class="defaul-card__property__name">Статус</h3>
            <p class="defaul-card__property__value"
                :class="{ 'status-neutral': outputRequest.status === 1, 'status-false': outputRequest.status === 2, 'status-true': outputRequest.status === 3 }">
                {{ getStatusName(outputRequest.status) }}</p>
        </td>
    </tr>
    <ProcessOutputRequestWindow :isShow="isShowProcessWindow" :outputRequest="outputRequest" @hideWindow="isShowProcessWindow = false" @requestProccessed="$emit('requestsUpdated')" />
</template>

<script>
import ProcessOutputRequestWindow from "@/components/windows/ProcessOutputRequestWindow.vue"
import CurrentCurrency from '../../currency/CurrentCurrency.vue'

export default {
    emits: ["requestsUpdated"],
    props: {
        outputRequest: {
            required: true
        }
    },
    data() {
        return {
            isShowProcessWindow: false
        }
    },
    methods: {
        getStatusName(id) {
            switch (id) {
                case 1: return "В обработке"
                case 2: return "Отменён"
                case 3: return "Одобрен"
            }
        }
    },
    components: {
        ProcessOutputRequestWindow,
        CurrentCurrency
    }
}
</script>
