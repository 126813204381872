<template>
    <section class="content">
        <div class="container">
            <h1 class="content-title">{{ this.$store.getters.content.NOTIFICATIONS_TITLE }}</h1>
            <div class="messages" v-if="notifications.length">
                <div class="message" v-for="notification in notifications">
                    <p class="message-text">{{ notification.text }}</p>
                </div>
            </div>
            <div class="message-null" v-else>
                <p class="message-null-text">{{ this.$store.getters.content.NOTIFICATIONS_VOID }}</p>
            </div>
        </div>
    </section>
</template>

<script>
import NotificationService from "@/services/NotificationService.js"

export default {
    data() {
        return {
            notifications: []
        }
    },
    async created() {
        const response = await NotificationService.getNotifications()
        this.notifications = response.items
    }
}
</script>