<template>
  <Teleport to="body">
    <div v-show="isShow" class="window__wraper" @click="$emit('hideWindow')">
      <slot></slot>
    </div>
  </Teleport>
</template>

<style>
.window__wraper {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 20;
}
</style>
  
<script>
export default {
  name: "window-wrapper",
  emits: ["hideWindow"],
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    isShow(newValue, oldValue) {
      if (newValue) {
        document.body.classList.add("no-scroll")
      }
      else {
        document.body.classList.remove("no-scroll")
      }
    }
  }
}
</script>