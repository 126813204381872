<template>
    <container v-if="user">
        <h2 class="user-admin__title">Пользователь {{ user.userName }}</h2>
        <table class="admin-deals__main">
            <tr class="defaul-card">
                <td class="defaul-card__property">
                    <h3 class="defaul-card__property__name">Логин</h3>
                    <p class="defaul-card__property__value">{{ user.userName }}</p>
                </td>
                <td class="defaul-card__property">
                    <h3 class="defaul-card__property__name">E-mail</h3>
                    <p class="defaul-card__property__value">{{ user.email }}</p>
                </td>
                <td class="defaul-card__property">
                    <h3 class="defaul-card__property__name">Баланс</h3>
                    <p class="defaul-card__property__value"><CurrentCurrency :value="user.balance" /></p>
                </td>
                <td class="defaul-card__property">
                    <h3 class="defaul-card__property__name">Последний онлайн</h3>
                    <p class="defaul-card__property__value">{{ new Date(user.lastOnline).toLocaleString("ru-RU") }}</p>
                </td>
                <td class="defaul-card__property" v-if="user.roles.length">
                    <h3 class="defaul-card__property__name">Роли</h3>
                    <p class="defaul-card__property__value">{{ user.roles.join(", ") }}</p>
                </td>
            </tr>
        </table>
        <InputRequestForm :user="user" @updateUser="setUser" />
        <ReduceBalanceForm :user="user" @updateUser="setUser" />
        <BlockUserForm :user="user" @updateUser="setUser" @deleteUser="onUserDelete" />
        <SendNotificationForm :user="user" />
        <ChangePasswordForm :user="user" />
    </container>
</template>

<style>
.user-admin__title {
    color: var(--neutral-white);
    margin-top: 75px;
    margin-bottom: -40px;
    font-size: 32px;
    font-weight: 600;
}

.user-admin__form {
    padding: 25px;
    background-color: var(--neutral-background-100);
    border-radius: 8px;
    margin-top: 50px;
}

.user-admin__form__title {
    color: var(--neutral-white);
    font-size: 26px;
    font-weight: 600;
}

.user-admin__form__content {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.user-admin__form__button {
    width: 100%;
    height: 52px;
    font-size: 16px;
    font-weight: 400;
    border: none;
    border-radius: 6px;
}

textarea.user-admin__form__input {
    height: 200px;
}
</style>

<script>
import AdminUserService from "@/services/AdminUserService.js"
import BlockUserForm from "@/components/partials/user/BlockUserForm.vue"
import ChangePasswordForm from "@/components/partials/user/ChangePasswordForm.vue"
import SendNotificationForm from "@/components/partials/user/SendNotificationForm.vue"
import InputRequestForm from "@/components/partials/user/InputRequestForm.vue"
import ReduceBalanceForm from "@/components/partials/user/ReduceBalanceForm.vue"
import CurrentCurrency from '../components/currency/CurrentCurrency.vue'

export default {
    data() {
        return {
            user: null
        }
    },
    async created() {
        await this.setUser()
    },
    methods: {
        async setUser() {
            try {
                this.user = await AdminUserService.getUser(this.$route.params.id)
            }
            catch (e) {
                if (e.response.status === 404) {
                    this.$router.push("/notfound")
                }
            }
        },
        onUserDelete() {
            this.$notify({text: "Пользователь удалён"})
            this.$router.push("/admin/users")
        }
    },
    components: { BlockUserForm, ReduceBalanceForm, SendNotificationForm, ChangePasswordForm, InputRequestForm, CurrentCurrency }
}
</script>