<template>
    <section class="rules container">
        <router-link to="/" class="back">{{ this.$store.getters.content.BACK }}</router-link>
        <h1 class="rules-title">{{ this.$store.getters.content.RULES_TITLE }}</h1>
        <p class="rules-text" v-html="this.$store.getters.content.RULES_TEXT"></p>
    </section>
</template>

<style scoped>
.rules {
    color: var(--neutral-white);
    padding-top: 30px;
}

.rules-title {
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    margin-top: 28px;
    margin-bottom: 24px;
}

.rules-text {
    color: var(--neutral-white);
    font-size: 16px;
    line-height: 24px;
}

.back {
    text-decoration: none;
    color: var(--primary-pink);
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 0.029px;
    position: relative;
    margin-left: 28px;
}

.back::before {
    content: "";
    height: 100%;
    width: 38px;
    position: absolute;
    top: 0px;
    display: block;
    background: url('@/assets/img/arrow_back.svg') no-repeat;
}

@media (max-width:770px) {
    .rules-title {
        font-size: 20px;
        line-height: 28px;
    }

    .rules-text {
        margin-bottom: 56px;
    }
}
</style>
