<template>
    <vue-awesome-paginate :total-items="count" :max-pages-shown="4" :items-per-page="perPage" v-model="modelValue" />
</template>

<style>
.pagination-container {
    display: flex;
    column-gap: 8px;
}

.paginate-buttons {
    height: 32px;
    width: 32px;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--neutral-background-90);
    border: none;
    color: var(--neutral-white);
    font-size: 14px;
    font-weight: 700;
}

.active-page {
    border: 1px solid var(--primary-pink);
    color: var(--primary-pink);
}
</style>

<script>
export default {
    name: "primary-pagination",
    props: {
        count: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            modelValue: 1
        }
    }
}
</script>