<template>
    <container>
        <div class="admin-deals__header">
            <form @submit.prevent="setOutputRequests" class="admin-deals__filter">
                <div class="admin-deals__input-wrapper">
                    <p>
                        Статус заявок
                    </p>
                    <select class="admin-deals__filter__input" v-model="status">
                        <option value="1">В обработке</option>
                        <option value="2">Отклонённые</option>
                        <option value="3">Одобренные</option>
                    </select>
                </div>
                <div class="admin-deals__input-wrapper search">
                    <p>
                        Поиск по пользователю
                    </p>
                    <primary-input placeholder="Логин . . ." v-model="userName" />
                </div>
                <button type="sumbit" class="admin-deals__filter__button primary-linear-stroke">Найти</button>
            </form>
        </div>
        <table class="admin-deals__main" v-if="outputRequestsCount">
            <AdminOutputCard @requestsUpdated="setOutputRequests" v-for="outputRequest in outputRequests" :outputRequest="outputRequest" :key="outputRequest.id" />
        </table>
        <div class="admin-deals__footer" v-if="outputRequestsCount">
            <primary-pagination class="admin__pagination" :count="outputRequestsCount" :perPage="9" v-model="page"
                @update:modelValue="setOutputRequests" />
        </div>
        <p class="admin-deals__notfound-text" v-else>Заявок не найдено</p>
    </container>
</template>

<script>
import AdminOutputService from "@/services/AdminOutputService.js"
import UserService from "@/services/UserService"
import AdminOutputCard from "@/components/partials/admin/AdminOutputCard.vue"

export default {
    components: {
        AdminOutputCard
    },
    data() {
        return {
            status: 1,
            userName: "",
            outputRequests: [],
            outputRequestsCount: 0,
            page: 1
        }
    },
    async created() {
        await this.setOutputRequests();
    },
    methods: {
        async setOutputRequests() {
            let userId = await this.getUserId()

            const response = await AdminOutputService.getOutputRequests(
                {
                    userId: userId,
                    status: this.status,
                    page: this.page,
                    perPage: 9
                }
            )
            this.outputRequests = response.items
            this.outputRequestsCount = response.count
        },
        async getUserId() {
            try {
                return (await UserService.getUserByUserName(this.userName)).id
            }
            catch (e) {
                return null
            }
        }
    }
}
</script>
