import axios from "axios"
import store from "../store"

const UserService = {
    async getCurrentUser(accessToken) {
        const response = await axios.get("/api/users/me", { headers: { Authorization: `Bearer ${accessToken}` } })
        return response.data
    },
    async getUserByUserName(userName) {
        const response = await axios.get(`/api/users/${userName}`)
        return response.data
    },
    async updateCurrentUser(email, password) {
        let data = new FormData()

        email && data.append("email", email)
        password && data.append("password", password)

        await axios.patch("/api/users/me", data, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    }
}

export default UserService
