<template>
    <section class="outputting">
        <primary-button
            v-if="!this.$store.state.currentUser.roles.includes('BlockedBalance')"
            class="outputting-btn"
            @click="isShowCreateWindow = true"
        >
            {{ this.$store.getters.content.OUTPUT_BUTTON }}
        </primary-button>
        <div class="outputting-table my-table" v-if="requestsCount">
            <template v-for="(request, index) in requests" :key="request.id">
                <div class="table-item">
                    <div class="table-id">
                        <p class="table-title">ID</p>
                        <p class="table-desc">{{ request.id }}</p>
                    </div>
                    <div class="table-date">
                        <p class="table-title">{{ this.$store.getters.content.DEPOSIT_DATE }}</p>
                        <p class="table-desc">{{ new Date(request.createdAt).toLocaleString("ru-RU") }}</p>
                    </div>
                    <div class="table-type">
                        <p class="table-title">{{ this.$store.getters.content.DEPOSIT_TYPE }}</p>
                        <p class="table-desc">{{ request.paymentSystem.name }}</p>
                    </div>
                    <div class="table-number">
                        <p class="table-title">{{ this.$store.getters.content.DEPOSIT_NUMBER_1 }}</p>
                        <p class="table-desc">{{ request.paymentDetails }}</p>
                    </div>
                    <div class="table-summ">
                        <p class="table-title">{{ this.$store.getters.content.DEPOSIT_AMOUNT }}</p>
                        <p class="table-desc"><CurrentCurrency :value="request.amount" /></p>
                    </div>
                    <div class="table-status">
                        <p class="table-title">{{ this.$store.getters.content.DEPOSIT_STATUS }}</p>
                        <p class="table-desc"
                            :class="{ 'status-neutral': request.status === 1, 'status-false': request.status === 2, 'status-true': request.status === 3 }">
                            {{ getStatusName(request.status) }}</p>
                    </div>
                </div>
                <div class="line" v-if="index != (requests.length - 1)"></div>
            </template>
        </div>
    </section>
    <primary-pagination v-if="requests.length" class="input__pagination" v-model="page" :count="requestsCount"
        :perPage="perPage" @update:modelValue="setRequests" />
    <OutputRequestWindow :isShow="isShowCreateWindow" @hideWindow="isShowCreateWindow = false" @newRequest="setRequests" />
</template>

<script>
import BalanceService from "@/services/BalanceService.js"
import OutputRequestWindow from "@/components/windows/OutputRequestWindow.vue"
import CurrentCurrency from '../../currency/CurrentCurrency.vue'

export default {
    data() {
        return {
            requests: [],
            requestsCount: 0,
            page: 1,
            perPage: 9,
            isShowCreateWindow: false
        }
    },
    async created() {
        await this.setRequests()
    },
    methods: {
        async setRequests() {
            const response = await BalanceService.getOutputRequests({ page: this.page, perPage: this.perPage })
            this.requests = response.items
            this.requestsCount = response.count
        },
        getStatusName(id) {
            switch (id) {
                case 1: return this.$store.getters.content.OUTPUT_STATUS_1
                case 2: return this.$store.getters.content.OUTPUT_STATUS_2
                case 3: return this.$store.getters.content.OUTPUT_STATUS_3
            }
        }
    },
    components: {
        OutputRequestWindow,
        CurrentCurrency
    }
}
</script>
