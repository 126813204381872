import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import components from "@/components/UI"
import Notifications from '@kyvg/vue3-notification'

import VueAwesomePaginate from "vue-awesome-paginate"
import "vue-awesome-paginate/dist/style.css"

import "@/assets/css/bootstrap.min.css"
import "@/assets/css/style.css"
import "@/assets/js/chaport.js"

const app = createApp(App)

components.forEach(c => app.component(c.name, c))

app.use(VueAwesomePaginate).use(Notifications).use(store).use(router).mount("#app")
