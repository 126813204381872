<template>
    <form @submit.prevent="changePassword" class="user-admin__form primary-linear-stroke">
        <h2 class="user-admin__form__title">Сменить пароль</h2>
        <div class="user-admin__form__content">
            <div class="auth-window__input-wrapper">
                <primary-input type="password" placeholder="Новый пароль" @input="validatePassword" :isError="errors.password.length"
                    v-model="password" class="user-admin__form__input" />
                <small class="auth-window__error-text" v-if="errors.password.length">{{ errors.password.join("\n")
                }}</small>
            </div>
            <button type="sumbit" class="user-admin__form__button btn-success">Сменить</button>
        </div>
    </form>
</template>

<script>
import AdminUserService from "@/services/AdminUserService.js"

export default {
    data() {
        return {
            password: "",
            errors: {
                password: []
            }
        }
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    methods: {
        async changePassword() {
            this.validatePassword()
            if (this.errors.password.length) {
                return
            }

            await AdminUserService.changePassword(this.user.id, this.password)
            this.$notify({ text: "Пароль обновлён" })
            this.password = ""
        },
        validatePassword() {
            if (this.password.length === 0) {
                this.errors.password = [this.$store.getters.content.ERROR_REQUIRED_FIELD]
            }
            else if (this.password.length < 8) {
                this.errors.password = ["Пароль слишком короткий"]
            }
            else {
                this.errors.password = []
            }
        }
    },
}
</script>