<template>
    <window-wrapper :isShow="$store.state.isShowAuthWindow" @hideWindow="this.$store.dispatch('hideAuthWindow')" v-if="this.$store.state.isReady">
        <div @click.stop class="auth-window primary-linear-stroke">
            <LoginWindow v-if="$store.state.authWindowState == 1" />
            <RegisterWindow v-if="$store.state.authWindowState == 2" />
        </div>
    </window-wrapper>
</template>

<style>
.auth-window {
    width: 390px;
    padding: 24px 35px;
    background: var(--neutral-background-100);
    margin: auto;
    border-radius: 16px;
}

@media (max-width: 450px) {
    .auth-window {
        width: 90%;
        padding: 18px 26px;
    }
}

.auth-window__header {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
}

.auth-window__title {
    font-size: 20px;
    font-weight: 600;
    color: var(--neutral-white);
}

.auth-window__close {
    height: 24px;
    width: 24px;
    cursor: pointer;
}

.auth-window__form__inputs-wraper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.auth-window__error-text {
    font-size: 12px;
    font-weight: 400;
    color: var(--red);
    margin-top: 4px;
    white-space: pre-line;
}

.auth-window__button {
    width: 100%;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 400;
}

.auth-window__change-window-text {
    font-size: 14px;
    font-weight: 400;
    color: var(--neutral-white);
    margin-top: 24px;
}

.auth-window__change-window-text > span {
    cursor: pointer;
}
</style>

<script>
import LoginWindow from "./LoginWindow.vue"
import RegisterWindow from "./RegisterWindow.vue"

export default {
    components: {
        LoginWindow,
        RegisterWindow
    }
}
</script>