<template>
    <Promo />
    <section class="partners">
        <ul class="partners-list partners-list-first">
            <li class="partners-item"><a><img class="partners-item-img" src="@/assets/img/qiwi.svg"></a></li>
            <li class="partners-item"><a><img class="partners-item-img" src="@/assets/img/robokassa.svg"></a></li>
            <li class="partners-item"><a><img class="partners-item-img" src="@/assets/img/avito.svg"></a></li>
            <li class="partners-item"><a><img class="partners-item-img" src="@/assets/img/boxberry.svg"></a></li>
            <li class="partners-item"><a><img class="partners-item-img" src="@/assets/img/gett.svg"></a></li>
        </ul>
        <ul class="partners-list-second partners-list">
            <li class="partners-item"><a><img class="partners-item-img" src="@/assets/img/qiwi.svg"></a></li>
            <li class="partners-item"><a><img class="partners-item-img" src="@/assets/img/avito.svg"></a></li>
            <li class="partners-item"><a><img class="partners-item-img" src="@/assets/img/gett.svg"></a></li>
            <li class="partners-item"><a><img class="partners-item-img" src="@/assets/img/boxberry.svg"></a></li>
            <li class="partners-item"><a><img class="partners-item-img" src="@/assets/img/robokassa.svg"></a></li>
        </ul>
    </section>
    <section class="safe">
        <div class="container">
            <div class="safe-content">
                <div class="safe-img">
                    <img src="@/assets/img/wallet.png" class="safe-image" alt="">
                </div>
                <div class="safe-desc">
                    <h1 class="safe-text-title" v-html="this.$store.getters.content.SAFE_TITLE"></h1>
                    <p class=" safe-text-desc safe-text-desc-one">
                        {{ this.$store.getters.content.SAFE_DESCRIPTION_1 }}
                    </p>
                    <p class="safe-text-desc safe-text-desc-two">
                        {{ this.$store.getters.content.SAFE_DESCRIPTION_2 }}
                    </p>
                    <button @click="goToDeals" class="safe-btn">{{ this.$store.getters.content.SAFE_BUTTON }}</button>
                </div>
            </div>
        </div>
    </section>
    <section class="steps">
        <div class="container">
            <div class="steps-content">
                <h1 class="steps-title">{{ this.$store.getters.content.STEPS_TITLE }}</h1>
                <p class="steps-desc">{{ this.$store.getters.content.STEPS_DESCRIPTION }}</p>
                <div class="steps-cards">
                    <div class="steps-card">
                        <div class="steps-horizontal">
                            <img src="@/assets/img/frame87.png" class="steps-card-img" alt="">
                        </div>
                        <h1 class="steps-card-title">{{ this.$store.getters.content.STEPS_1_TITLE }}</h1>
                        <p class="steps-card-desc">{{ this.$store.getters.content.STEPS_1_DESCRIPTION }}</p>
                    </div>
                    <div class="steps-card">
                        <div class="steps-horizontal">
                            <img src="@/assets/img/frame88.png" class="steps-card-img" alt="">
                        </div>
                        <h1 class="steps-card-title">{{ this.$store.getters.content.STEPS_2_TITLE }}</h1>
                        <p class="steps-card-desc">{{ this.$store.getters.content.STEPS_2_DESCRIPTION }}</p>
                    </div>
                    <div class="steps-card">
                        <div class="steps-horizontal">
                            <img src="@/assets/img/frame93.png" class="steps-card-img" alt="">
                        </div>
                        <h1 class="steps-card-title">{{ this.$store.getters.content.STEPS_3_TITLE }}</h1>
                        <p class="steps-card-desc">{{ this.$store.getters.content.STEPS_3_DESCRIPTION }}</p>
                    </div>
                    <div class="steps-card">
                        <div class="steps-horizontal">
                            <img src="@/assets/img/frame92.png" class="steps-card-img" alt="">
                        </div>
                        <h1 class="steps-card-title">{{ this.$store.getters.content.STEPS_4_TITLE }}</h1>
                        <p class="steps-card-desc">{{ this.$store.getters.content.STEPS_4_DESCRIPTION }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="scheme">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="schemeBlockMain d-flex flex-wrap col-12 col-xl-6">
                    <h2 class="text-center">{{ this.$store.getters.content.SCHEME_TITLE }}</h2>
                    <div class="schemeBlockMain__items col-12 col-xl-12">
                        <Accordeon :title="this.$store.getters.content.SCHEME_1_TITLE" :description="this.$store.getters.content.SCHEME_1_DESCRIPTION" />
                        <Accordeon :title="this.$store.getters.content.SCHEME_2_TITLE" :description="this.$store.getters.content.SCHEME_2_DESCRIPTION" />
                        <Accordeon :title="this.$store.getters.content.SCHEME_3_TITLE" :description="this.$store.getters.content.SCHEME_3_DESCRIPTION" />
                        <Accordeon :title="this.$store.getters.content.SCHEME_4_TITLE" :description="this.$store.getters.content.SCHEME_4_DESCRIPTION" />
                        <Accordeon :title="this.$store.getters.content.SCHEME_5_TITLE" :description="this.$store.getters.content.SCHEME_5_DESCRIPTION" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="adventages container">
        <h1 class="adventages-title">{{ this.$store.getters.content.ADVENTAGES_TITLE }}</h1>
        <div class="security">
            <div class="security-content">
                <div class="security-left-content">
                    <p class="security-title">{{ this.$store.getters.content.ADVENTAGES_1 }}</p>
                    <ul class="security-list">
                        <li class="security-list-item">
                            <p>&nbsp;&nbsp;&nbsp;{{ this.$store.getters.content.ADVENTAGES_1_1 }}</p>
                        </li>
                        <li class="security-list-item">
                            <p>&nbsp;&nbsp;&nbsp;{{ this.$store.getters.content.ADVENTAGES_1_2 }}</p>
                        </li>
                        <li class="security-list-item">
                            <p>&nbsp;&nbsp;&nbsp;{{ this.$store.getters.content.ADVENTAGES_1_3 }}</p>
                        </li>
                        <li class="security-list-item">
                            <p>&nbsp;&nbsp;&nbsp;{{ this.$store.getters.content.ADVENTAGES_1_4 }}</p>
                        </li>
                        <li class="security-list-item">
                            <p>&nbsp;&nbsp;&nbsp;{{ this.$store.getters.content.ADVENTAGES_1_5 }}</p>
                        </li>
                    </ul>
                </div>
                <div class="security-right-content">
                    <img src="@/assets/img/security.png" alt="">
                </div>
            </div>
        </div>
        <div class="security">
            <div class="security-content">
                <div class="security-left-content">
                    <img src="@/assets/img/security2.png" alt="" class="security-img-one">
                </div>
                <div class="security-right-content">
                    <p class="security-title">{{ this.$store.getters.content.ADVENTAGES_2 }}</p>
                    <ul class="security-list">
                        <li class="security-list-item">
                            <p>&nbsp;&nbsp;&nbsp;{{ this.$store.getters.content.ADVENTAGES_2_1 }}</p>
                        </li>
                        <li class="security-list-item">
                            <p>&nbsp;&nbsp;&nbsp;{{ this.$store.getters.content.ADVENTAGES_2_2 }}</p>
                        </li>
                        <li class="security-list-item">
                            <p>&nbsp;&nbsp;&nbsp;{{ this.$store.getters.content.ADVENTAGES_2_3 }}</p>
                        </li>
                        <li class="security-list-item">
                            <p>&nbsp;&nbsp;&nbsp;{{ this.$store.getters.content.ADVENTAGES_2_4 }}</p>
                        </li>
                    </ul>
                </div>
                <img src="@/assets/img/security2.png" alt="" class="security-img-two">
            </div>
        </div>
        <div class="security">
            <div class="security-content">
                <div class="security-left-content security-left-content-3">
                    <p class="security-title">{{ this.$store.getters.content.ADVENTAGES_3 }}</p>
                    <ul class="security-list">
                        <li class="security-list-item">
                            <p>&nbsp;&nbsp;&nbsp;{{ this.$store.getters.content.ADVENTAGES_3_1 }}</p>
                        </li>
                        <li class="security-list-item">
                            <p>&nbsp;&nbsp;&nbsp;{{ this.$store.getters.content.ADVENTAGES_3_2 }}</p>
                        </li>
                        <li class="security-list-item">
                            <p>&nbsp;&nbsp;&nbsp;{{ this.$store.getters.content.ADVENTAGES_3_3 }}</p>
                        </li>
                    </ul>
                </div>
                <div class="security-right-content">
                    <img src="@/assets/img/security3.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <section class="head">
        <div class="container">
            <div class="row">
                <div class="headMain bg_secondary d-flex flex-column align-items-center justify-content-center col-12">
                    <div class="head__title col-12">
                        <h2 class="text-center">{{ this.$store.getters.content.HEAD_TITLE }}</h2>
                    </div>
                    <div class="headBlockMain__items d-flex flex-wrap col-12 col-xl-12">
                        <div class="headBlockMain__item d-flex flex-column align-items-center col-12 col-xl-4">
                            <div class="d-flex align-items-center">
                                <h3>
                                    &#60; 10%
                                </h3>
                            </div>
                            <p class="text-center">
                                {{ this.$store.getters.content.HEAD_1 }}
                            </p>
                        </div>
                        <div class="headBlockMain__item d-flex flex-column align-items-center col-12 col-xl-4">
                            <div class="d-flex align-items-center">
                                <h3><CurrentCurrency :value="69328868" :isSplit="true" /></h3>
                            </div>
                            <p class="text-center">
                                {{ this.$store.getters.content.HEAD_2 }}
                            </p>
                        </div>
                        <div class="headBlockMain__item d-flex flex-column align-items-center col-12 col-xl-4">
                            <div class="d-flex align-items-center">
                                <h3>30 000</h3>
                            </div>
                            <p class="text-center">
                                {{ this.$store.getters.content.HEAD_3 }}
                            </p>
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <primary-button v-if="!this.$store.state.isAuth" @click="this.$store.dispatch('showRegisterWindow')"
                            class="head_bg fc__mainInvert">{{ this.$store.getters.content.HEAD_BUTTON_AUTH }}</primary-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="reviewsSlider">
        <div class="container-fluid">
            <div class="row text-center">
                <h2>{{ this.$store.getters.content.REVIEWS_TITLE }}</h2>
            </div>
            <div class="row">
                <div class="sliderWrapper justify-content-center position-relative px-xl-0 col-12">
                    <div class="slider position-relative d-flex align-items-center col-12">
                        <div ref="slider" class="slides fc__secondary d-flex" :style="{ left: `${sliderMargin}px` }">
                            <div class="slide d-flex flex-column align-items-xl-center col-3 col-md-4" ref="slides"
                                v-for="slide in slides">
                                <div class="reviewSlider__img d-flex col-12">
                                </div>
                                <div class="reviewsSlider__txt bg__secondary d-flex flex-column col-12">
                                    <div
                                        class="reviewsSlider__txt__username d-flex justify-content-between align-items-center size12">
                                        <p>{{ slide.username }}</p>
                                        <span class="bg__main"><CurrentCurrency :value="slide.price" /></span>
                                    </div>
                                    <div class="reviewSlider__stars d-flex col-12">
                                        <img src="@/assets/img/star_purple500.svg">
                                        <img src="@/assets/img/star_purple500.svg">
                                        <img src="@/assets/img/star_purple500.svg">
                                        <img src="@/assets/img/star_purple500.svg">
                                        <img src="@/assets/img/star_purple500.svg">
                                    </div>
                                    <div class="reviewsSlider__txt__review col-12">
                                        <p>
                                            {{ slide.text[this.$store.state.currentLanguage] }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sliderControls d-flex justify-content-center">
                        <div class="sliderControl control__left d-xl-flex" @click="prevSlide">
                            <img src="@/assets/img/arrow-left-circle.svg" class="sliderControl__left"
                                :style="{ opacity: currentSlide === 0 ? '0.3' : '1' }">
                        </div>
                        <div class="sliderControl control__right d-xl-flex" @click="nextSlide">
                            <img src="@/assets/img/arrow-right-circle.svg" class="sliderControl__right">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="reviews reviewsMain bg__third px-0">
        <div class="container">
            <div class="row reviews_bg flex-wrap justify-content-around">
                <div class="reviewsMain__img px-0 col-12 col-xl-6">
                    <img src="@/assets/img/group30.png">
                </div>
                <div class="reviews__content d-flex flex-column col-12 col-xl-6">
                    <h2>{{ this.$store.getters.content.SUPPORT_TITLE }}</h2>
                    <p>{{ this.$store.getters.content.SUPPORT_DESCRIPTION }}</p>
                    <div class="wrapperBtn reviews_btn d-flex justify-content-center align-items-center">
                        <a class="btn fc__main  border__btn" @click="openChaport">{{ this.$store.getters.content.SUPPORT_BUTTON }}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.steps {
    margin-top: 90px;
}

.steps-title {
    color: var(--neutral-white);
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    margin-bottom: 24px;
}

.steps-desc {
    color: var(--neutral-text);
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    width: 808px;
    margin: auto;
    text-align: center;
}

.steps-cards {
    margin-top: 56px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.steps-card {
    width: 288px;
}

.steps-card:nth-child(4) {
    margin-right: 0px;
}

.step-card-img {
    display: block;
    margin: auto;
}

.steps-card-title {
    color: var(--neutral-white);
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
}

.steps-card-desc {
    color: var(--neutral-white);
    text-align: center;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px
}

.steps-horizontal {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

@media (max-width: 1399px) {
    .steps-card:nth-child(4) {
        margin: auto;
        margin-top: 36px;
    }
}

@media (max-width: 960px) {
    .steps-cards {
        justify-content: center;
    }

    .steps-card:nth-child(4) {
        margin: 0;
        margin-top: 36px;
    }

    .steps-card:nth-child(3) {
        margin-top: 36px;
    }

    .steps-title {
        font-size: 24px;
    }

    .steps-desc {
        font-size: 16px;
        width: 100%;
    }
}

@media (max-width: 639px) {
    .steps-card:nth-child(2) {
        margin-top: 36px;
    }
}

@media (max-width: 550px) {
    .steps-title {
        font-size: 18px;
        width: 100%;
        line-height: 28px;
    }
}
</style>

<script>
import Promo from "@/components/partials/home/Promo.vue"
import Accordeon from "@/components/partials/home/Accordeon.vue"
import CurrentCurrency from '../components/currency/CurrentCurrency.vue'

export default {
    name: 'HomeView',
    components: {
        Promo,
        Accordeon,
        CurrentCurrency
    },
    data() {
        return {
            currentSlide: 0,
            slides: [
                {
                    "username": "GOR4666",
                    "price": 2000,
                    "text": {
                        "RU": "Всё происходит очень быстро. Все продавцы очень вежливы и пытаются сделать всё как можно лучше. Всем советую)",
                        "UA": "Все відбувається дуже швидко. Всі продавці дуже ввічливі і намагаються зробити все якнайкраще. Всім раджу)",
                        "EN": "Everything happens very quickly. All the sales people are very polite and try to make everything as good as possible. Would recommend to everyone"
                    }
                },
                {
                    "username": "lwhite",
                    "price": 14500,
                    "text": {
                        "RU": "Очень хороший сервис продаж, всем рекомендую, сам продаю на ней больше года, поддержка топ, покупатели и продавцы лучшие!",
                        "UA": "Дуже хороший сервіс продажів, всім рекомендую, сам продаю на ній більше року, підтримка топ, покупці і продавці кращі!",
                        "EN": "Very good sales service, I recommend it to everyone, I've been selling on it myself for over a year, support is top, buyers and sellers are the best!"
                    }
                },
                {
                    "username": "SmartiOxigenium",
                    "price": 7000,
                    "text": {
                        "RU": "Минусов нету, всё очень удобно, быстрая и вежливая тех. поддержка. Всё честно и быстро. Рекомендую!",
                        "UA": "Мінусів немає, все дуже зручно, швидка і ввічлива техпідтримка. Все чесно і швидко. Рекомендую!",
                        "EN": "There are no minuses, everything is very convenient, fast and polite technical support. Everything is fair and fast. I recommend!"
                    }
                },
                {
                    "username": "Ражабик",
                    "price": 10000,
                    "text": {
                        "RU": "Отличный проверенный сайт для продажи игрового имущества и т. д, сам пользуюсь и вам советую, это вам не какой-то ноунейм сайт где вас кинут и пошлют подальше.",
                        "UA": "Відмінний перевірений сайт для продажу ігрового майна і т.д, сам користуюся і Вам раджу, це вам не якийсь ноунейм сайт де вас кинуть і пошлють подалі.",
                        "EN": "Excellent verified site for the sale of game property, etc., I use myself and advise you, it is not some nouneym site where you will be thrown and sent away."
                    }
                },
                {
                    "username": "artuazzz",
                    "price": 4700,
                    "text": {
                        "RU": "Пользуюсь услугами продолжительное время. Было дело что покупал/продавал аккаунты, было дело что покупал/продавал золото. Всё отлично и всём советую.",
                        "UA": "Користуюся послугами тривалий час. Була справа що купував/продавав акаунти, була справа що купував / продавав золото. Все відмінно і всім раджу.",
                        "EN": "I have been using the services for a long time. There was a case that bought/sold accounts, there was a case that bought/sold gold. Everything is great and I recommend it to everyone."
                    }
                },
                {
                    "username": "SkingerLoy",
                    "price": 12900,
                    "text": {
                        "RU": "Со стороны покупателя площадка максимально доступна и понятна, а главное по возможности защищена, 100% Рекомендую ! Площадка свои роль в сделке выполняет.",
                        "UA": "З боку покупця майданчик максимально доступна і зрозуміла, а головне по можливості захищена, 100% Рекомендую ! Майданчик свої роль в Угоді виконує.",
                        "EN": "From the buyer's side the platform is maximally accessible and understandable, and most importantly if possible protected, 100% recommended ! The platform fulfills its role in the transaction."
                    }
                },
                {
                    "username": "Genk1",
                    "price": 8000.00,
                    "text": {
                        "RU": "Мы с друзьями хотели поиграть все вместе в валорант, а так как у меня нету аккаунта от него я решил купить его. Провёл сделку на этом сервисе и теперь не нарадуюсь)",
                        "UA": "Ми з друзями хотіли пограти всі разом в Valorant, а так як у мене немає аккаунта від нього я вирішив купити його. Провів угоду на цьому сервісі і тепер не натішуся)",
                        "EN": "My friends and I wanted to play all together in Valorant, and since I do not have an account from it I decided to buy it. All fine :)."
                    }
                }
            ]
        }
    },
    methods: {
        goToDeals() {
            if (this.$store.state.isAuth) {
                this.$router.push("/deals")
            }
            else {
                this.$store.dispatch("showRegisterWindow")
            }
        },
        nextSlide() {
            if (this.currentSlide >= this.slides.length - 4) {
                this.currentSlide = 0
                return
            }
            this.currentSlide++
        },
        prevSlide() {
            if (this.currentSlide === 0) {
                return
            }
            this.currentSlide--
        },
        openChaport() {
            document.querySelector(".chaport-launcher-button").click()
        },
    },
    computed: {
        sliderMargin() {
            this.currentSlide
            if (this.$refs.slides != null) {
                return -this.$refs.slides[0].clientWidth * this.currentSlide + 16
            }
            return 0
        }
    }
}
</script>
