<template>
    <container>
        <div class="admin-deals__header">
            <form @submit.prevent="setDeals" class="admin-deals__filter">
                <div class="admin-deals__input-wrapper">
                    <p>
                        Статус сделок
                    </p>
                    <select class="admin-deals__filter__input" v-model="status">
                        <option value="0">Все</option>
                        <option v-for="status in statuses" :value="status.id">{{ status.name[this.$store.state.currentLanguage] }}</option>
                    </select>
                </div>
                <div class="admin-deals__input-wrapper search">
                    <p>
                        Поиск
                    </p>
                    <primary-input placeholder="Запрос . . ." v-model="searchQuery" />
                </div>
                <button type="sumbit" class="admin-deals__filter__button primary-linear-stroke">Найти</button>
            </form>
        </div>
        <table class="admin-deals__main" v-if="dealsCount">
            <DealCard v-for="deal in deals" :deal="deal" :key="deal.id" />
        </table>
        <div class="admin-deals__footer" v-if="dealsCount">
            <primary-pagination class="admin__pagination" :count="dealsCount" :perPage="9" v-model="page"
                @update:modelValue="setDeals" />
        </div>
        <p class="admin-deals__notfound-text" v-else>Сделок не найдено</p>
    </container>
</template>

<style>
.admin-deals__header {
    display: flex;
    justify-content: center;
    margin-top: 35px;
}

.admin-deals__input-wrapper {
    text-align: center;
    color: var(--neutral-white);
    display: flex;
    flex-direction: column;
    font-size: 15px;
    gap: 5px;
}

.admin-deals__filter {
    display: flex;
    align-items: center;
    gap: 30px;
    width: 100%;
}

.admin-deals__filter__input {
    height: 45px;
    border-radius: 8px;
    border: none;
    background: var(--neutral-background-90);
    padding: 0 12px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
}

.admin-deals__filter__input {
    outline: none;
}

.search {
    width: 100%;
}

.admin-deals__filter__button {
    border: none;
    background: var(--neutral-background-90);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    color: var(--neutral-white);
    cursor: pointer;
    width: 150px;
    height: 45px;
    margin-top: 24px;
}

@media (max-width: 850px) {
    .admin-deals__filter {
        flex-direction: column;
    }

    .admin-deals__input-wrapper {
        width: 100%;
    }

    .admin-deals__filter__input {
        width: 100%;
    }

    .admin-deals__filter__button {
        margin-top: 0;
    }
}

.admin-deals__main {
    margin-top: 50px;
    display: table;
    border-spacing: 0 24px;
    border-collapse: separate;
    flex-direction: column;
    width: 100%;
}

.admin-deals__footer {
    display: flex;
    justify-content: center;
    margin-top: 26px;
}

.admin-deals__notfound-text {
    text-align: center;
    margin-top: 200px;
    font-size: 20px;
    font-weight: 600;
    color: var(--neutral-white);
}
</style>

<script>
import DealCard from "@/components/partials/deal/DealCard.vue"
import AdminDealService from "@/services/AdminDealService.js"
import StatusProvider from "@/services/StatusProvider"

export default {
    components: {
        DealCard
    },
    data() {
        return {
            searchQuery: "",
            status: 4,
            deals: [],
            dealsCount: 0,
            page: 1,
            statuses: [],
        }
    },
    async created() {
        this.statuses = StatusProvider.getStatuses()
        this.setDeals();
    },
    methods: {
        async setDeals() {
            let status = this.status == 0 ? null : this.status
            const response = await AdminDealService.getDeals({ searchQuery: this.searchQuery, status: status, page: this.page, perPage: 9 })
            this.deals = response.items
            this.dealsCount = response.count
        }
    }
}
</script>
