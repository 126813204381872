<template>
    <div class="status-bar-wrapper">
        <div class="status-bar__text-wrapper">
            <img v-if="status.icon" :src="status.icon" class="status-bar__image">
            <p class="status-bar__text">{{ this.$store.getters.content.STATUS }} «{{ status.name[this.$store.state.currentLanguage] }}»</p>
        </div>
        <div class="status-bar" :style="{ '--barWidth': status.barWidth }"></div>
    </div>
    <div class="status-mobile">
        <h2 class="status-mobile__title">{{ this.$store.getters.content.DEAL_STATUS }}:</h2>
        <div class="status-mobile__statuses">
            <div class="status-mobile__statuses__item">
                <div class="status-mobile__statuses__item__circle"></div>
                <p class="status-mobile__statuses__item__name primary-text">{{ statuses[0] }}</p>
            </div>
            <div class="status-mobile__statuses__item">
                <div class="status-mobile__statuses__item__circle"></div>
                <p class="status-mobile__statuses__item__name" :class="{ 'primary-text': status.id >= 2 }">{{ statuses[1] }}</p>
            </div>
            <div class="status-mobile__statuses__item">
                <div class="status-mobile__statuses__item__circle"></div>
                <p class="status-mobile__statuses__item__name" :class="{ 'primary-text': status.id >= 3 }">{{ statuses[2] }}</p>
            </div>
            <div class="status-mobile__statuses__item" v-if="status.id != 4 && status.id != 6">
                <div class="status-mobile__statuses__item__circle"></div>
                <p class="status-mobile__statuses__item__name" :class="{ 'primary-text': status.id == 5 }">{{ statuses[4] }}</p>
            </div>
            <div class="status-mobile__statuses__item" v-else>
                <div class="status-mobile__statuses__item__circle"></div>
                <p class="status-mobile__statuses__item__name" style="color: var(--red)">{{ status.name[this.$store.state.currentLanguage] }}</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.status-bar-wrapper {
    padding: 39px 15px;
    background-color: var(--neutral-background-90);
    width: 1016px;
    display: flex;
    align-items: center;
    gap: 20px;
    margin: auto;
    margin-top: 50px;
    border-radius: 16px;
    position: relative;
    z-index: -2;
}

.status-mobile {
    display: none;
}

@media (max-width: 1200px) {
    .status-bar-wrapper {
        width: 100%;
    }
}

@media (max-width: 800px) {
    .status-bar-wrapper {
        display: none;
    }

    .status-mobile {
        display: block;
    }
}

.status-bar__text-wrapper {
    display: flex;
    gap: 16px;
}

.status-bar__image {
    height: 24px;
    width: 24px;
}

.status-bar__text {
    color: var(--neutral-white);
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;
}

.status-bar {
    height: 9px;
    border-radius: 28px;
    background-color: var(--neutral-white);
    width: 100%;
    position: relative;
}

.status-bar::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: var(--barWidth);
    border-radius: inherit;
    background: var(--primary-gradient);
    transition: all 0.5s ease;
}

.status-mobile {
    position: relative;
    padding: 10px 15px;
    border-radius: 8px;
    width: 100%;
    background-color: var(--neutral-background-90);
    margin-top: 100px;
    z-index: -2;
}

.status-mobile__title {
    color: var(--neutral-white);
    font-size: 28px;
    font-weight: 600;
}

.status-mobile__statuses {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 6px;
    position: relative;
}

.status-mobile__statuses::before {
    content: "";
    position: absolute;
    top: 5px;
    bottom: 5px;
    width: 2px;
    background: var(--primary-violet-main);
    border-radius: 28px;
    z-index: -1;
    opacity: 0.4;
    left: 5px;
}

.status-mobile__statuses__item {
    display: flex;
    gap: 19px;
    align-items: center;
}

.status-mobile__statuses__item__circle {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: var(--primary-gradient);
}

.status-mobile__statuses__item__name {
    color: var(--neutral-text);
    font-size: 16px;
    font-weight: 400;
}
</style>

<script>
import StatusProvider from '@/services/StatusProvider'

export default {
    props: {
        status: {
            type: Object,
            required: true
        }
    },
    computed: {
        statuses() {
            return StatusProvider.getStatuses().map(e => e.name[this.$store.state.currentLanguage])
        }
    }
}
</script>
