<template>
    <transition appear name="deal-chat-message__transition">
        <div class="deal-chat-message" :class="{ author: this.$store.state.currentUser.id == message.author.id }">
            <p class="deal-chat-message__text">{{ message.text[this.$store.state.currentLanguage] }}</p>
            <div class="deal-chat-message__info">
                <p class="deal-chat-message__author">{{ message.author.userName[this.$store.state.currentLanguage] }}</p>
                <p class="deal-chat-message__time">{{ messageTime }}</p>
            </div>
        </div>
    </transition>
</template>
  
<style>
.deal-chat-message__transition-enter-active,
.deal-chat-message__transition-leave-active {
    transition: all .1s;
}

.deal-chat-message__transition-enter-from,
.deal-chat-message__transition-leave-to {
    opacity: 0;
    transform: translateY(10px);
}

.deal-chat-message {
    padding: 12px;
    width: fit-content;
    border: 1px solid var(--gray);
    border-radius: 12px;
    border-bottom-left-radius: 0;
}

.deal-chat-message.author {
    align-self: flex-end;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 0;
    border: none;
    background-color: var(--primary-violet-main);
}

.deal-chat-message__text {
    max-width: 360px;
    color: var(--neutral-white);
    font-size: 14px;
    font-weight: 400;
    white-space: pre-wrap;
    word-break: break-word;
}

.deal-chat-message__info {
    opacity: 0.7;
    margin-top: 3px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
}

.deal-chat-message__author {
    color: var(--neutral-white);
    font-size: 12px;
    font-weight: 400;
}

.deal-chat-message__time {
    font-size: 12px;
    font-weight: 400;
    color: var(--neutral-white);
}
</style>
  
<script>

export default {
    props: {
        message: {
            type: Object,
            required: true
        }
    },
    computed: {
        messageTime() {
            let createdDate = new Date(this.message.createdAt)
            let hours = createdDate.getHours()
            let minutes = createdDate.getMinutes()
            if (minutes < 10) {
                minutes = "0" + minutes
            }
            return `${hours}:${minutes}`
        }
    }
}
</script>