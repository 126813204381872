<template>
    <textarea :value="modelValue" @input="onUpdateValue" class="primary-input" :class="{ error: isError }">
        <slot></slot>
    </textarea>
</template>

<script>
export default {
    name: "primary-textarea",
    props: {
        isError: {
            default: false
        },
        modelValue: {
            type: String,
            default: null
        }
    },
    methods: {
        onUpdateValue(event) {
            this.$emit("update:modelValue", event.target.value)
        }
    },
    watch: {
        modelValue: function () {
            return this.modelValue;
        },
    },
}
</script>