<template>
    <footer class="footer bg__secondary" v-if="this.$store.state.isReady">
        <div class="container">
            <div class="row justify-content-between footer-content flex-wrap">
                <div class="footerCopyright col-12 col-xl-3">
                    <div class="footerCopyright__logo">
                        <router-link class="footer-logo-m" to="/"><span>{{ this.$store.state.appName[0] }}</span>{{ this.$store.state.appName.slice(1) }}</router-link>
                    </div>
                </div>
                <nav class="footerNav d-flex flex-column align-items-xl-center col-12 col-xl-6">
                    <ul class="Nav__menu d-flex flex-column flex-xl-row flex-wrap">
                        <li class="footer_nav_item"><router-link class="footer_nav_link" to="/reviews">{{ this.$store.getters.content.HEADER_REVIEWS }}</router-link></li>
                        <li class="footer_nav_item"><router-link class="footer_nav_link" to="/forwhom">{{ this.$store.getters.content.HEADER_FORWHOM }}</router-link></li>
                        <li class="footer_nav_item"><router-link class="footer_nav_link" to="/sertificates">{{ this.$store.getters.content.HEADER_SERTIFICATES }}</router-link></li>
                        <li class="footer_nav_item"><router-link class="footer_nav_link" to="/howitwork">{{ this.$store.getters.content.HEADER_HOWITWORK }}</router-link></li>
                    </ul>
                    <div class="footerCopyright__copyright fc__secondary size12 d-none d-xl-block">
                        <span>©2020-2024 {{ this.$store.getters.content.FOOTER_COPY }} {{ this.$store.state.appDomain.toLowerCase() }}</span>
                    </div>
                </nav>
                <div class="agreement d-flex flex-column align-items-xl-end col-12 col-xl-3">
                    <div class="agreementImg">
                        <img src="@/assets/img/Frame34.svg">
                        <img src="@/assets/img/Frame35.svg">
                        <img src="@/assets/img/Frame36.svg">
                    </div>
                    <div class="footerCopyright__copyright  size12 d-block d-xl-none">
                        <span>©2020-2024 {{ this.$store.getters.content.FOOTER_COPY }}</span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<style>
footer {
    margin-top: 75px;
}
</style>