<template>
    <container>
        <div class="admin-deals__header">
            <form @submit.prevent="setTransactions" class="admin-deals__filter">
                <div class="admin-deals__input-wrapper">
                    <p>
                        Сортировать по
                    </p>
                    <select class="admin-deals__filter__input" v-model="sortBy">
                        <option value="CreatedAt">Дате создания (по возрастанию)</option>
                        <option value="DescCreatedAt">Дате создания (по убыванию)</option>
                        <option value="Amount">Сумме перевод (по возрастанию)</option>
                        <option value="DescAmount">Сумме перевод (по убыванию)</option>
                    </select>
                </div>
                <div class="admin-deals__input-wrapper search">
                    <p>
                        Поиск по пользователю
                    </p>
                    <primary-input placeholder="Логин . . ." v-model="userName" />
                </div>
                <button type="sumbit" class="admin-deals__filter__button primary-linear-stroke">Найти</button>
            </form>
        </div>
        <table class="admin-deals__main" v-if="transactionsCount">
            <AdminTransactionCard v-for="transaction in transactions" :transaction="transaction" :key="transaction.id" />
        </table>
        <div class="admin-deals__footer" v-if="transactionsCount">
            <primary-pagination class="admin__pagination" :count="transactionsCount" :perPage="9" v-model="page"
                @update:modelValue="setTransactions" />
        </div>
        <p class="admin-deals__notfound-text" v-else>Переводов не найдено</p>
    </container>
</template>

<script>
import AdminTransactionCard from '@/components/partials/admin/AdminTransactionCard.vue'
import AdminTransactionService from "@/services/AdminTransactionService.js"
import UserService from "@/services/UserService"

export default {
    components: { AdminTransactionCard },
    data() {
        return {
            userName: "",
            sortBy: "DescCreatedAt",
            transactions: [],
            transactionsCount: 0,
            page: 1
        }
    },
    async created() {
        await this.setTransactions();
    },
    methods: {
        async setTransactions() {
            const response = await AdminTransactionService.getTransactions(
                {
                    userId: await this.getUserId(),
                    isDesc: this.sortBy.startsWith("Desc"),
                    sortBy: this.sortBy.replace(/^Desc/, ''),
                    page: this.page,
                    perPage: 9
                }
            )
            this.transactions = response.items
            this.transactionsCount = response.count
        },
        async getUserId() {
            try {
                return (await UserService.getUserByUserName(this.userName)).id
            }
            catch (e) {
                return null
            }
        }
    }
}
</script>
