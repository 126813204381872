<template>
    <container>
        <div class="tabs">
            <div class="tabs__content-wrapper">
                <router-link class="tabs__item" to="/admin/deals" active-class="active">
                    Сделки
                </router-link>
                <router-link class="tabs__item" to="/admin/users" active-class="active">
                    Пользователи
                </router-link>
                <router-link class="tabs__item" to="/admin/outputs" active-class="active">
                    Выводы средств
                </router-link>
                <router-link class="tabs__item" to="/admin/transactions" active-class="active">
                    Переводы
                </router-link>
            </div>
        </div>
    </container>
</template>
