import { createRouter, createWebHistory } from "vue-router"
import HomeView from "../views/HomeView.vue"
import AdminView from "../views/AdminView.vue"
import AdminUserView from "../views/AdminUserView.vue"
import AdminUsersView from "../views/AdminUsersView.vue"
import AdminOutputsView from "../views/AdminOutputsView.vue"
import AdminTransactionsView from "../views/AdminTransactionsView.vue"
import AdminDealsView from "../views/AdminDealsView.vue"
import MyDealsView from "../views/MyDealsView.vue"
import DealCreateView from "../views/DealCreateView.vue"
import DealView from "../views/DealView.vue"
import NotificationsView from "../views/NotificationsView.vue"
import NotFoundView from "../views/NotFoundView.vue"
import BalanceView from "../views/BalanceView.vue"
import AddBalanceView from "../views/AddBalanceView.vue"
import SettingsView from "../views/SettingsView.vue"
import ForWhomView from "../views/ForWhomView.vue"
import ReviewsView from "../views/ReviewsView.vue"
import SertificatesView from "../views/SertificatesView.vue"
import HowItWorkView from "../views/HowItWorkView.vue"
import RulesView from "../views/RulesView.vue"
import BlockedView from "../views/BlockedView.vue"
import BlockedBalanceView from "../views/BlockedBalanceView.vue"
import store from "../store"

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView
    },
    {
        path: "/admin",
        name: "admin",
        component: AdminView,
        meta: {
            adminRequired: true
        },
        children: [
            {
                path: "deals",
                component: AdminDealsView
            },
            {
                path: "users",
                component: AdminUsersView
            },
            {
                path: "outputs",
                component: AdminOutputsView
            },
            {
                path: "transactions",
                component: AdminTransactionsView
            }
        ]
    },
    {
        path: "/admin/users/:id",
        name: "adminUser",
        component: AdminUserView,
        meta: {
            adminRequired: true
        }
    },
    {
        path: "/deals",
        name: "deals",
        component: MyDealsView,
        meta: {
            authRequired: true,
            nonBlockRequired: true
        }
    },
    {
        path: "/deals/create",
        name: "dealCreate",
        component: DealCreateView,
        meta: {
            authRequired: true,
            nonBlockRequired: true
        }
    },
    {
        path: "/deals/:id",
        name: "deal",
        component: DealView,
        meta: {
            authRequired: true,
            nonBlockRequired: true
        }
    },
    {
        path: "/notifications",
        name: "notifications",
        component: NotificationsView,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/balance",
        name: "balance",
        component: BalanceView,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/balance/add",
        component: AddBalanceView,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/settings",
        name: "settings",
        component: SettingsView,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/forwhom",
        component: ForWhomView
    },
    {
        path: "/reviews",
        component: ReviewsView
    },
    {
        path: "/sertificates",
        component: SertificatesView
    },
    {
        path: "/howitwork",
        component: HowItWorkView
    },
    {
        path: "/policy",
        component: RulesView
    },
    {
        path: "/blocked",
        name: "blocked",
        component: BlockedView
    },
    {
        path: "/blockedBalance",
        name: "blockedBalance",
        component: BlockedBalanceView
    },
    {
        path: "/:pathMatch(.*)*",
        component: NotFoundView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    if (!store.state.currencies.length) {
        await store.dispatch("updateCurrencies")
    }
    if (store.state.currentUser == null) {
        await store.dispatch("updateCurrentUser")
    }
    if (store.state.language == null) {
        await store.dispatch("updateLanguage")
    }
    store.dispatch("ready")

    store.dispatch("hideMobileMenu")

    if (store.state.currentUser?.roles.includes("Blocked") && to.name != "blocked") {
        router.push("/blocked")
    }

    if (to.meta.authRequired == true) {
        if (store.state.isAuth) {
            return next()
        }
        else {
            router.push("/")
            store.dispatch("showLoginWindow")
        }
    }
    else if (to.meta.adminRequired == true) {
        if (store.state.currentUser?.roles.includes("Admin")) {
            return next()
        }
        else {
            router.push("/")
        }
    }
    else {
        return next()
    }
})

export default router
