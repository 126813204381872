import axios from "axios"
import store from "../store"

const AdminOutputService = {
    async getOutputRequests({ status = null, userId = null, perPage = 30, page = 1 } = {}) {
        const response = await axios.get("/api/admin/outputRequests", { headers: { Authorization: `Bearer ${store.state.accessToken}` }, params: arguments[0] })
        return response.data
    },
    async approve(id) {
        await axios.post(`/api/admin/outputRequests/${id}/approve`, null, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    },
    async cancel(id) {
        await axios.post(`/api/admin/outputRequests/${id}/cancel`, null, { headers: { Authorization: `Bearer ${store.state.accessToken}` } })
    }
}

export default AdminOutputService
