<template>
    <button class="linear-stroke-button primary-linear-stroke"><slot></slot></button>
</template>

<style>
.linear-stroke-button {
    width: 179px;
    height: 32px;
    border-radius: 6px;
    background: linear-gradient(180deg, rgb(54, 58, 73) 0%, rgb(29, 34, 51) 100%);
    color: var(--neutral-white);
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    border: none;
}
</style>

<script>
export default {
    name: "linear-stroke-button"
}
</script>