<template>
    <button class="primary-button">
        <slot></slot>
    </button>
</template>

<style>
.primary-button {
    padding: 14px 32px;
    border: none;
    background: var(--primary-gradient);
    border-radius: 8px;
    font-size: 18px;
    font-weight: 400;
    color: var(--neutral-white);
    cursor: pointer;
}
</style>

<script>
export default {
    name: "primary-button"
}
</script>